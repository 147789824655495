import { Activity, SolidActivity } from './Icons/Activity';
import { Alert } from './Icons/Alert';
import { AnonymousType, LeadType, PatientType } from './Icons/ProfileTypes';
import { Apps } from './Icons/Apps';
import {
  ArrowDown,
  ArrowDownload,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowOut,
  ArrowPath,
  ArrowPathSquared,
  ArrowRight,
  ArrowRightLeft,
  ArrowUp,
  ArrowsFlowRight,
} from './Icons/Arrow';
import { Bolt, BoltArrow, BoltDouble, BoltId, BoltSlash } from './Icons/Bolt';
import { Bug } from './Icons/Bug';
import { Calendar } from './Icons/Calendar';
import { CarrotDown, CarrotLeft, CarrotRight, CarrotUp } from './Icons/Carrot';
import { Check, CheckBadge } from './Icons/Check';
import {
  CheckCircle,
  Circle,
  MinusCircle,
  PlusCircle,
  SolidCheck,
} from './Icons/Circle';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  DoubleChevronRight,
} from './Icons/Chevron';
import { Clock } from './Icons/Clock';
import { Close, SolidXCircle, XCircle } from './Icons/Close';
import { CodeBracket, CurlyBrackets } from './Icons/Bracket';
import { CommandLine } from './Icons/CommandLine';
import { Conditional } from './Icons/Conditional';
import { Copy } from './Icons/Copy';
import { Destination } from './Icons/Destination';
import { Details } from './Icons/Details';
import { DocumentSearch } from './Icons/Document';
import { Edit } from './Icons/Edit';
import { Filter } from './Icons/Filter';
import { FilterLines } from './Icons/FilterLines';
import { Flows } from './Icons/Flows';
import { FolderOpen } from './Icons/Folder';
import { Help } from './Icons/Help';
import { Home } from './Icons/Home';
import { Identification, SolidIdentification } from './Icons/Identification';
import { Info, SolidInfo } from './Icons/Info';
import { InformationCircle } from './Icons/InformationCircle';
import { Link } from './Icons/Link';
import { LinkOut } from './Icons/LinkOut';
import { List } from './Icons/List';
import { LockClosed, LockOpen } from './Icons/Lock';
import { Logout } from './Icons/Logout';
import { MenuWorkspace, SolidWorkspace, Workspace } from './Icons/Workspace';
import { More } from './Icons/More';
import { Morf } from './Icons/Morf';
import {
  PanelBottom,
  PanelLeft,
  PanelLeftSlash,
  PanelRight,
  SolidPanelLeft,
} from './Icons/Panel';
import { Patients, SolidPatients } from './Icons/Patients';
import {
  Patient,
  PatientAdd,
  PatientArrow,
  PatientCircle,
  PatientLookup,
} from './Icons/Patient';
import { Pause } from './Icons/Pause';
import { Payload, PayloadLeft, PayloadRight } from './Icons/Payload';
import { Plus } from './Icons/Plus';
import { Properties } from './Icons/Properties';
import { ReactNode, useMemo } from 'react';
import {
  Refresh,
  RefreshChevronDown,
  RefreshChevronUp,
  RefreshCircle,
} from './Icons/Refresh';
import { Search } from './Icons/Search';
import { Settings } from './Icons/Settings';
import { Sorter, SorterBottom, SorterTop } from './Icons/Sorter';
import { Table, TableComfortable, TableCompact } from './Icons/Table';
import { Tag } from './Icons/Tag';
import { Temporal } from './Icons/Temporal';
import { Text } from './Icons/Text';
import { Tools } from './Icons/Tools';
import { Trash } from './Icons/Trash';
import { User } from './Icons/User';
import { Variable } from './Icons/Variable';
import { Wait } from './Icons/Wait';
import { Warning } from './Icons/Warning';
import { Webhook } from './Icons/Webhook';

export const IconMapping = (): { [key: string]: ReactNode } =>
  useMemo(
    () => ({
      activity: <Activity />,
      'activity-solid': <SolidActivity />,
      alert: <Alert />,
      'anonymous-type': <AnonymousType />,
      apps: <Apps />,
      'arrow-down': <ArrowDown />,
      'arrow-download': <ArrowDownload />,
      'arrow-left': <ArrowLeft />,
      'arrow-left-circle': <ArrowLeftCircle />,
      'arrow-out': <ArrowOut />,
      'arrow-path': <ArrowPath />,
      'arrow-path-squared': <ArrowPathSquared />,
      'arrow-right': <ArrowRight />,
      'arrow-right-left': <ArrowRightLeft />,
      'arrow-up': <ArrowUp />,
      'arrows-flow-right': <ArrowsFlowRight />,
      bolt: <Bolt />,
      'bolt-arrow': <BoltArrow />,
      'bolt-double': <BoltDouble />,
      'bolt-id': <BoltId />,
      'bolt-slash': <BoltSlash />,
      bug: <Bug />,
      calendar: <Calendar />,
      'carrot-down': <CarrotDown />,
      'carrot-left': <CarrotLeft />,
      'carrot-right': <CarrotRight />,
      'carrot-up': <CarrotUp />,
      check: <Check />,
      'check-badge': <CheckBadge />,
      'check-circle': <CheckCircle />,
      'check-solid': <SolidCheck />,
      'chevron-down': <ChevronDown />,
      'chevron-left': <ChevronLeft />,
      'chevron-right': <ChevronRight />,
      'chevron-up': <ChevronUp />,
      circle: <Circle />,
      clock: <Clock />,
      close: <Close />,
      'code-bracket': <CodeBracket />,
      'command-line': <CommandLine />,
      conditional: <Conditional />,
      copy: <Copy />,
      'curly-brackets': <CurlyBrackets />,
      destination: <Destination />,
      details: <Details />,
      'document-search': <DocumentSearch />,
      'double-chevron-right': <DoubleChevronRight />,
      edit: <Edit />,
      filter: <Filter />,
      'filter-lines': <FilterLines />,
      flows: <Flows />,
      'folder-open': <FolderOpen />,
      help: <Help />,
      home: <Home />,
      identification: <Identification />,
      'identification-solid': <SolidIdentification />,
      info: <Info />,
      'info-solid': <SolidInfo />,
      'information-circle': <InformationCircle />,
      'lead-type': <LeadType />,
      link: <Link />,
      'link-out': <LinkOut />,
      list: <List />,
      'lock-closed': <LockClosed />,
      'lock-open': <LockOpen />,
      logout: <Logout />,
      menuWorkspace: <MenuWorkspace />,
      'minus-circle': <MinusCircle />,
      more: <More />,
      'morf-logo': <Morf />,
      'panel-bottom': <PanelBottom />,
      'panel-left': <PanelLeft />,
      'panel-left-slash': <PanelLeftSlash />,
      'panel-left-solid': <SolidPanelLeft />,
      'panel-right': <PanelRight />,
      patient: <Patient />,
      'patient-add': <PatientAdd />,
      'patient-arrow': <PatientArrow />,
      'patient-circle': <PatientCircle />,
      'patient-lookup': <PatientLookup />,
      'patient-type': <PatientType />,
      patients: <Patients />,
      'patients-solid': <SolidPatients />,
      pause: <Pause />,
      payload: <Payload />,
      'payload-left': <PayloadLeft />,
      'payload-right': <PayloadRight />,
      plus: <Plus />,
      'plus-circle': <PlusCircle />,
      properties: <Properties />,
      refresh: <Refresh />,
      'refresh-chevron-down': <RefreshChevronDown />,
      'refresh-chevron-up': <RefreshChevronUp />,
      'refresh-circle': <RefreshCircle />,
      search: <Search />,
      settings: <Settings />,
      sorter: <Sorter />,
      'sorter-bottom': <SorterBottom />,
      'sorter-top': <SorterTop />,
      table: <Table />,
      'table-comfortable': <TableComfortable />,
      'table-compact': <TableCompact />,
      tag: <Tag />,
      temporal: <Temporal />,
      text: <Text />,
      tools: <Tools />,
      trash: <Trash />,
      user: <User />,
      variable: <Variable />,
      wait: <Wait />,
      warning: <Warning />,
      webhook: <Webhook />,
      workspace: <Workspace />,
      'workspace-solid': <SolidWorkspace />,
      'x-circle': <XCircle />,
      'x-circle-solid': <SolidXCircle />,
    }),
    []
  );
