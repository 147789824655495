import moment from 'moment';
import { Button } from '../../../../Button';
import { CustomTimeRangeProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { Icon } from '../../../../Icon';
import { Input } from '../../../../Input';
import { Text } from '../../../../Typography';
import { convertRFC3339ToTimestamp } from './convertRFC339ToTimestamp';
import { convertTimestampToRFC3339 } from './convertTimestampToRFC339';
import { defaultDateFormat } from '../../constants';
import { timestampPattern } from './timestampPattern';
import { useForm } from 'react-hook-form';
import { useTheme } from '@morf/theming';

const CustomTimeRange: FC<CustomTimeRangeProps> = ({
  onApplyClick,
  onBackClick,
  onResetClick,
  timeOption,
}) => {
  const theme = useTheme();
  const { register, formState, watch } = useForm({
    mode: 'onChange',
  });

  const { startTimestamp, endTimestamp } = watch();

  const handleApplyClick = () => {
    const label = `${moment(startTimestamp).format(
      defaultDateFormat
    )} - ${moment(endTimestamp).format(defaultDateFormat)}`;

    onApplyClick({
      label,
      timeRange: {
        startTimestamp: convertRFC3339ToTimestamp(startTimestamp),
        endTimestamp: convertRFC3339ToTimestamp(endTimestamp),
      },
    });
  };

  return (
    <Flexbox
      data-testid='custom-time-range'
      direction='column'
      justifyContent='center'
      alignItems='flex-start'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
        pr={0.125}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          width='auto'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='chevron-left'
            size={0.75}
            stroke={theme.colors.ui.dark}
            strokeWidth={2}
            cursor='pointer'
            onClick={onBackClick}
          />

          <Text tag='p3' color={theme.colors.text.muted}>
            Select time range
          </Text>
        </Flexbox>

        <Icon
          name='trash'
          stroke={theme.colors.ui.dark}
          size={0.75}
          strokeWidth={2}
          cursor='pointer'
          onClick={onResetClick}
        />
      </Flexbox>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.25}
      >
        <Input
          id='startTimestamp'
          tag='p3'
          height='1.5rem'
          value={convertTimestampToRFC3339(timeOption.timeRange.startTimestamp)}
          placeholder='Start timestamp'
          {...register('startTimestamp', {
            required: true,
            pattern: {
              value: timestampPattern,
              message: 'Invalid timestamp format',
            },
          })}
        />

        <Input
          id='endTimestamp'
          tag='p3'
          height='1.5rem'
          value={convertTimestampToRFC3339(timeOption.timeRange.endTimestamp)}
          placeholder='End timestamp'
          {...register('endTimestamp', {
            required: true,
            pattern: {
              value: timestampPattern,
              message: 'Invalid timestamp format',
            },
          })}
        />
      </Flexbox>

      <Button
        width='100%'
        isDisabled={!formState.isValid}
        onClick={handleApplyClick}
      >
        <Text tag='h6' color='inherit'>
          Apply
        </Text>
      </Button>
    </Flexbox>
  );
};

export const MemoizedCustomTimeRange = memo(CustomTimeRange);
