export const Circle = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z'
    />
  );
};

export const CheckCircle = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
    />
  );
};

export const SolidCheck = () => {
  return (
    <path
      fillRule='evenodd'
      d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
      clipRule='evenodd'
    />
  );
};

export const PlusCircle = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
    />
  );
};

export const MinusCircle = () => {
  return (
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
    />
  );
};
