import _ from 'lodash';
import { ActionExecutionStatus } from '../../../ActionExecutionStatus';
import { ActionExecutionSubRowProps } from './types';
import { Container } from '../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Text } from '../../../Typography';
import { convertTimestampToMoment } from '../../../Filters';
import { defaultDateTimeFormatWithSeconds } from '../../../Filters/TimeFilter/constants';
import { useHover } from '../../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';

const ActionExecutionSubRow: FC<ActionExecutionSubRowProps> = ({
  attemptNumber,
  executionStart,
  expectedExecutionEnd,
  isLastSubRow,
  isSubRowSelected,
  onSubRowClick,
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const backgroundColor = isSubRowSelected
    ? theme.colors.main.light.light
    : isHovered
    ? theme.colors.ui.body
    : theme.colors.ui.card;

  const timestampTag = isSubRowSelected ? 'h5' : 'p2';

  const formattedTimestamp =
    executionStart &&
    convertTimestampToMoment(executionStart).format(
      defaultDateTimeFormatWithSeconds
    );

  const status = workflow_monitoring.v2.ActionExecutionAttempts.Status.RETRYING;

  return (
    <Flexbox
      data-testid='action-execution-sub-row'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={backgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onSubRowClick}
      width='16.5rem'
      height='auto'
      cursor='pointer'
      pl={0.75}
      pr={0.5}
      gap={0.5}
      {...(isLastSubRow && {
        borderType: 'borderBottom',
        borderColor: backgroundColor,
        borderRadius: 0.25,
      })}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        borderType='borderLeft'
        borderColor={theme.colors.ui.muted}
        position='relative'
        height='auto'
        gap={0.25}
        py={0.5}
      >
        {isLastSubRow && (
          <Container
            position='absolute'
            left='-0.1rem'
            top='1.175rem'
            width='1rem'
            height='1.125rem'
            borderType='borderLeft'
            borderColor={backgroundColor}
          />
        )}

        <Container
          width='1.25rem'
          borderType='borderTop'
          borderColor={theme.colors.ui.muted}
        />

        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Text tag={timestampTag} whiteSpace='nowrap'>
            {formattedTimestamp}
          </Text>

          <ActionExecutionStatus
            variant='icon'
            status={status}
            retryNumber={attemptNumber}
            expectedExecutionEnd={expectedExecutionEnd}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedActionExecutionSubRow = memo(ActionExecutionSubRow);
