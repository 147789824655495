import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { IntegrationIcon } from '../IntegrationIcon';
import { SkeletonLoadable } from '../Skeleton';
import { Text } from '../Typography';
import { WorkflowExecutionRowProps } from './types';
import { WorkflowExecutionStatus } from '../WorkflowExecutionStatus';
import { convertTimestampToMoment } from '../Filters';
import { defaultDateFormat } from '../Filters/TimeFilter/constants';
import { useHover } from '../Hooks/useHover';
import { useTheme } from '@morf/theming';

const WorkflowExecutionRow: FC<WorkflowExecutionRowProps> = ({
  description,
  integrationIconName,
  isLoading,
  isSelected,
  name,
  onClick,
  status,
  timestamp,
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const backgroundColor =
    isHovered && !isLoading ? theme.colors.ui.body : theme.colors.ui.card;
  const borderColor = isSelected
    ? theme.colors.main.primary.darker
    : theme.colors.ui.card;
  const cursor = isLoading ? 'default' : 'pointer';
  const descriptionTag = isSelected ? 'h6' : 'p3';
  const nameTag = isSelected ? 'h5' : 'p2';
  const formattedTimestamp =
    timestamp && convertTimestampToMoment(timestamp).format(defaultDateFormat);

  return (
    <Flexbox
      data-testid='workflow-execution-row'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      backgroundColor={backgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      borderType='border'
      borderColor={borderColor}
      borderRadius={0.25}
      height='auto'
      width='16.5rem'
      cursor={cursor}
      px={0.5}
      py={0.75}
      gap={0.5}
    >
      <SkeletonLoadable isLoading={isLoading} width='1rem' height='1rem'>
        <Container width='auto' height='auto'>
          <IntegrationIcon name={integrationIconName} size={1.25} />
        </Container>
      </SkeletonLoadable>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
      >
        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <SkeletonLoadable isLoading={isLoading} width='11.5rem' height='1rem'>
            <Container width='11.5rem' height='auto'>
              <Text tag={nameTag} whiteSpace='nowrap'>
                {name}
              </Text>
            </Container>
          </SkeletonLoadable>
          <SkeletonLoadable isLoading={isLoading} width='1rem' height='1rem'>
            <WorkflowExecutionStatus variant='icon' status={status} />
          </SkeletonLoadable>
        </Flexbox>

        <SkeletonLoadable isLoading={isLoading} width='14rem' height='0.5rem'>
          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='auto'
            gap={0.5}
          >
            <Container width='8.25rem' height='auto'>
              <Text
                tag={descriptionTag}
                color={theme.colors.text.muted}
                whiteSpace='nowrap'
              >
                {description}
              </Text>
            </Container>
            <Text tag='p3' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {formattedTimestamp}
            </Text>
          </Flexbox>
        </SkeletonLoadable>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionRow = memo(WorkflowExecutionRow);
