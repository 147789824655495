import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { WorkflowExecutionHeaderProps } from './types';
import { WorkflowExecutionStatus } from '../WorkflowExecutionStatus';
import { timestampToString } from '../../../apps/admin/components/helpers';
import { useTheme } from '@morf/theming';

const WorkflowExecutionHeader: FC<WorkflowExecutionHeaderProps> = ({
  onClose,
  workflowExecutionSummary,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='workflow-execution-header'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      backgroundColor={theme.colors.ui.card}
      borderType='borderBottomRight'
      width='17.5rem'
      height='auto'
      gap={0.5}
      px={0.75}
      py={1}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0.5}
      >
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
        >
          <Text tag='h5'>{workflowExecutionSummary.workflowName}</Text>
          <Text tag='p3'>
            {
              timestampToString(workflowExecutionSummary.executionEndTime)
                .isoDateString
            }
          </Text>
        </Flexbox>

        <Icon
          name='close'
          cursor='pointer'
          onClick={onClose}
          size={1.125}
          stroke={theme.colors.text.muted}
          strokeWidth={1.75}
        />
      </Flexbox>

      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        gap={0.25}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='patient-arrow'
            size={1}
            stroke={theme.colors.text.muted}
            strokeWidth={1.75}
          />
          <Text tag='p3'>
            {workflowExecutionSummary.matchedProfileIndex?.thirdPartyId}
          </Text>
        </Flexbox>

        <WorkflowExecutionStatus
          status={workflowExecutionSummary.status}
          variant='icon-description'
        />

        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='bolt-id'
            size={1}
            stroke={theme.colors.text.muted}
            strokeWidth={1.75}
          />
          <Text tag='p3'>{workflowExecutionSummary.workflowExecutionId}</Text>
        </Flexbox>

        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='temporal'
            size={1}
            strokeWidth={0.125}
            fill={theme.colors.text.body}
          />
          <Text tag='p3'>
            {
              workflowExecutionSummary.temporalWorkflowExecution
                ?.temporalWorkflowId
            }
          </Text>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionHeader = memo(WorkflowExecutionHeader);
