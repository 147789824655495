import _ from 'lodash';
import { FC, memo, useRef, useState } from 'react';
import { FilterChip } from './FilterChip';
import { FilterDropdown } from './FilterDropdown';
import { FilterOption } from './FilterDropdown/FilterOption/types';
import { FilterProps } from './types';
import { Flexbox } from '../Flexbox';
import { WrapperModal } from '../WrapperModal';
import { useRouter } from 'next/router';

const Filter: FC<FilterProps> = ({
  allOptions,
  name,
  options,
  setIsFilterSelected,
  setOptions,
  value,
}) => {
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);
  const queryName = _.toLower(name) + (_.endsWith(name, 's') ? 'es' : 's');

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [position, setPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const size = _.size(options);

  let updatedValue;

  if (value) {
    updatedValue = value;
  } else if (size === 0) {
    updatedValue = '';
  } else if (size === 1) {
    updatedValue = options[0].name;
  } else {
    updatedValue = `${size} ${_.toLower(name)}s`;
  }

  const handleToggleOptions = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top / 16 + 1.75 + 'rem',
        left: rect.left / 16 + 'rem',
      });
    }
    setShowOptions(!showOptions);
  };

  const handleCloseOptions = () => setShowOptions(false);

  const handleApplyClick = (options: FilterOption[]) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [queryName]: options.map(({ id }) => id),
        },
      },
      undefined,
      { shallow: true }
    );
    setOptions(options);
    setShowOptions(false);
  };

  const handleResetClick = () => {
    const { [queryName]: _, ...rest } = router.query;

    router.push(
      {
        pathname: router.pathname,
        query: rest,
      },
      undefined,
      { shallow: true }
    );
    setOptions([]);
    setShowOptions(false);
    setIsFilterSelected(false);
  };

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid={_.toLower(name) + '-filter'}
      width='auto'
      height='auto'
    >
      <FilterChip
        name={name}
        onClick={handleToggleOptions}
        showOptions={showOptions}
        value={updatedValue}
      />

      {showOptions && (
        <WrapperModal
          {...position}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          width='13.125rem'
          onClose={handleCloseOptions}
        >
          <FilterDropdown
            allOptions={allOptions}
            name={name}
            onApplyClick={handleApplyClick}
            onResetClick={handleResetClick}
            options={options}
          />
        </WrapperModal>
      )}
    </Flexbox>
  );
};

export const MemoizedFilter = memo(Filter);
