import _ from 'lodash';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';
import {
  ActionExecutionStatusDetails,
  ActionExecutionStatusProps,
} from './types';

const ActionExecutionStatus: FC<ActionExecutionStatusProps> = ({
  errorString,
  retryNumber,
  retryOriginalWorkflowExecutionId,
  status,
  expectedExecutionEnd,
  variant,
}) => {
  const theme = useTheme();

  const statusMap: Record<
    workflow_monitoring.v2.ActionExecutionAttempts.Status,
    ActionExecutionStatusDetails
  > = {
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.UNSPECIFIED_STATUS]:
      {
        description: 'Unspecified',
        hoverDescription: 'Unspecified',
        icon: <></>,
      },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.PROCESSING]: {
      description: 'Processing',
      hoverDescription: 'Processing',
      icon: (
        <Icon
          name='circle'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.EXECUTED]: {
      description: 'Executed',
      hoverDescription: 'Executed',
      icon: (
        <Icon
          name='check-circle'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.green.darkest}
        />
      ),
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.ERROR]: {
      description: 'Error',
      hoverDescription: `${errorString}`,
      icon: (
        <Icon
          name='alert'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.red.darkest}
        />
      ),
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.WARNING]: {
      description: 'Warning',
      hoverDescription: `${errorString}`,
      icon: (
        <Icon
          name='alert'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.support.yellow.darkest}
        />
      ),
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.WAITING]: {
      description: 'Waiting',
      hoverDescription: `Waiting  until ${expectedExecutionEnd}`,
      icon: (
        <Icon
          name='circle'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.main.primary.darker}
        />
      ),
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.TERMINATED]: {
      description: 'Terminated',
      hoverDescription: 'Terminated',
      icon: (
        <Icon
          name='x-circle'
          size={1}
          strokeWidth={1.75}
          stroke={theme.colors.text.muted}
        />
      ),
    },
    [workflow_monitoring.v2.ActionExecutionAttempts.Status.RETRYING]: {
      description: 'Retrying',
      hoverDescription: `Retry ${retryNumber} of ${retryOriginalWorkflowExecutionId}`,
      icon: (
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          position='relative'
          height='auto'
          width='auto'
          gap={0}
        >
          <Icon
            name='arrow-path'
            size={1}
            strokeWidth={1.75}
            stroke={theme.colors.text.muted}
          />
          <Flexbox
            position='absolute'
            top='0.025rem'
            left='0.375rem'
            height='auto'
            width='auto'
          >
            <Text tag='h7' color={theme.colors.text.muted}>
              {_.toString(retryNumber)}
            </Text>
          </Flexbox>
        </Flexbox>
      ),
    },
  };

  const { description, hoverDescription, icon } = statusMap[status];

  return (
    <Flexbox
      data-testid='action-execution-status'
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      width='auto'
      gap={0.25}
    >
      {icon}
      {variant === 'icon-description' && <Text tag='p3'>{description}</Text>}
      {variant === 'icon-hover-description' && (
        <Text tag='p3'>{hoverDescription}</Text>
      )}
    </Flexbox>
  );
};

export const MemoizedActionExecutionStatus = memo(ActionExecutionStatus);
