import _ from 'lodash';
import { FC, memo, useRef } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { WorkflowExecutionRow } from '../WorkflowExecutionRow';
import { WorkflowExecutionTimelineProps } from './types';
import { useTheme } from '@morf/theming';

const WorkflowExecutionTimeline: FC<WorkflowExecutionTimelineProps> = ({
  fetchNextPage,
  workflowExecutionRows,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerElement = containerRef.current;

  const handleScroll = () => {
    if (
      containerElement &&
      containerElement.scrollHeight - containerElement.scrollTop <=
        containerElement.clientHeight
    ) {
      fetchNextPage();
    }
  };

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='workflow-execution-timeline'
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      borderType='borderRight'
      width='17.5rem'
      overflowY='scroll'
      onScroll={handleScroll}
      gap={0}
      p={0.5}
    >
      {_.isEmpty(workflowExecutionRows) ? (
        <Flexbox
          direction='column'
          justifyContent='center'
          alignItems='center'
          data-testid='empty-table-body'
          gap={0.5}
          p={1}
        >
          <Icon name='folder-open' stroke={theme.colors.ui.dark} />
          <Text tag='p2' color={theme.colors.text.muted}>
            No executions
          </Text>
        </Flexbox>
      ) : (
        workflowExecutionRows.map((row, index) => (
          <WorkflowExecutionRow key={index} {...row} />
        ))
      )}
    </Flexbox>
  );
};

export const MemoizedWorkflowExecutionTimeline = memo(
  WorkflowExecutionTimeline
);
