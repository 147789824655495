import _ from 'lodash';
import { FC, memo } from 'react';
import { Filter } from '../../Filter';
import { SourceFilterProps } from './types';

const SourceFilter: FC<SourceFilterProps> = ({
  setSources,
  sourceOptions,
  sources,
}) => {
  return (
    <Filter
      allOptions={sourceOptions}
      name='Source'
      options={sources}
      setIsFilterSelected={() => console.log('setIsFilterSelected')}
      setOptions={setSources}
    />
  );
};

export const MemoizedSourceFilter = memo(SourceFilter);
