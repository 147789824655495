import * as protobufjs from 'protobufjs';
import Cookies from 'js-cookie';
import Long from 'long';
import { CancelablePromise } from '../util/async';
import { CancelableService } from './types';
import { MORF_ORG_ID, STYTCH_SESSION_JWT } from '@morf/constants';
import { Subject } from 'rxjs';
import { accounts } from '@morf/proto/accounts_v1_ts_proto';
import { formsort } from '@morf/proto/formsort_v1_ts_proto';
import { getRpcMethodNames } from './getRpcMethodNames';
import { organization } from '@morf/proto/organization_v1_ts_proto';
import { patients } from '@morf/proto/patients_v1_ts_proto';
import { ping } from '@morf/proto/ping_ts_proto';
import { profiles } from '@morf/proto/profiles_v1_ts_proto';
import { segment } from '@morf/proto/segment_v1_ts_proto';
import { translateError } from './translateError';
import { workflow_monitoring } from '@morf/proto/workflow_monitoring_v2_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export class RpcService {
  serverHostname: string;
  accountsV1Service: CancelableService<accounts.v1.AccountsService>;
  formsortService: CancelableService<formsort.v1.FormsortService>;
  organizationV1Service: CancelableService<organization.v1.OrganizationService>;
  patientsV1Service: CancelableService<patients.v1.PatientsService>;
  pingService: CancelableService<ping.PingService>;
  profilesV1Service: CancelableService<profiles.v1.ProfilesService>;
  segmentV1Service: CancelableService<segment.v1.SegmentService>;
  workflowMonitoringV2Service: CancelableService<workflow_monitoring.v2.WorkflowMonitoringService>;
  workflowsV1Service: CancelableService<workflows.v1.WorkflowsService>;
  events: Subject<string>;

  constructor() {
    protobufjs.util.Long = Long;
    protobufjs.configure();

    this.serverHostname = process.env.NEXT_PUBLIC_BACKEND_HOST || '';

    this.accountsV1Service = this.getExtendedService(
      new accounts.v1.AccountsService(
        this.rpc.bind(this, 'accounts.v1.AccountsService')
      ),
      accounts.v1.AccountsService
    );

    this.formsortService = this.getExtendedService(
      new formsort.v1.FormsortService(
        this.rpc.bind(this, 'formsort.v1.FormsortService')
      ),
      formsort.v1.FormsortService
    );

    this.organizationV1Service = this.getExtendedService(
      new organization.v1.OrganizationService(
        this.rpc.bind(this, 'organization.v1.OrganizationService')
      ),
      organization.v1.OrganizationService
    );

    this.patientsV1Service = this.getExtendedService(
      new patients.v1.PatientsService(
        this.rpc.bind(this, 'patients.v1.PatientsService')
      ),
      patients.v1.PatientsService
    );

    this.pingService = this.getExtendedService(
      new ping.PingService(this.rpc.bind(this, 'ping.PingService')),
      ping.PingService
    );

    this.profilesV1Service = this.getExtendedService(
      new profiles.v1.ProfilesService(
        this.rpc.bind(this, 'profiles.v1.ProfilesService')
      ),
      profiles.v1.ProfilesService
    );

    this.segmentV1Service = this.getExtendedService(
      new segment.v1.SegmentService(
        this.rpc.bind(this, 'segment.v1.SegmentService')
      ),
      segment.v1.SegmentService
    );

    this.workflowMonitoringV2Service = this.getExtendedService(
      new workflow_monitoring.v2.WorkflowMonitoringService(
        this.rpc.bind(this, 'workflow_monitoring.v2.WorkflowMonitoringService')
      ),
      workflow_monitoring.v2.WorkflowMonitoringService
    );

    this.workflowsV1Service = this.getExtendedService(
      new workflows.v1.WorkflowsService(
        this.rpc.bind(this, 'workflows.v1.WorkflowsService')
      ),
      workflows.v1.WorkflowsService
    );

    this.events = new Subject();
  }

  debuggingEnabled(): boolean {
    const url = new URL(window.location.href);
    let sp = url.searchParams.get('debug');
    if (sp === '1' || sp === 'true' || sp === 'True') {
      return true;
    }
    return false;
  }

  rpc(servicePath: string, ...props: Parameters<protobufjs.RPCImpl>): void {
    const [method, requestData, callback] = props;
    const request = new XMLHttpRequest();
    request.open(
      'POST',
      `${this.serverHostname}/${servicePath}/${method.name}`,
      true
    );
    if (this.debuggingEnabled()) {
      request.setRequestHeader('x-morf-trace', 'force');
    }

    request.setRequestHeader('Content-Type', 'application/x-protobuf');
    request.responseType = 'arraybuffer';
    request.setRequestHeader(
      'x-stytch-session-jwt',
      Cookies.get(STYTCH_SESSION_JWT) || ''
    );
    request.setRequestHeader(
      'x-morf-organization-id',
      Cookies.get(MORF_ORG_ID) || ''
    );
    request.onload = () => {
      const status = request.getResponseHeader('grpc-status');
      const message = request.getResponseHeader('grpc-message');
      if (request.status >= 200 && request.status < 400 && status === '0') {
        callback(null, new Uint8Array(request.response));
        this.events.next(method.name);
        console.log(`Emitting event [${method.name}]`);
      } else {
        if (status !== null && message !== null) {
          const error = translateError(status, message);
          callback(
            new Error(`Grpc status: ${error.status}, message: ${error.message}`)
          );
        } else {
          callback(new Error('Grpc status or message is null'));
        }
      }
    };

    request.onerror = () => {
      callback(new Error('Connection error'));
    };

    request.send(requestData);
  }
  private getExtendedService<A extends protobufjs.rpc.Service>(
    service: A,
    f: Function
  ): CancelableService<A> {
    const extendedService = Object.create(service);
    for (const rpcName of getRpcMethodNames(f)) {
      extendedService[rpcName] = (request: Record<string, any>) => {
        return new CancelablePromise((service as any)[rpcName](request));
      };
    }
    return extendedService;
  }
}

export default new RpcService();
