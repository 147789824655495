import _ from 'lodash';
import { ActionExecutionRowProps } from './types';
import { ActionExecutionStatus } from '../../ActionExecutionStatus';
import { ActionExecutionSubRow } from './ActionExecutionSubRow';
import { Container } from '../../Container';
import { FC, memo, MouseEvent, useState } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { IntegrationIcon } from '../../IntegrationIcon';
import { SkeletonLoadable } from '../../Skeleton';
import { Text } from '../../Typography';
import { convertTimestampToMoment } from '../../Filters';
import { convertToKebabCase } from '../../Helpers/convertToKebabCase';
import { defaultDateTimeFormatWithSeconds } from '../../Filters/TimeFilter/constants';
import { useHover } from '../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { workflow_destination_applications } from '@morf/proto/workflow_destination_applications_v1_ts_proto';

const ActionExecutionRow: FC<ActionExecutionRowProps> = ({
  application,
  attempts,
  isLoading,
  isRowSelected,
  name,
  onRowClick,
  status,
}) => {
  const theme = useTheme();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const [isExpanded, setIsExpanded] = useState(false);

  const latestAttempt = _.last(attempts);

  const backgroundColor =
    isHovered && !isLoading ? theme.colors.ui.body : theme.colors.ui.card;

  const borderColor = isRowSelected
    ? theme.colors.main.primary.darker
    : theme.colors.ui.card;

  const carrotIcon = isExpanded ? 'carrot-down' : 'carrot-right';

  const cursor = isLoading ? 'default' : 'pointer';

  const hasMultipleAttempts = attempts.length > 1;

  const nameTag = isRowSelected ? 'h5' : 'p2';

  const formattedTimestamp =
    latestAttempt &&
    latestAttempt.executionStart &&
    convertTimestampToMoment(latestAttempt.executionStart).format(
      defaultDateTimeFormatWithSeconds
    );

  const handleRowClick = () => {
    onRowClick();
    setIsExpanded(true);
  };

  const handleCarrotIconClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isExpanded) {
      handleRowClick();
    } else {
      setIsExpanded(false);
    }
  };

  return (
    <Flexbox
      data-testid='action-execution-row'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={0.25}
      borderType='border'
      cursor={cursor}
      width='16.5rem'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleRowClick}
    >
      <Flexbox
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        pl={0.25}
        pr={0.5}
        py={0.75}
        gap={0}
      >
        {hasMultipleAttempts && (
          <Container height='auto' width='auto' onClick={handleCarrotIconClick}>
            <Icon
              name={carrotIcon}
              size={1.125}
              fill={theme.colors.ui.dark}
              stroke='none'
            />
          </Container>
        )}

        <SkeletonLoadable isLoading={isLoading} width='1rem' height='1rem'>
          <Container width='auto' height='auto'>
            <IntegrationIcon
              name={convertToKebabCase(
                workflow_destination_applications.v1.DestinationApplication
                  .Application[application]
              )}
              size={1.25}
            />
          </Container>
        </SkeletonLoadable>
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          pl={0.25}
          gap={0}
        >
          <SkeletonLoadable isLoading={isLoading} width='11.5rem' height='1rem'>
            <Container width='11.5rem' height='auto'>
              <Text tag={nameTag} whiteSpace='nowrap'>
                {name}
              </Text>
            </Container>
          </SkeletonLoadable>

          <SkeletonLoadable
            isLoading={isLoading}
            width='11.5rem'
            height='0.5rem'
          >
            <Text tag='p3' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {formattedTimestamp}
            </Text>
          </SkeletonLoadable>
        </Flexbox>
        <SkeletonLoadable isLoading={isLoading} width='1rem' height='1rem'>
          <ActionExecutionStatus
            variant='icon'
            status={status}
            expectedExecutionEnd={undefined}
          />
        </SkeletonLoadable>
      </Flexbox>

      {hasMultipleAttempts && isExpanded && (
        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          onMouseEnter={handleMouseLeave}
          gap={0}
        >
          {attempts.map((a, i) => (
            <ActionExecutionSubRow
              key={i}
              {...a}
              isLastSubRow={i === attempts.length - 1}
            />
          ))}
        </Flexbox>
      )}
    </Flexbox>
  );
};

export const MemoizedActionExecutionRow = memo(ActionExecutionRow);
