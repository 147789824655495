import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../../Flexbox';
import { InsetTable } from '../../../../InsetTable';
import { ParamSourceConfigProps } from '../../../types';
import { ProfileUpdateNodeProps } from '../types';
import { SelectValuePairCategory } from '../../DestinationActionNode/DestinationActionConfigure/SelectValuePairModal/types';
import { SelectValuePairModal } from '../../DestinationActionNode/DestinationActionConfigure/SelectValuePairModal';
import { columns } from './columns';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { formatProfileUpdateProperties } from './formatProfileUpdateProperties';
import { google } from '@morf/proto/checked_ts_proto';
import { handleNodeSave } from '../handleNodeSave';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../../../../../apps/admin/components/context/workflow/useWorkflow';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

const ProfileUpdateProperties: FC<ProfileUpdateNodeProps> = (node) => {
  const id = node.id;
  const { configuration, onUpdateNode, onCreateCustomProperty, isReadOnly } =
    node.data.value;
  const theme = useTheme();
  const { nodes, setNodes } = useWorkflow();

  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedSubRow, setSelectedSubRow] = useState<number | null>(null);

  const updateProfileProperties =
    configuration?.morfV1?.updateProfileProperties;

  const formattedProperties = formatProfileUpdateProperties(
    updateProfileProperties,
    isReadOnly
  );

  const calculateRowsCountPrior = (index: number) =>
    formattedProperties
      .slice(0, index)
      .reduce(
        (acc, curr) =>
          acc +
          (curr.subRows?.length && curr.subRows?.length > 0
            ? curr.subRows.length
            : 1),
        0
      );

  const selectedIndex =
    selectedRow != null
      ? calculateRowsCountPrior(selectedRow) + (selectedSubRow ?? 0)
      : null;

  const isNewPropertyCell =
    selectedRow !== null &&
    selectedColumn === 0 &&
    !formattedProperties[selectedRow]?.param?.fieldName;

  const isSourceCell = selectedIndex !== null && selectedColumn === 1;

  const selectedParam: workflow_parameters.v1.DestinationActionParameter | null =
    isSourceCell &&
    updateProfileProperties?.propertyUpdateSources?.[selectedIndex]?.property
      ? new workflow_parameters.v1.DestinationActionParameter({
          static: new workflow_parameters.v1.StaticDestinationActionParameter({
            fieldName:
              updateProfileProperties?.propertyUpdateSources?.[selectedIndex]
                ?.property?.propertyName,
            fieldType:
              new workflow_parameters.v1.StaticDestinationActionParameterType({
                primitiveType:
                  google.api.expr.v1alpha1.Type.PrimitiveType.STRING,
              }),
            sensitivity:
              updateProfileProperties?.propertyUpdateSources?.[selectedIndex]
                ?.property?.sensitivities,
            isRequired: false,
          }),
        })
      : null;

  const selectedSource =
    isSourceCell &&
    updateProfileProperties?.propertyUpdateSources?.[selectedIndex]?.source
      ? new workflow_parameters.v1.DestinationActionParameterSource({
          ...updateProfileProperties.propertyUpdateSources[selectedIndex]
            .source,
        })
      : null;

  const handlePropertySave = (
    updatedSources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration.morfV1?.updateProfileProperties,
              propertyUpdateSources:
                configuration.morfV1?.updateProfileProperties?.propertyUpdateSources?.flatMap(
                  (p, i) => {
                    if (i === selectedIndex) {
                      return updatedSources.map(
                        (updatedSource) =>
                          new destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping(
                            {
                              property: updatedSource.profilePropertyLookup,
                              source:
                                new workflow_parameters.v1.DestinationActionParameterSource(
                                  {
                                    elidedValue: {},
                                  }
                                ),
                            }
                          )
                      );
                    }
                    return [p];
                  }
                ),
            }),
        }),
      });
      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleSourceSave = (
    sources: workflow_parameters.v1.DestinationActionParameterSource[]
  ) => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration.morfV1?.updateProfileProperties,
              propertyUpdateSources:
                configuration.morfV1?.updateProfileProperties?.propertyUpdateSources.map(
                  (p, i) => {
                    return i === selectedIndex
                      ? { property: p.property, source: sources[0] }
                      : p;
                  }
                ),
            }),
        }),
      });
      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleRowAdd = () => {
    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration?.morfV1?.updateProfileProperties,
              propertyUpdateSources: [
                ...(configuration.morfV1?.updateProfileProperties
                  ?.propertyUpdateSources || []),
                new destinationactions.morf.v1.UpdateProfileProperties.PropertyMapping(
                  {
                    property: null,
                    source:
                      new workflow_parameters.v1.DestinationActionParameterSource(
                        {
                          elidedValue: {},
                        }
                      ),
                  }
                ),
              ],
            }),
        }),
      });
      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleRowRemove = (rowId: number) => {
    const rowsCountPriorToRowId = calculateRowsCountPrior(rowId);
    const subRowsCount = formattedProperties?.[rowId]?.subRows?.length || 0;
    const subRowsCountIndex =
      subRowsCount > 0 ? subRowsCount - 1 : subRowsCount;

    const startIndex = rowsCountPriorToRowId;
    const endIndex = rowsCountPriorToRowId + subRowsCountIndex;

    if (configuration) {
      const destinationAction = new workflows.v1.WorkflowDestinationAction({
        ...configuration,
        morfV1: new destinationactions.morf.v1.DestinationAction({
          updateProfileProperties:
            new destinationactions.morf.v1.UpdateProfileProperties({
              ...configuration?.morfV1?.updateProfileProperties,
              propertyUpdateSources:
                configuration.morfV1?.updateProfileProperties?.propertyUpdateSources.filter(
                  (_, index) =>
                    !Array.from(
                      { length: endIndex - startIndex + 1 },
                      (_, index) => startIndex + index
                    ).includes(index)
                ),
            }),
        }),
      });
      handleNodeSave(destinationAction, id, nodes, setNodes, onUpdateNode);
    }
  };

  const handleClose = () => {
    setSelectedRow(null);
    setSelectedSubRow(null);
  };

  return (
    <Flexbox
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <InsetTable<ParamSourceConfigProps>
        columns={columns}
        data={formattedProperties}
        emptyText='There are no profile properties!'
        headCellColor={theme.colors.text.body}
        selectedRow={selectedRow}
        totalCount={formattedProperties.length}
        totalFetched={formattedProperties.length}
        {...(!isReadOnly && {
          onColumnClick: setSelectedColumn,
          onRowAdd: handleRowAdd,
          onRowClick: setSelectedRow,
          onRowRemove: handleRowRemove,
          onSubRowClick: setSelectedSubRow,
        })}
      />

      {isNewPropertyCell && onCreateCustomProperty && (
        <SelectValuePairModal
          onClick={handlePropertySave}
          onClose={handleClose}
          onCreateCustomProperty={onCreateCustomProperty}
          selectedSource={null}
          visibleCategories={[
            SelectValuePairCategory.MorfProperties,
            SelectValuePairCategory.CustomProperties,
          ]}
        />
      )}

      {isSourceCell && onCreateCustomProperty && (
        <SelectValuePairModal
          onClick={handleSourceSave}
          onClose={handleClose}
          onCreateCustomProperty={onCreateCustomProperty}
          selectedParam={selectedParam}
          selectedSource={selectedSource}
        />
      )}
    </Flexbox>
  );
};

export const MemoizedProfileUpdateProperties = memo(ProfileUpdateProperties);
