import _ from 'lodash';
import { FC, memo, useEffect, useState } from 'react';
import { FilterChip } from '../../Filter/FilterChip';
import { Flexbox } from '../../Flexbox';
import { TimeDropdown } from './TimeDropdown';
import { TimeFilterProps } from './types';
import { TimeOption } from './TimeDropdown/TimeOption/types';
import { WrapperModal } from '../../WrapperModal';
import { useRef } from 'react';
import { useRouter } from 'next/router';

const TimeFilter: FC<TimeFilterProps> = ({
  defaultTimeOption,
  setIsTimeFilterSelected,
  setTimeOption,
  timeOption,
}) => {
  const router = useRouter();
  const { query } = router;
  const containerRef = useRef<HTMLDivElement>(null);

  const [showTimeOptions, setShowTimeOptions] = useState<boolean>(false);
  const [position, setPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const updateRouterQuery = (label: string | null) => {
    const query = { ...router.query };

    if (label) {
      query.time = label;
    } else {
      delete query.time;
    }

    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
  };

  const handleToggleTimeOptions = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top / 16 + 1.75 + 'rem',
        left: rect.left / 16 + 'rem',
      });
    }
    setShowTimeOptions(!showTimeOptions);
  };
  const handleCloseTimeOptions = () => setShowTimeOptions(false);

  const handleApplyClick = (timeOption: TimeOption) => {
    setShowTimeOptions(false);
    setTimeOption(timeOption);
    updateRouterQuery(timeOption.label);
  };

  const handleResetClick = () => {
    setShowTimeOptions(false);
    setTimeOption(defaultTimeOption);
    updateRouterQuery(null);
    setIsTimeFilterSelected(false);
  };

  useEffect(() => {
    !query.time && updateRouterQuery(defaultTimeOption.label);
  }, []);

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid='time-filter'
      width='auto'
      height='auto'
    >
      <FilterChip
        name='Time'
        onClick={handleToggleTimeOptions}
        showOptions={showTimeOptions}
        value={timeOption.label}
      />

      {showTimeOptions && (
        <WrapperModal
          {...position}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          width='13.125rem'
          onClose={handleCloseTimeOptions}
        >
          <TimeDropdown
            timeOption={timeOption}
            onApplyClick={handleApplyClick}
            onResetClick={handleResetClick}
          />
        </WrapperModal>
      )}
    </Flexbox>
  );
};

export const MemoizedTimeFilter = memo(TimeFilter);
