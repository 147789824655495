import _ from 'lodash';
import { ChangeEvent, FC, KeyboardEvent, memo, useState } from 'react';
import { NodePanelProps } from './types';
import { NodeType } from '@morf/ui/Workflow/types';
import { ReadOnlyTag } from './ReadOnlyTag';
import { iconNameNodeTypeMap } from '@morf/ui/Workflow/CustomNode/constants';
import { useTheme } from '@morf/theming';
import { useWorkflow } from '../../context/workflow/useWorkflow';
import {
  Flexbox,
  Icon,
  Input,
  IntegrationIcon,
  Tab,
  Tabs,
  Text,
} from '@morf/ui';

const NodePanel: FC<NodePanelProps> = ({
  node,
  onClose,
  onUpdateDescription,
  onUpdateName,
  tabs,
}) => {
  const theme = useTheme();
  const { nodes, setNodes } = useWorkflow();
  const { description, integrationIconName, isReadOnly, isRequired, name } =
    node.data.value;
  const type = node.type as NodeType;

  const hasSingleTab = _.size(tabs) === 1;

  const [selectedTab, setSelectedTab] = useState(0);

  const [editName, setEditName] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);

  const [editDescription, setEditDescription] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState(description);

  const isNameReadOnly =
    isReadOnly ||
    type === NodeType.TriggerNode ||
    type === NodeType.ProfileLookupNode;

  const nameBorderColor =
    editName && !isNameReadOnly
      ? theme.colors.ui.divider
      : theme.colors.ui.card;

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUpdatedName(e.target.value);

  const handleNameKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && updatedName && onUpdateName) {
      if (isRequired) {
        const updatedNodes = nodes.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: {
                ...n.data,
                value: {
                  ...n.data.value,
                  name: updatedName,
                },
              },
            };
          }
          return n;
        });
        setNodes(updatedNodes);
      } else {
        onUpdateName(updatedName);
      }
    }
  };

  const handleNameMouseEnter = () => setEditName(true);

  const handleNameMouseLeave = () => setEditName(false);

  const showDescription =
    type !== NodeType.TriggerNode &&
    type !== NodeType.ProfileLookupNode &&
    (!isReadOnly || (isReadOnly && description));

  const descriptionBorderColor =
    editDescription && !isReadOnly
      ? theme.colors.ui.divider
      : theme.colors.ui.card;

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUpdatedDescription(e.target.value);

  const handleDescriptionKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && updatedDescription && onUpdateDescription) {
      if (isRequired) {
        const updatedNodes = nodes.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: {
                ...n.data,
                value: {
                  ...n.data.value,
                  description: updatedDescription,
                },
              },
            };
          }
          return n;
        });
        setNodes(updatedNodes);
      } else {
        onUpdateDescription(updatedDescription);
      }
    }
  };

  const handleDescriptionMouseEnter = () => setEditDescription(true);

  const handleDescriptionMouseLeave = () => setEditDescription(false);

  const handleTabChange = (selectedIndex: number) => {
    setSelectedTab(selectedIndex);
  };

  return (
    <Flexbox
      key={node.id}
      data-testid='node-panel'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      backgroundColor={theme.colors.ui.card}
      gap={0}
    >
      <Flexbox
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
      >
        <Flexbox
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          height='auto'
          p={1.25}
          pb={0}
        >
          <Flexbox
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={0.25}
          >
            {integrationIconName && (
              <IntegrationIcon name={integrationIconName} />
            )}

            {!integrationIconName && iconNameNodeTypeMap[type] && (
              <Icon
                name={iconNameNodeTypeMap[type]!}
                stroke={theme.colors.text.muted}
                size={1.25}
              />
            )}

            <Text tag='p2' color={theme.colors.text.muted}>
              {node.data.value.title}
            </Text>
          </Flexbox>

          <Icon
            name='close'
            stroke={theme.colors.text.muted}
            onClick={onClose}
            cursor='pointer'
          />
        </Flexbox>

        <Flexbox
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          height='auto'
          gap={0}
          px={1}
        >
          <Input
            id='name'
            borderColor={nameBorderColor}
            onChange={handleNameChange}
            onKeyDown={handleNameKeyDown}
            onMouseEnter={handleNameMouseEnter}
            onMouseLeave={handleNameMouseLeave}
            p={0.25}
            readOnly={isNameReadOnly}
            tag='h2'
            value={name}
          />

          {showDescription && (
            <Input
              id='description'
              borderColor={descriptionBorderColor}
              color={theme.colors.ui.dark}
              onChange={handleDescriptionChange}
              onKeyDown={handleDescriptionKeyDown}
              onMouseEnter={handleDescriptionMouseEnter}
              onMouseLeave={handleDescriptionMouseLeave}
              p={0.25}
              placeholder='Add description'
              readOnly={isReadOnly}
              value={description}
            />
          )}
        </Flexbox>

        {isReadOnly && <ReadOnlyTag />}
      </Flexbox>

      {hasSingleTab ? (
        <Flexbox p={1.25} overflow='scroll'>
          {tabs[0].children}
        </Flexbox>
      ) : (
        <Tabs
          id={node.id}
          initialValue={selectedTab}
          onChange={handleTabChange}
          pt={0.5}
          height='3rem'
        >
          {tabs.map((tab, index) => {
            return (
              <Tab key={index} label={tab.name} p={1.25}>
                {tab.children}
              </Tab>
            );
          })}
        </Tabs>
      )}
    </Flexbox>
  );
};

export const MemoizedNodePanel = memo(NodePanel);
