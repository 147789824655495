import moment, { Moment } from 'moment';
import { timing } from '@morf/proto/timing_v1_ts_proto';

export const convertTimestampToMoment = (
  timestamp: timing.v1.Timestamp
): Moment => {
  return moment(
    new Date(timestamp.seconds.toNumber() * 1000 + timestamp.nanoseconds / 1e6)
  );
};
