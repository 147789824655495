import _ from 'lodash';
import { FC, memo } from 'react';
import { Filter } from '../../Filter';
import { WorkflowFilterProps } from './types';

const WorkflowFilter: FC<WorkflowFilterProps> = ({
  setWorkflows,
  workflowOptions,
  workflows,
}) => {
  return (
    <Filter
      allOptions={workflowOptions}
      name='Workflow'
      options={workflows}
      setIsFilterSelected={() => console.log('setIsFilterSelected')}
      setOptions={setWorkflows}
    />
  );
};

export const MemoizedWorkflowFilter = memo(WorkflowFilter);
