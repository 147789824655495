import _ from 'lodash';
import { CustomOption } from './CustomOption';
import { CustomTimeRange } from './CustomTimeRange';
import { FC, memo, useState } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { Text } from '../../../Typography';
import { TimeDropdownProps } from './types';
import { TimeOption } from './TimeOption';
import { timeOptions } from './TimeOption/timeOptions';
import { useTheme } from '@morf/theming';

const TimeDropdown: FC<TimeDropdownProps> = ({
  onApplyClick,
  onResetClick,
  timeOption,
}) => {
  const theme = useTheme();
  const [showCustomTimeRange, setShowCustomTimeRange] =
    useState<boolean>(false);

  const handleShowCustomTimeRange = () => setShowCustomTimeRange(true);
  const handleCloseCustomTimeRange = () => setShowCustomTimeRange(false);

  return (
    <Flexbox
      data-testid='time-dropdown'
      direction='column'
      height='auto'
      p={0.5}
      gap={0.5}
    >
      {showCustomTimeRange ? (
        <CustomTimeRange
          onApplyClick={onApplyClick}
          onBackClick={handleCloseCustomTimeRange}
          onResetClick={onResetClick}
          timeOption={timeOption}
        />
      ) : (
        <>
          <Flexbox
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            height='auto'
            pr={0.25}
          >
            <Text tag='p3' color={theme.colors.text.muted}>
              Time:
            </Text>
            <Icon
              name='trash'
              cursor='pointer'
              stroke={theme.colors.ui.dark}
              size={0.75}
              strokeWidth={2}
              onClick={onResetClick}
            />
          </Flexbox>

          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0}
          >
            {timeOptions.map((t, index) => (
              <TimeOption
                key={index}
                timeOption={t}
                isSelected={_.isEqual(timeOption.label, t.label)}
                onClick={onApplyClick}
              />
            ))}
            <CustomOption
              isSelected={timeOption.label.includes(' - ')}
              onClick={handleShowCustomTimeRange}
            />
          </Flexbox>
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedTimeDropdown = memo(TimeDropdown);
