import rpcService from '@morf/server/browser-server';
import { FC } from 'react';
import { PermissionsContext } from './PermissionsContext';
import { PermissionsProviderProps } from './types';
import { organization } from '@morf/proto/organization_v1_ts_proto';
import { useQuery } from '@tanstack/react-query';

export const PermissionsProvider: FC<PermissionsProviderProps> = ({
  children,
  organizationId,
}) => {
  const { data } = useQuery<organization.v1.ListAllowedRpcsResponse>(
    ['/organization.v1.OrganizationService/ListAllowedRpcs', organizationId],
    async () => {
      return await rpcService.organizationV1Service.listAllowedRpcs({
        stub: '',
      });
    }
  );
  const hasRpcPermission = (names: string[]): boolean => {
    return names.every((name) => data?.allowedRpcs?.includes(name));
  };

  const canCreateAPIKey = hasRpcPermission([
    '/organization.v1.OrganizationService/CreateAPIKey',
  ]);

  const canCreateUser = hasRpcPermission([
    '/accounts.v1.AccountsService/CreateUser',
  ]);

  const canCreateWorkflow = hasRpcPermission([
    '/workflows.v1.WorkflowsService/Create',
  ]);

  const canEditWorkflow = hasRpcPermission([
    '/workflows.v1.WorkflowsService/Update',
    '/workflows.v1.WorkflowsService/UpdateWorkflowVersion',
    '/workflows.v1.WorkflowsService/UpdateWorkflowVersionNode',
  ]);

  const canUpdateUser = hasRpcPermission([
    '/accounts.v1.AccountsService/UpdateUser',
  ]);

  return (
    <PermissionsContext.Provider
      value={{
        canCreateAPIKey,
        canCreateUser,
        canCreateWorkflow,
        canEditWorkflow,
        canUpdateUser,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
