import { Container } from '../Container';
import { ExtendedRowData, TableProps } from '../Table/types';
import { Table } from '../Table/Table';
import { memo, useEffect, useRef, useState } from 'react';
import { useTheme } from '@morf/theming';

export const InsetTable: <RowData>(
  props: TableProps<ExtendedRowData<RowData>>
) => JSX.Element = ({ ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [maxTableHeight, setMaxTableHeight] = useState<number>(0);
  const maxHeight = `${maxTableHeight}px`;

  useEffect(() => {
    if (containerRef.current) {
      const parentHeight = containerRef.current.parentElement!.offsetHeight;
      setMaxTableHeight(parentHeight);
    }
  }, []);

  return (
    <Container
      ref={containerRef}
      overflow='scroll'
      height={!!props.data.length ? 'auto' : '100%'}
      maxHeight={maxHeight}
      borderType='border'
      borderRadius={0.5}
      p={0}
    >
      <Table
        headCellHeight='2.25rem'
        subCellHeight='auto'
        parentCellHeight='auto'
        cellHeight='auto'
        headCellColor={theme.colors.text.muted}
        headCellBackgroundColor='white'
        cellBackgroundColor={theme.colors.ui.card}
        cellHoveredBackgroundColor='none'
        cellHoveredBorderColor='none'
        tableMaxHeight={maxHeight}
        {...props}
      />
    </Container>
  );
};

export const MemoizedInsetTable = memo(InsetTable) as typeof InsetTable;
