'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { accounts as accounts$1 } from './accounts_v1_ts_proto';
import { destinationactions as destinationactions$1 } from './destinationactions/activecampaign/v1_ts_proto';
import { destinationactions as destinationactions$2 } from './destinationactions/customerio/v1_ts_proto';
import { destinationactions as destinationactions$3 } from './destinationactions/facebook/v1_ts_proto';
import { destinationactions as destinationactions$4 } from './destinationactions/google/v1_ts_proto';
import { destinationactions as destinationactions$5 } from './destinationactions/healthie/v1_ts_proto';
import { destinationactions as destinationactions$7 } from './destinationactions/intakeq/v1_ts_proto';
import { destinationactions as destinationactions$6 } from './destinationactions/morf/v1_ts_proto';
import { destinationactions as destinationactions$8 } from './destinationactions/segment/v1_ts_proto';
import { destinationactions as destinationactions$10 } from './destinationactions/slack/v1_ts_proto';
import { destinationactions as destinationactions$9 } from './destinationactions/spruce/v1_ts_proto';
import { google as google$1 } from './empty_ts_proto';
import { event_payload as event_payload$1 } from './event_payload_v2_ts_proto';
import { fetchactions as fetchactions$2 } from './fetchactions/freshdesk/v1_ts_proto';
import { fetchactions as fetchactions$1 } from './fetchactions/healthie/v1_ts_proto';
import { fetchactions as fetchactions$4 } from './fetchactions/hubspot/v1_ts_proto';
import { fetchactions as fetchactions$5 } from './fetchactions/intercom/v1_ts_proto';
import { fetchactions as fetchactions$3 } from './fetchactions/morf/v1_ts_proto';
import { object_category as object_category$1 } from './object_category_v1_ts_proto';
import { profiles as profiles$1 } from './profiles_v1_ts_proto';
import { source_application as source_application$1 } from './source_application_v2_ts_proto';
import { sources as sources$1 } from './sources_v2_ts_proto';
import { google as google$2 } from './struct_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { workflow_destination_applications as workflow_destination_applications$1 } from './workflow_destination_applications_v1_ts_proto';
import { workflow_evaluation_results as workflow_evaluation_results$1 } from './workflow_evaluation_results_v1_ts_proto';
import { workflow_extra_fetched_data as workflow_extra_fetched_data$1 } from './workflow_extra_fetched_data_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from './workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflows = (() => {
  const workflows = {};
  workflows.v1 = (() => {
    const v1 = {};
    v1.CreateRequest = (() => {
      class CreateRequest {
        constructor(properties) {
          this.tags = [];
          this.triggerObjectTypeFilters = [];
          this.nodes = [];
          this.edges = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(10).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(18).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(26).string(element);
            }
          }
          if (
            message.sourceTriggerEvents != null &&
            Object.hasOwnProperty.call(message, 'sourceTriggerEvents')
          ) {
            sources$1.v2.CreateRequest.encode(
              message.sourceTriggerEvents,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.triggerObjectCategory != null &&
            Object.hasOwnProperty.call(message, 'triggerObjectCategory')
          ) {
            writer.uint32(40).int32(message.triggerObjectCategory);
          }
          if (
            message.triggerObjectTypeFilters != null &&
            Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
          ) {
            for (const element of message.triggerObjectTypeFilters) {
              writer.uint32(50).string(element);
            }
          }
          if (
            message.triggerExtraCelExpression != null &&
            Object.hasOwnProperty.call(message, 'triggerExtraCelExpression')
          ) {
            writer.uint32(58).string(message.triggerExtraCelExpression);
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            for (const element of message.nodes) {
              $root.workflows.v1.CreateWorkflowNodeRequest.encode(
                element,
                writer.uint32(74).fork()
              ).ldelim();
            }
          }
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            for (const element of message.edges) {
              $root.workflows.v1.WorkflowEdge.encode(
                element,
                writer.uint32(82).fork()
              ).ldelim();
            }
          }
          if (
            message.uniquenessConflictResolution != null &&
            Object.hasOwnProperty.call(message, 'uniquenessConflictResolution')
          ) {
            $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
              message.uniquenessConflictResolution,
              writer.uint32(90).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.name = reader.string();
                break;
              }
              case 2: {
                message.description = reader.string();
                break;
              }
              case 3: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 4: {
                message.sourceTriggerEvents = sources$1.v2.CreateRequest.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.triggerObjectCategory = reader.int32();
                break;
              }
              case 6: {
                if (
                  !message.triggerObjectTypeFilters ||
                  !message.triggerObjectTypeFilters.length
                ) {
                  message.triggerObjectTypeFilters = [];
                }
                message.triggerObjectTypeFilters.push(reader.string());
                break;
              }
              case 7: {
                message.triggerExtraCelExpression = reader.string();
                break;
              }
              case 8: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                if (!message.nodes || !message.nodes.length) {
                  message.nodes = [];
                }
                message.nodes.push(
                  $root.workflows.v1.CreateWorkflowNodeRequest.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 10: {
                if (!message.edges || !message.edges.length) {
                  message.edges = [];
                }
                message.edges.push(
                  $root.workflows.v1.WorkflowEdge.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 11: {
                message.uniquenessConflictResolution =
                  $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CreateRequest();
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.sourceTriggerEvents != null) {
            if (typeof object.sourceTriggerEvents !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateRequest.sourceTriggerEvents: object expected, but got ' +
                  typeof object.sourceTriggerEvents
              );
            }
            message.sourceTriggerEvents = sources$1.v2.CreateRequest.fromObject(
              object.sourceTriggerEvents
            );
          }
          if (object.triggerObjectCategory != null) {
            switch (object.triggerObjectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.triggerObjectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.triggerObjectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.triggerObjectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.triggerObjectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.triggerObjectCategory = 4;
                break;
              }
              default: {
                if (typeof object.triggerObjectCategory == 'number') {
                  message.triggerObjectCategory = object.triggerObjectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.triggerObjectTypeFilters) {
            if (!Array.isArray(object.triggerObjectTypeFilters)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.triggerObjectTypeFilters: array type expected, but got ' +
                  typeof object.triggerObjectTypeFilters
              );
            }
            message.triggerObjectTypeFilters = new Array(
              object.triggerObjectTypeFilters.length
            );
            for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
              message.triggerObjectTypeFilters[i] = String(
                object.triggerObjectTypeFilters[i]
              );
            }
          }
          if (object.triggerExtraCelExpression != null) {
            message.triggerExtraCelExpression = String(
              object.triggerExtraCelExpression
            );
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateRequest.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.nodes) {
            if (!Array.isArray(object.nodes)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.nodes: array type expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes = new Array(object.nodes.length);
            for (let i = 0; i < object.nodes.length; ++i) {
              if (typeof object.nodes[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.CreateRequest.nodes: object expected, but got ' +
                    typeof object.nodes[i]
                );
              }
              message.nodes[i] =
                $root.workflows.v1.CreateWorkflowNodeRequest.fromObject(
                  object.nodes[i]
                );
            }
          }
          if (object.edges) {
            if (!Array.isArray(object.edges)) {
              throw new TypeError(
                '.workflows.v1.CreateRequest.edges: array type expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges = new Array(object.edges.length);
            for (let i = 0; i < object.edges.length; ++i) {
              if (typeof object.edges[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.CreateRequest.edges: object expected, but got ' +
                    typeof object.edges[i]
                );
              }
              message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                object.edges[i]
              );
            }
          }
          if (object.uniquenessConflictResolution != null) {
            if (typeof object.uniquenessConflictResolution !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateRequest.uniquenessConflictResolution: object expected, but got ' +
                  typeof object.uniquenessConflictResolution
              );
            }
            message.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                object.uniquenessConflictResolution
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
            object.triggerObjectTypeFilters = [];
            object.nodes = [];
            object.edges = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.name = '';
            object.sourceTriggerEvents = null;
            object.triggerObjectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.profileLookupConfiguration = null;
          }
          let keys;
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (
            message.sourceTriggerEvents != null &&
            message.hasOwnProperty('sourceTriggerEvents')
          ) {
            object.sourceTriggerEvents = sources$1.v2.CreateRequest.toObject(
              message.sourceTriggerEvents,
              options
            );
          }
          if (
            message.triggerObjectCategory != null &&
            message.hasOwnProperty('triggerObjectCategory')
          ) {
            object.triggerObjectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.triggerObjectCategory
                  ] === undefined
                  ? message.triggerObjectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.triggerObjectCategory
                    ]
                : message.triggerObjectCategory;
          }
          if (
            message.triggerObjectTypeFilters &&
            message.triggerObjectTypeFilters.length
          ) {
            object.triggerObjectTypeFilters = new Array(
              message.triggerObjectTypeFilters.length
            );
            for (let i = 0; i < message.triggerObjectTypeFilters.length; ++i) {
              object.triggerObjectTypeFilters[i] =
                message.triggerObjectTypeFilters[i];
            }
          }
          if (
            message.triggerExtraCelExpression != null &&
            message.hasOwnProperty('triggerExtraCelExpression')
          ) {
            object.triggerExtraCelExpression =
              message.triggerExtraCelExpression;
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
          }
          if (message.nodes && message.nodes.length) {
            object.nodes = new Array(message.nodes.length);
            for (let i = 0; i < message.nodes.length; ++i) {
              object.nodes[i] =
                $root.workflows.v1.CreateWorkflowNodeRequest.toObject(
                  message.nodes[i],
                  options
                );
            }
          }
          if (message.edges && message.edges.length) {
            object.edges = new Array(message.edges.length);
            for (let i = 0; i < message.edges.length; ++i) {
              object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                message.edges[i],
                options
              );
            }
          }
          if (
            message.uniquenessConflictResolution != null &&
            message.hasOwnProperty('uniquenessConflictResolution')
          ) {
            object.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                message.uniquenessConflictResolution,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateRequest';
        }
      }

      CreateRequest.prototype.name = '';
      CreateRequest.prototype.description = null;
      CreateRequest.prototype.tags = $util.emptyArray;
      CreateRequest.prototype.sourceTriggerEvents = null;
      CreateRequest.prototype.triggerObjectCategory = 0;
      CreateRequest.prototype.triggerObjectTypeFilters = $util.emptyArray;
      CreateRequest.prototype.triggerExtraCelExpression = null;
      CreateRequest.prototype.profileLookupConfiguration = null;
      CreateRequest.prototype.nodes = $util.emptyArray;
      CreateRequest.prototype.edges = $util.emptyArray;
      CreateRequest.prototype.uniquenessConflictResolution = null;

      return CreateRequest;
    })();

    v1.CreateResponse = (() => {
      class CreateResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateResponse) {
            return object;
          }
          const message = new $root.workflows.v1.CreateResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateResponse';
        }
      }

      CreateResponse.prototype.workflow = null;

      return CreateResponse;
    })();

    v1.CreateWorkflowCloneRequest = (() => {
      class CreateWorkflowCloneRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowCloneRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.targetOrganizationId != null &&
            Object.hasOwnProperty.call(message, 'targetOrganizationId')
          ) {
            writer.uint32(10).string(message.targetOrganizationId);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(18).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(24).int64(message.version);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowCloneRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.targetOrganizationId = reader.string();
                break;
              }
              case 2: {
                message.workflowId = reader.string();
                break;
              }
              case 3: {
                message.version = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateWorkflowCloneRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowCloneRequest();
          if (object.targetOrganizationId != null) {
            message.targetOrganizationId = String(object.targetOrganizationId);
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.targetOrganizationId = '';
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.targetOrganizationId != null &&
            message.hasOwnProperty('targetOrganizationId')
          ) {
            object.targetOrganizationId = message.targetOrganizationId;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowCloneRequest';
        }
      }

      CreateWorkflowCloneRequest.prototype.targetOrganizationId = '';
      CreateWorkflowCloneRequest.prototype.workflowId = '';
      CreateWorkflowCloneRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      return CreateWorkflowCloneRequest;
    })();

    v1.CreateWorkflowCloneResponse = (() => {
      class CreateWorkflowCloneResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowCloneResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowCloneResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.CreateWorkflowCloneResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowCloneResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowCloneResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowCloneResponse';
        }
      }

      CreateWorkflowCloneResponse.prototype.workflow = null;

      return CreateWorkflowCloneResponse;
    })();

    v1.CreateWorkflowNodeRequest = (() => {
      class CreateWorkflowNodeRequest {
        get type() {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowNodeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.destinationAction != null &&
            Object.hasOwnProperty.call(message, 'destinationAction')
          ) {
            $root.workflows.v1.WorkflowDestinationAction.encode(
              message.destinationAction,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.workflows.v1.CreateWorkflowFilterRequest.encode(
              message.filter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.pause != null &&
            Object.hasOwnProperty.call(message, 'pause')
          ) {
            $root.workflows.v1.WorkflowPause.encode(
              message.pause,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.fetchAction != null &&
            Object.hasOwnProperty.call(message, 'fetchAction')
          ) {
            $root.workflows.v1.WorkflowFetchAction.encode(
              message.fetchAction,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.restart != null &&
            Object.hasOwnProperty.call(message, 'restart')
          ) {
            $root.workflows.v1.WorkflowRestart.encode(
              message.restart,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowNodeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.destinationAction =
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.filter =
                  $root.workflows.v1.CreateWorkflowFilterRequest.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.pause = $root.workflows.v1.WorkflowPause.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.fetchAction =
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.restart = $root.workflows.v1.WorkflowRestart.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CreateWorkflowNodeRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowNodeRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.destinationAction != null) {
            if (typeof object.destinationAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.destinationAction: object expected, but got ' +
                  typeof object.destinationAction
              );
            }
            message.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.fromObject(
                object.destinationAction
              );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter =
              $root.workflows.v1.CreateWorkflowFilterRequest.fromObject(
                object.filter
              );
          }
          if (object.pause != null) {
            if (typeof object.pause !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.pause: object expected, but got ' +
                  typeof object.pause
              );
            }
            message.pause = $root.workflows.v1.WorkflowPause.fromObject(
              object.pause
            );
          }
          if (object.fetchAction != null) {
            if (typeof object.fetchAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.fetchAction: object expected, but got ' +
                  typeof object.fetchAction
              );
            }
            message.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.fromObject(
                object.fetchAction
              );
          }
          if (object.restart != null) {
            if (typeof object.restart !== 'object') {
              throw new TypeError(
                '.workflows.v1.CreateWorkflowNodeRequest.restart: object expected, but got ' +
                  typeof object.restart
              );
            }
            message.restart = $root.workflows.v1.WorkflowRestart.fromObject(
              object.restart
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.destinationAction != null &&
            message.hasOwnProperty('destinationAction')
          ) {
            object.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.toObject(
                message.destinationAction,
                options
              );
            if (options.oneofs) {
              object.type = 'destinationAction';
            }
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter =
              $root.workflows.v1.CreateWorkflowFilterRequest.toObject(
                message.filter,
                options
              );
            if (options.oneofs) {
              object.type = 'filter';
            }
          }
          if (message.pause != null && message.hasOwnProperty('pause')) {
            object.pause = $root.workflows.v1.WorkflowPause.toObject(
              message.pause,
              options
            );
            if (options.oneofs) {
              object.type = 'pause';
            }
          }
          if (
            message.fetchAction != null &&
            message.hasOwnProperty('fetchAction')
          ) {
            object.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.toObject(
                message.fetchAction,
                options
              );
            if (options.oneofs) {
              object.type = 'fetchAction';
            }
          }
          if (message.restart != null && message.hasOwnProperty('restart')) {
            object.restart = $root.workflows.v1.WorkflowRestart.toObject(
              message.restart,
              options
            );
            if (options.oneofs) {
              object.type = 'restart';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowNodeRequest';
        }
      }

      CreateWorkflowNodeRequest.prototype.id = '';
      CreateWorkflowNodeRequest.prototype.name = null;
      CreateWorkflowNodeRequest.prototype.description = null;
      CreateWorkflowNodeRequest.prototype.destinationAction = null;
      CreateWorkflowNodeRequest.prototype.filter = null;
      CreateWorkflowNodeRequest.prototype.pause = null;
      CreateWorkflowNodeRequest.prototype.fetchAction = null;
      CreateWorkflowNodeRequest.prototype.restart = null;

      return CreateWorkflowNodeRequest;
    })();

    v1.CreateWorkflowFilterRequest = (() => {
      class CreateWorkflowFilterRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CreateWorkflowFilterRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.celExpressionStr != null &&
            Object.hasOwnProperty.call(message, 'celExpressionStr')
          ) {
            writer.uint32(10).string(message.celExpressionStr);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CreateWorkflowFilterRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.celExpressionStr = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.CreateWorkflowFilterRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.CreateWorkflowFilterRequest();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = '';
          }
          let keys;
          if (
            message.celExpressionStr != null &&
            message.hasOwnProperty('celExpressionStr')
          ) {
            object.celExpressionStr = message.celExpressionStr;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CreateWorkflowFilterRequest';
        }
      }

      CreateWorkflowFilterRequest.prototype.celExpressionStr = '';

      return CreateWorkflowFilterRequest;
    })();

    v1.GetRequest = (() => {
      class GetRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetRequest) {
            return object;
          }
          const message = new $root.workflows.v1.GetRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetRequest';
        }
      }

      GetRequest.prototype.id = '';

      return GetRequest;
    })();

    v1.GetResponse = (() => {
      class GetResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetResponse) {
            return object;
          }
          const message = new $root.workflows.v1.GetResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetResponse';
        }
      }

      GetResponse.prototype.workflow = null;

      return GetResponse;
    })();

    v1.UpdateRequest = (() => {
      class UpdateRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            $root.workflows.v1.UpdateRequest.Tags.encode(
              message.tags,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.newVersion != null &&
            Object.hasOwnProperty.call(message, 'newVersion')
          ) {
            $root.workflows.v1.UpdateRequest.Version.encode(
              message.newVersion,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.tags = $root.workflows.v1.UpdateRequest.Tags.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.newVersion =
                  $root.workflows.v1.UpdateRequest.Version.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.UpdateRequest) {
            return object;
          }
          const message = new $root.workflows.v1.UpdateRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags != null) {
            if (typeof object.tags !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateRequest.tags: object expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = $root.workflows.v1.UpdateRequest.Tags.fromObject(
              object.tags
            );
          }
          if (object.newVersion != null) {
            if (typeof object.newVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateRequest.newVersion: object expected, but got ' +
                  typeof object.newVersion
              );
            }
            message.newVersion =
              $root.workflows.v1.UpdateRequest.Version.fromObject(
                object.newVersion
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags != null && message.hasOwnProperty('tags')) {
            object.tags = $root.workflows.v1.UpdateRequest.Tags.toObject(
              message.tags,
              options
            );
          }
          if (
            message.newVersion != null &&
            message.hasOwnProperty('newVersion')
          ) {
            object.newVersion =
              $root.workflows.v1.UpdateRequest.Version.toObject(
                message.newVersion,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateRequest';
        }
      }

      UpdateRequest.prototype.id = '';
      UpdateRequest.prototype.name = null;
      UpdateRequest.prototype.description = null;
      UpdateRequest.prototype.tags = null;
      UpdateRequest.prototype.newVersion = null;
      UpdateRequest.Tags = (() => {
        class Tags {
          constructor(properties) {
            this.tags = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Tags(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.tags != null &&
              Object.hasOwnProperty.call(message, 'tags')
            ) {
              for (const element of message.tags) {
                writer.uint32(10).string(element);
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Tags();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.tags || !message.tags.length) {
                    message.tags = [];
                  }
                  message.tags.push(reader.string());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.UpdateRequest.Tags) {
              return object;
            }
            const message = new $root.workflows.v1.UpdateRequest.Tags();
            if (object.tags) {
              if (!Array.isArray(object.tags)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Tags.tags: array type expected, but got ' +
                    typeof object.tags
                );
              }
              message.tags = new Array(object.tags.length);
              for (let i = 0; i < object.tags.length; ++i) {
                message.tags[i] = String(object.tags[i]);
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.tags = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.tags && message.tags.length) {
              object.tags = new Array(message.tags.length);
              for (let i = 0; i < message.tags.length; ++i) {
                object.tags[i] = message.tags[i];
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.UpdateRequest.Tags';
          }
        }

        Tags.prototype.tags = $util.emptyArray;

        return Tags;
      })();

      UpdateRequest.Version = (() => {
        class Version {
          constructor(properties) {
            this.triggerObjectTypeFilters = [];
            this.nodes = [];
            this.edges = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Version(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.sourceTriggerEvents != null &&
              Object.hasOwnProperty.call(message, 'sourceTriggerEvents')
            ) {
              sources$1.v2.CreateRequest.encode(
                message.sourceTriggerEvents,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.triggerObjectCategory != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectCategory')
            ) {
              writer.uint32(16).int32(message.triggerObjectCategory);
            }
            if (
              message.triggerObjectTypeFilters != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
            ) {
              for (const element of message.triggerObjectTypeFilters) {
                writer.uint32(26).string(element);
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              Object.hasOwnProperty.call(message, 'triggerExtraCelExpression')
            ) {
              writer.uint32(34).string(message.triggerExtraCelExpression);
            }
            if (
              message.profileLookupConfiguration != null &&
              Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
            ) {
              profiles$1.v1.LookupConfiguration.encode(
                message.profileLookupConfiguration,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.nodes != null &&
              Object.hasOwnProperty.call(message, 'nodes')
            ) {
              for (const element of message.nodes) {
                $root.workflows.v1.CreateWorkflowNodeRequest.encode(
                  element,
                  writer.uint32(50).fork()
                ).ldelim();
              }
            }
            if (
              message.edges != null &&
              Object.hasOwnProperty.call(message, 'edges')
            ) {
              for (const element of message.edges) {
                $root.workflows.v1.WorkflowEdge.encode(
                  element,
                  writer.uint32(58).fork()
                ).ldelim();
              }
            }
            if (
              message.uniquenessConflictResolution != null &&
              Object.hasOwnProperty.call(
                message,
                'uniquenessConflictResolution'
              )
            ) {
              $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
                message.uniquenessConflictResolution,
                writer.uint32(66).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Version();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sourceTriggerEvents =
                    sources$1.v2.CreateRequest.decode(reader, reader.uint32());
                  break;
                }
                case 2: {
                  message.triggerObjectCategory = reader.int32();
                  break;
                }
                case 3: {
                  if (
                    !message.triggerObjectTypeFilters ||
                    !message.triggerObjectTypeFilters.length
                  ) {
                    message.triggerObjectTypeFilters = [];
                  }
                  message.triggerObjectTypeFilters.push(reader.string());
                  break;
                }
                case 4: {
                  message.triggerExtraCelExpression = reader.string();
                  break;
                }
                case 5: {
                  message.profileLookupConfiguration =
                    profiles$1.v1.LookupConfiguration.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  if (!message.nodes || !message.nodes.length) {
                    message.nodes = [];
                  }
                  message.nodes.push(
                    $root.workflows.v1.CreateWorkflowNodeRequest.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 7: {
                  if (!message.edges || !message.edges.length) {
                    message.edges = [];
                  }
                  message.edges.push(
                    $root.workflows.v1.WorkflowEdge.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 8: {
                  message.uniquenessConflictResolution =
                    $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.UpdateRequest.Version) {
              return object;
            }
            const message = new $root.workflows.v1.UpdateRequest.Version();
            if (object.sourceTriggerEvents != null) {
              if (typeof object.sourceTriggerEvents !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.sourceTriggerEvents: object expected, but got ' +
                    typeof object.sourceTriggerEvents
                );
              }
              message.sourceTriggerEvents =
                sources$1.v2.CreateRequest.fromObject(
                  object.sourceTriggerEvents
                );
            }
            if (object.triggerObjectCategory != null) {
              switch (object.triggerObjectCategory) {
                case 'UNSPECIFIED_OBJECT_CATEGORY':
                case 0: {
                  message.triggerObjectCategory = 0;
                  break;
                }
                case 'FORM':
                case 1: {
                  message.triggerObjectCategory = 1;
                  break;
                }
                case 'APPOINTMENT':
                case 2: {
                  message.triggerObjectCategory = 2;
                  break;
                }
                case 'PATIENT':
                case 3: {
                  message.triggerObjectCategory = 3;
                  break;
                }
                case 'LAB_ORDER':
                case 4: {
                  message.triggerObjectCategory = 4;
                  break;
                }
                default: {
                  if (typeof object.triggerObjectCategory == 'number') {
                    message.triggerObjectCategory =
                      object.triggerObjectCategory;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.triggerObjectTypeFilters) {
              if (!Array.isArray(object.triggerObjectTypeFilters)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.triggerObjectTypeFilters: array type expected, but got ' +
                    typeof object.triggerObjectTypeFilters
                );
              }
              message.triggerObjectTypeFilters = new Array(
                object.triggerObjectTypeFilters.length
              );
              for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
                message.triggerObjectTypeFilters[i] = String(
                  object.triggerObjectTypeFilters[i]
                );
              }
            }
            if (object.triggerExtraCelExpression != null) {
              message.triggerExtraCelExpression = String(
                object.triggerExtraCelExpression
              );
            }
            if (object.profileLookupConfiguration != null) {
              if (typeof object.profileLookupConfiguration !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.profileLookupConfiguration: object expected, but got ' +
                    typeof object.profileLookupConfiguration
                );
              }
              message.profileLookupConfiguration =
                profiles$1.v1.LookupConfiguration.fromObject(
                  object.profileLookupConfiguration
                );
            }
            if (object.nodes) {
              if (!Array.isArray(object.nodes)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.nodes: array type expected, but got ' +
                    typeof object.nodes
                );
              }
              message.nodes = new Array(object.nodes.length);
              for (let i = 0; i < object.nodes.length; ++i) {
                if (typeof object.nodes[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateRequest.Version.nodes: object expected, but got ' +
                      typeof object.nodes[i]
                  );
                }
                message.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.fromObject(
                    object.nodes[i]
                  );
              }
            }
            if (object.edges) {
              if (!Array.isArray(object.edges)) {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.edges: array type expected, but got ' +
                    typeof object.edges
                );
              }
              message.edges = new Array(object.edges.length);
              for (let i = 0; i < object.edges.length; ++i) {
                if (typeof object.edges[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateRequest.Version.edges: object expected, but got ' +
                      typeof object.edges[i]
                  );
                }
                message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                  object.edges[i]
                );
              }
            }
            if (object.uniquenessConflictResolution != null) {
              if (typeof object.uniquenessConflictResolution !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateRequest.Version.uniquenessConflictResolution: object expected, but got ' +
                    typeof object.uniquenessConflictResolution
                );
              }
              message.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                  object.uniquenessConflictResolution
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.triggerObjectTypeFilters = [];
              object.nodes = [];
              object.edges = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.sourceTriggerEvents = null;
              object.triggerObjectCategory =
                options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
              object.profileLookupConfiguration = null;
            }
            let keys;
            if (
              message.sourceTriggerEvents != null &&
              message.hasOwnProperty('sourceTriggerEvents')
            ) {
              object.sourceTriggerEvents = sources$1.v2.CreateRequest.toObject(
                message.sourceTriggerEvents,
                options
              );
            }
            if (
              message.triggerObjectCategory != null &&
              message.hasOwnProperty('triggerObjectCategory')
            ) {
              object.triggerObjectCategory =
                options.enums === String
                  ? object_category$1.v1.ObjectCategory.Category[
                      message.triggerObjectCategory
                    ] === undefined
                    ? message.triggerObjectCategory
                    : object_category$1.v1.ObjectCategory.Category[
                        message.triggerObjectCategory
                      ]
                  : message.triggerObjectCategory;
            }
            if (
              message.triggerObjectTypeFilters &&
              message.triggerObjectTypeFilters.length
            ) {
              object.triggerObjectTypeFilters = new Array(
                message.triggerObjectTypeFilters.length
              );
              for (
                let i = 0;
                i < message.triggerObjectTypeFilters.length;
                ++i
              ) {
                object.triggerObjectTypeFilters[i] =
                  message.triggerObjectTypeFilters[i];
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              message.hasOwnProperty('triggerExtraCelExpression')
            ) {
              object.triggerExtraCelExpression =
                message.triggerExtraCelExpression;
            }
            if (
              message.profileLookupConfiguration != null &&
              message.hasOwnProperty('profileLookupConfiguration')
            ) {
              object.profileLookupConfiguration =
                profiles$1.v1.LookupConfiguration.toObject(
                  message.profileLookupConfiguration,
                  options
                );
            }
            if (message.nodes && message.nodes.length) {
              object.nodes = new Array(message.nodes.length);
              for (let i = 0; i < message.nodes.length; ++i) {
                object.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.toObject(
                    message.nodes[i],
                    options
                  );
              }
            }
            if (message.edges && message.edges.length) {
              object.edges = new Array(message.edges.length);
              for (let i = 0; i < message.edges.length; ++i) {
                object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                  message.edges[i],
                  options
                );
              }
            }
            if (
              message.uniquenessConflictResolution != null &&
              message.hasOwnProperty('uniquenessConflictResolution')
            ) {
              object.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                  message.uniquenessConflictResolution,
                  options
                );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.UpdateRequest.Version';
          }
        }

        Version.prototype.sourceTriggerEvents = null;
        Version.prototype.triggerObjectCategory = 0;
        Version.prototype.triggerObjectTypeFilters = $util.emptyArray;
        Version.prototype.triggerExtraCelExpression = null;
        Version.prototype.profileLookupConfiguration = null;
        Version.prototype.nodes = $util.emptyArray;
        Version.prototype.edges = $util.emptyArray;
        Version.prototype.uniquenessConflictResolution = null;

        return Version;
      })();

      return UpdateRequest;
    })();

    v1.UpdateResponse = (() => {
      class UpdateResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflow != null &&
            Object.hasOwnProperty.call(message, 'workflow')
          ) {
            $root.workflows.v1.Workflow.encode(
              message.workflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflow = $root.workflows.v1.Workflow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.UpdateResponse) {
            return object;
          }
          const message = new $root.workflows.v1.UpdateResponse();
          if (object.workflow != null) {
            if (typeof object.workflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateResponse.workflow: object expected, but got ' +
                  typeof object.workflow
              );
            }
            message.workflow = $root.workflows.v1.Workflow.fromObject(
              object.workflow
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflow = null;
          }
          let keys;
          if (message.workflow != null && message.hasOwnProperty('workflow')) {
            object.workflow = $root.workflows.v1.Workflow.toObject(
              message.workflow,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateResponse';
        }
      }

      UpdateResponse.prototype.workflow = null;

      return UpdateResponse;
    })();

    v1.UpdateWorkflowVersionRequest = (() => {
      class UpdateWorkflowVersionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(24).int32(message.status);
          }
          if (
            message.trigger != null &&
            Object.hasOwnProperty.call(message, 'trigger')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.encode(
              message.trigger,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.encode(
              message.nodes,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.encode(
              message.edges,
              writer.uint32(58).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              case 3: {
                message.status = reader.int32();
                break;
              }
              case 4: {
                message.trigger =
                  $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.nodes =
                  $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.edges =
                  $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.UpdateWorkflowVersionRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.UpdateWorkflowVersionRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.trigger != null) {
            if (typeof object.trigger !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.trigger: object expected, but got ' +
                  typeof object.trigger
              );
            }
            message.trigger =
              $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.fromObject(
                object.trigger
              );
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.nodes != null) {
            if (typeof object.nodes !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.nodes: object expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes =
              $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.fromObject(
                object.nodes
              );
          }
          if (object.edges != null) {
            if (typeof object.edges !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionRequest.edges: object expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges =
              $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.fromObject(
                object.edges
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (message.trigger != null && message.hasOwnProperty('trigger')) {
            object.trigger =
              $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.toObject(
                message.trigger,
                options
              );
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
          }
          if (message.nodes != null && message.hasOwnProperty('nodes')) {
            object.nodes =
              $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.toObject(
                message.nodes,
                options
              );
          }
          if (message.edges != null && message.hasOwnProperty('edges')) {
            object.edges =
              $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.toObject(
                message.edges,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionRequest';
        }
      }

      UpdateWorkflowVersionRequest.prototype.workflowId = '';
      UpdateWorkflowVersionRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      UpdateWorkflowVersionRequest.prototype.status = null;
      UpdateWorkflowVersionRequest.prototype.trigger = null;
      UpdateWorkflowVersionRequest.prototype.profileLookupConfiguration = null;
      UpdateWorkflowVersionRequest.prototype.nodes = null;
      UpdateWorkflowVersionRequest.prototype.edges = null;
      UpdateWorkflowVersionRequest.TriggerObjectTypeFilters = (() => {
        class TriggerObjectTypeFilters {
          constructor(properties) {
            this.triggerObjectTypeFilters = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new TriggerObjectTypeFilters(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.triggerObjectTypeFilters != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
            ) {
              for (const element of message.triggerObjectTypeFilters) {
                writer.uint32(10).string(element);
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new TriggerObjectTypeFilters();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (
                    !message.triggerObjectTypeFilters ||
                    !message.triggerObjectTypeFilters.length
                  ) {
                    message.triggerObjectTypeFilters = [];
                  }
                  message.triggerObjectTypeFilters.push(reader.string());
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest
                .TriggerObjectTypeFilters
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.TriggerObjectTypeFilters();
            if (object.triggerObjectTypeFilters) {
              if (!Array.isArray(object.triggerObjectTypeFilters)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.TriggerObjectTypeFilters.triggerObjectTypeFilters: array type expected, but got ' +
                    typeof object.triggerObjectTypeFilters
                );
              }
              message.triggerObjectTypeFilters = new Array(
                object.triggerObjectTypeFilters.length
              );
              for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
                message.triggerObjectTypeFilters[i] = String(
                  object.triggerObjectTypeFilters[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.triggerObjectTypeFilters = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.triggerObjectTypeFilters &&
              message.triggerObjectTypeFilters.length
            ) {
              object.triggerObjectTypeFilters = new Array(
                message.triggerObjectTypeFilters.length
              );
              for (
                let i = 0;
                i < message.triggerObjectTypeFilters.length;
                ++i
              ) {
                object.triggerObjectTypeFilters[i] =
                  message.triggerObjectTypeFilters[i];
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.TriggerObjectTypeFilters'
            );
          }
        }

        TriggerObjectTypeFilters.prototype.triggerObjectTypeFilters =
          $util.emptyArray;

        return TriggerObjectTypeFilters;
      })();

      UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests = (() => {
        class CreateWorkflowNodeRequests {
          constructor(properties) {
            this.nodes = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new CreateWorkflowNodeRequests(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.nodes != null &&
              Object.hasOwnProperty.call(message, 'nodes')
            ) {
              for (const element of message.nodes) {
                $root.workflows.v1.CreateWorkflowNodeRequest.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new CreateWorkflowNodeRequests();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.nodes || !message.nodes.length) {
                    message.nodes = [];
                  }
                  message.nodes.push(
                    $root.workflows.v1.CreateWorkflowNodeRequest.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest
                .CreateWorkflowNodeRequests
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests();
            if (object.nodes) {
              if (!Array.isArray(object.nodes)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.nodes: array type expected, but got ' +
                    typeof object.nodes
                );
              }
              message.nodes = new Array(object.nodes.length);
              for (let i = 0; i < object.nodes.length; ++i) {
                if (typeof object.nodes[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests.nodes: object expected, but got ' +
                      typeof object.nodes[i]
                  );
                }
                message.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.fromObject(
                    object.nodes[i]
                  );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.nodes = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.nodes && message.nodes.length) {
              object.nodes = new Array(message.nodes.length);
              for (let i = 0; i < message.nodes.length; ++i) {
                object.nodes[i] =
                  $root.workflows.v1.CreateWorkflowNodeRequest.toObject(
                    message.nodes[i],
                    options
                  );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.CreateWorkflowNodeRequests'
            );
          }
        }

        CreateWorkflowNodeRequests.prototype.nodes = $util.emptyArray;

        return CreateWorkflowNodeRequests;
      })();

      UpdateWorkflowVersionRequest.WorkflowEdges = (() => {
        class WorkflowEdges {
          constructor(properties) {
            this.edges = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WorkflowEdges(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.edges != null &&
              Object.hasOwnProperty.call(message, 'edges')
            ) {
              for (const element of message.edges) {
                $root.workflows.v1.WorkflowEdge.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WorkflowEdges();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.edges || !message.edges.length) {
                    message.edges = [];
                  }
                  message.edges.push(
                    $root.workflows.v1.WorkflowEdge.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges();
            if (object.edges) {
              if (!Array.isArray(object.edges)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.edges: array type expected, but got ' +
                    typeof object.edges
                );
              }
              message.edges = new Array(object.edges.length);
              for (let i = 0; i < object.edges.length; ++i) {
                if (typeof object.edges[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges.edges: object expected, but got ' +
                      typeof object.edges[i]
                  );
                }
                message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                  object.edges[i]
                );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.edges = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.edges && message.edges.length) {
              object.edges = new Array(message.edges.length);
              for (let i = 0; i < message.edges.length; ++i) {
                object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                  message.edges[i],
                  options
                );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.WorkflowEdges'
            );
          }
        }

        WorkflowEdges.prototype.edges = $util.emptyArray;

        return WorkflowEdges;
      })();

      UpdateWorkflowVersionRequest.UpdateTrigger = (() => {
        class UpdateTrigger {
          constructor(properties) {
            this.triggerObjectTypeFilters = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateTrigger(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.triggerObjectCategory != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectCategory')
            ) {
              writer.uint32(8).int32(message.triggerObjectCategory);
            }
            if (
              message.triggerObjectTypeFilters != null &&
              Object.hasOwnProperty.call(message, 'triggerObjectTypeFilters')
            ) {
              for (const element of message.triggerObjectTypeFilters) {
                writer.uint32(18).string(element);
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              Object.hasOwnProperty.call(message, 'triggerExtraCelExpression')
            ) {
              writer.uint32(26).string(message.triggerExtraCelExpression);
            }
            if (
              message.uniquenessConflictResolution != null &&
              Object.hasOwnProperty.call(
                message,
                'uniquenessConflictResolution'
              )
            ) {
              $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
                message.uniquenessConflictResolution,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.triggerEventTypes != null &&
              Object.hasOwnProperty.call(message, 'triggerEventTypes')
            ) {
              event_payload$1.v2.EventTypes.encode(
                message.triggerEventTypes,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.removeUniquenessConflictResolution != null &&
              Object.hasOwnProperty.call(
                message,
                'removeUniquenessConflictResolution'
              )
            ) {
              writer
                .uint32(48)
                .bool(message.removeUniquenessConflictResolution);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateTrigger();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.triggerObjectCategory = reader.int32();
                  break;
                }
                case 2: {
                  if (
                    !message.triggerObjectTypeFilters ||
                    !message.triggerObjectTypeFilters.length
                  ) {
                    message.triggerObjectTypeFilters = [];
                  }
                  message.triggerObjectTypeFilters.push(reader.string());
                  break;
                }
                case 3: {
                  message.triggerExtraCelExpression = reader.string();
                  break;
                }
                case 4: {
                  message.uniquenessConflictResolution =
                    $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.triggerEventTypes =
                    event_payload$1.v2.EventTypes.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 6: {
                  message.removeUniquenessConflictResolution = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger();
            if (object.triggerObjectCategory != null) {
              switch (object.triggerObjectCategory) {
                case 'UNSPECIFIED_OBJECT_CATEGORY':
                case 0: {
                  message.triggerObjectCategory = 0;
                  break;
                }
                case 'FORM':
                case 1: {
                  message.triggerObjectCategory = 1;
                  break;
                }
                case 'APPOINTMENT':
                case 2: {
                  message.triggerObjectCategory = 2;
                  break;
                }
                case 'PATIENT':
                case 3: {
                  message.triggerObjectCategory = 3;
                  break;
                }
                case 'LAB_ORDER':
                case 4: {
                  message.triggerObjectCategory = 4;
                  break;
                }
                default: {
                  if (typeof object.triggerObjectCategory == 'number') {
                    message.triggerObjectCategory =
                      object.triggerObjectCategory;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.triggerObjectTypeFilters) {
              if (!Array.isArray(object.triggerObjectTypeFilters)) {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.triggerObjectTypeFilters: array type expected, but got ' +
                    typeof object.triggerObjectTypeFilters
                );
              }
              message.triggerObjectTypeFilters = new Array(
                object.triggerObjectTypeFilters.length
              );
              for (let i = 0; i < object.triggerObjectTypeFilters.length; ++i) {
                message.triggerObjectTypeFilters[i] = String(
                  object.triggerObjectTypeFilters[i]
                );
              }
            }
            if (object.triggerExtraCelExpression != null) {
              message.triggerExtraCelExpression = String(
                object.triggerExtraCelExpression
              );
            }
            if (object.uniquenessConflictResolution != null) {
              if (typeof object.uniquenessConflictResolution !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.uniquenessConflictResolution: object expected, but got ' +
                    typeof object.uniquenessConflictResolution
                );
              }
              message.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                  object.uniquenessConflictResolution
                );
            }
            if (object.triggerEventTypes != null) {
              if (typeof object.triggerEventTypes !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger.triggerEventTypes: object expected, but got ' +
                    typeof object.triggerEventTypes
                );
              }
              message.triggerEventTypes =
                event_payload$1.v2.EventTypes.fromObject(
                  object.triggerEventTypes
                );
            }
            if (object.removeUniquenessConflictResolution != null) {
              message.removeUniquenessConflictResolution = Boolean(
                object.removeUniquenessConflictResolution
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.triggerObjectTypeFilters = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.triggerObjectCategory =
                options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            }
            let keys;
            if (
              message.triggerObjectCategory != null &&
              message.hasOwnProperty('triggerObjectCategory')
            ) {
              object.triggerObjectCategory =
                options.enums === String
                  ? object_category$1.v1.ObjectCategory.Category[
                      message.triggerObjectCategory
                    ] === undefined
                    ? message.triggerObjectCategory
                    : object_category$1.v1.ObjectCategory.Category[
                        message.triggerObjectCategory
                      ]
                  : message.triggerObjectCategory;
            }
            if (
              message.triggerObjectTypeFilters &&
              message.triggerObjectTypeFilters.length
            ) {
              object.triggerObjectTypeFilters = new Array(
                message.triggerObjectTypeFilters.length
              );
              for (
                let i = 0;
                i < message.triggerObjectTypeFilters.length;
                ++i
              ) {
                object.triggerObjectTypeFilters[i] =
                  message.triggerObjectTypeFilters[i];
              }
            }
            if (
              message.triggerExtraCelExpression != null &&
              message.hasOwnProperty('triggerExtraCelExpression')
            ) {
              object.triggerExtraCelExpression =
                message.triggerExtraCelExpression;
            }
            if (
              message.uniquenessConflictResolution != null &&
              message.hasOwnProperty('uniquenessConflictResolution')
            ) {
              object.uniquenessConflictResolution =
                $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                  message.uniquenessConflictResolution,
                  options
                );
            }
            if (
              message.triggerEventTypes != null &&
              message.hasOwnProperty('triggerEventTypes')
            ) {
              object.triggerEventTypes = event_payload$1.v2.EventTypes.toObject(
                message.triggerEventTypes,
                options
              );
            }
            if (
              message.removeUniquenessConflictResolution != null &&
              message.hasOwnProperty('removeUniquenessConflictResolution')
            ) {
              object.removeUniquenessConflictResolution =
                message.removeUniquenessConflictResolution;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionRequest.UpdateTrigger'
            );
          }
        }

        UpdateTrigger.prototype.triggerObjectCategory = 0;
        UpdateTrigger.prototype.triggerObjectTypeFilters = $util.emptyArray;
        UpdateTrigger.prototype.triggerExtraCelExpression = null;
        UpdateTrigger.prototype.uniquenessConflictResolution = null;
        UpdateTrigger.prototype.triggerEventTypes = null;
        UpdateTrigger.prototype.removeUniquenessConflictResolution = null;

        return UpdateTrigger;
      })();

      return UpdateWorkflowVersionRequest;
    })();

    v1.UpdateWorkflowVersionNodeRequest = (() => {
      class UpdateWorkflowVersionNodeRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionNodeRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          if (
            message.nodeId != null &&
            Object.hasOwnProperty.call(message, 'nodeId')
          ) {
            writer.uint32(26).string(message.nodeId);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(34).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(42).string(message.description);
          }
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.encode(
              message.type,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionNodeRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              case 3: {
                message.nodeId = reader.string();
                break;
              }
              case 4: {
                message.name = reader.string();
                break;
              }
              case 5: {
                message.description = reader.string();
                break;
              }
              case 6: {
                message.type =
                  $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.UpdateWorkflowVersionNodeRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.UpdateWorkflowVersionNodeRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.type != null) {
            if (typeof object.type !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionNodeRequest.type: object expected, but got ' +
                  typeof object.type
              );
            }
            message.type =
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.fromObject(
                object.type
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.nodeId = '';
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.nodeId != null && message.hasOwnProperty('nodeId')) {
            object.nodeId = message.nodeId;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type =
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.toObject(
                message.type,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionNodeRequest'
          );
        }
      }

      UpdateWorkflowVersionNodeRequest.prototype.workflowId = '';
      UpdateWorkflowVersionNodeRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      UpdateWorkflowVersionNodeRequest.prototype.nodeId = '';
      UpdateWorkflowVersionNodeRequest.prototype.name = null;
      UpdateWorkflowVersionNodeRequest.prototype.description = null;
      UpdateWorkflowVersionNodeRequest.prototype.type = null;
      UpdateWorkflowVersionNodeRequest.UpdateNodeType = (() => {
        class UpdateNodeType {
          get type() {
            for (const key of [
              'destinationAction',
              'filter',
              'pause',
              'fetchAction',
              'restart',
            ]) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of [
              'destinationAction',
              'filter',
              'pause',
              'fetchAction',
              'restart',
            ]) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new UpdateNodeType(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.destinationAction != null &&
              Object.hasOwnProperty.call(message, 'destinationAction')
            ) {
              $root.workflows.v1.WorkflowDestinationAction.encode(
                message.destinationAction,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.filter != null &&
              Object.hasOwnProperty.call(message, 'filter')
            ) {
              $root.workflows.v1.CreateWorkflowFilterRequest.encode(
                message.filter,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.pause != null &&
              Object.hasOwnProperty.call(message, 'pause')
            ) {
              $root.workflows.v1.WorkflowPause.encode(
                message.pause,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.fetchAction != null &&
              Object.hasOwnProperty.call(message, 'fetchAction')
            ) {
              $root.workflows.v1.WorkflowFetchAction.encode(
                message.fetchAction,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.restart != null &&
              Object.hasOwnProperty.call(message, 'restart')
            ) {
              $root.workflows.v1.WorkflowRestart.encode(
                message.restart,
                writer.uint32(42).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new UpdateNodeType();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.destinationAction =
                    $root.workflows.v1.WorkflowDestinationAction.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.filter =
                    $root.workflows.v1.CreateWorkflowFilterRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 3: {
                  message.pause = $root.workflows.v1.WorkflowPause.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 4: {
                  message.fetchAction =
                    $root.workflows.v1.WorkflowFetchAction.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.restart = $root.workflows.v1.WorkflowRestart.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType();
            if (object.destinationAction != null) {
              if (typeof object.destinationAction !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.destinationAction: object expected, but got ' +
                    typeof object.destinationAction
                );
              }
              message.destinationAction =
                $root.workflows.v1.WorkflowDestinationAction.fromObject(
                  object.destinationAction
                );
            }
            if (object.filter != null) {
              if (typeof object.filter !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.filter: object expected, but got ' +
                    typeof object.filter
                );
              }
              message.filter =
                $root.workflows.v1.CreateWorkflowFilterRequest.fromObject(
                  object.filter
                );
            }
            if (object.pause != null) {
              if (typeof object.pause !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.pause: object expected, but got ' +
                    typeof object.pause
                );
              }
              message.pause = $root.workflows.v1.WorkflowPause.fromObject(
                object.pause
              );
            }
            if (object.fetchAction != null) {
              if (typeof object.fetchAction !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.fetchAction: object expected, but got ' +
                    typeof object.fetchAction
                );
              }
              message.fetchAction =
                $root.workflows.v1.WorkflowFetchAction.fromObject(
                  object.fetchAction
                );
            }
            if (object.restart != null) {
              if (typeof object.restart !== 'object') {
                throw new TypeError(
                  '.workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType.restart: object expected, but got ' +
                    typeof object.restart
                );
              }
              message.restart = $root.workflows.v1.WorkflowRestart.fromObject(
                object.restart
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.destinationAction != null &&
              message.hasOwnProperty('destinationAction')
            ) {
              object.destinationAction =
                $root.workflows.v1.WorkflowDestinationAction.toObject(
                  message.destinationAction,
                  options
                );
              if (options.oneofs) {
                object.type = 'destinationAction';
              }
            }
            if (message.filter != null && message.hasOwnProperty('filter')) {
              object.filter =
                $root.workflows.v1.CreateWorkflowFilterRequest.toObject(
                  message.filter,
                  options
                );
              if (options.oneofs) {
                object.type = 'filter';
              }
            }
            if (message.pause != null && message.hasOwnProperty('pause')) {
              object.pause = $root.workflows.v1.WorkflowPause.toObject(
                message.pause,
                options
              );
              if (options.oneofs) {
                object.type = 'pause';
              }
            }
            if (
              message.fetchAction != null &&
              message.hasOwnProperty('fetchAction')
            ) {
              object.fetchAction =
                $root.workflows.v1.WorkflowFetchAction.toObject(
                  message.fetchAction,
                  options
                );
              if (options.oneofs) {
                object.type = 'fetchAction';
              }
            }
            if (message.restart != null && message.hasOwnProperty('restart')) {
              object.restart = $root.workflows.v1.WorkflowRestart.toObject(
                message.restart,
                options
              );
              if (options.oneofs) {
                object.type = 'restart';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.UpdateWorkflowVersionNodeRequest.UpdateNodeType'
            );
          }
        }

        UpdateNodeType.prototype.destinationAction = null;
        UpdateNodeType.prototype.filter = null;
        UpdateNodeType.prototype.pause = null;
        UpdateNodeType.prototype.fetchAction = null;
        UpdateNodeType.prototype.restart = null;

        return UpdateNodeType;
      })();

      return UpdateWorkflowVersionNodeRequest;
    })();

    v1.UpdateWorkflowVersionNodeResponse = (() => {
      class UpdateWorkflowVersionNodeResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionNodeResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.node != null &&
            Object.hasOwnProperty.call(message, 'node')
          ) {
            $root.workflows.v1.WorkflowNode.encode(
              message.node,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionNodeResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.node = $root.workflows.v1.WorkflowNode.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.UpdateWorkflowVersionNodeResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.UpdateWorkflowVersionNodeResponse();
          if (object.node != null) {
            if (typeof object.node !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionNodeResponse.node: object expected, but got ' +
                  typeof object.node
              );
            }
            message.node = $root.workflows.v1.WorkflowNode.fromObject(
              object.node
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.node = null;
          }
          let keys;
          if (message.node != null && message.hasOwnProperty('node')) {
            object.node = $root.workflows.v1.WorkflowNode.toObject(
              message.node,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionNodeResponse'
          );
        }
      }

      UpdateWorkflowVersionNodeResponse.prototype.node = null;

      return UpdateWorkflowVersionNodeResponse;
    })();

    v1.GetWorkflowVersionRequest = (() => {
      class GetWorkflowVersionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowVersionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(16).int64(message.version);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowVersionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.version = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetWorkflowVersionRequest) {
            return object;
          }
          const message = new $root.workflows.v1.GetWorkflowVersionRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetWorkflowVersionRequest';
        }
      }

      GetWorkflowVersionRequest.prototype.workflowId = '';
      GetWorkflowVersionRequest.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      return GetWorkflowVersionRequest;
    })();

    v1.UpdateWorkflowVersionResponse = (() => {
      class UpdateWorkflowVersionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new UpdateWorkflowVersionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            $root.workflows.v1.Version.encode(
              message.version,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new UpdateWorkflowVersionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.version = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.UpdateWorkflowVersionResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.UpdateWorkflowVersionResponse();
          if (object.version != null) {
            if (typeof object.version !== 'object') {
              throw new TypeError(
                '.workflows.v1.UpdateWorkflowVersionResponse.version: object expected, but got ' +
                  typeof object.version
              );
            }
            message.version = $root.workflows.v1.Version.fromObject(
              object.version
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.version = null;
          }
          let keys;
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version = $root.workflows.v1.Version.toObject(
              message.version,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.UpdateWorkflowVersionResponse';
        }
      }

      UpdateWorkflowVersionResponse.prototype.version = null;

      return UpdateWorkflowVersionResponse;
    })();

    v1.ListRequest = (() => {
      class ListRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.workflows.v1.ListRequest.Filter.encode(
              message.filter,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.returnLightweight != null &&
            Object.hasOwnProperty.call(message, 'returnLightweight')
          ) {
            writer.uint32(16).bool(message.returnLightweight);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.filter = $root.workflows.v1.ListRequest.Filter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.returnLightweight = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListRequest();
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListRequest.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter = $root.workflows.v1.ListRequest.Filter.fromObject(
              object.filter
            );
          }
          if (object.returnLightweight != null) {
            message.returnLightweight = Boolean(object.returnLightweight);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.filter = null;
          }
          let keys;
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.workflows.v1.ListRequest.Filter.toObject(
              message.filter,
              options
            );
          }
          if (
            message.returnLightweight != null &&
            message.hasOwnProperty('returnLightweight')
          ) {
            object.returnLightweight = message.returnLightweight;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListRequest';
        }
      }

      ListRequest.prototype.filter = null;
      ListRequest.prototype.returnLightweight = null;
      ListRequest.Filter = (() => {
        class Filter {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Filter(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.status != null &&
              Object.hasOwnProperty.call(message, 'status')
            ) {
              writer.uint32(8).int32(message.status);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Filter();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.status = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.ListRequest.Filter) {
              return object;
            }
            const message = new $root.workflows.v1.ListRequest.Filter();
            if (object.status != null) {
              switch (object.status) {
                case 'UNSPECIFIED_WORKFLOW_STATUS':
                case 0: {
                  message.status = 0;
                  break;
                }
                case 'DRAFT':
                case 1: {
                  message.status = 1;
                  break;
                }
                case 'ACTIVE':
                case 2: {
                  message.status = 2;
                  break;
                }
                case 'INACTIVE':
                case 3: {
                  message.status = 3;
                  break;
                }
                default: {
                  if (typeof object.status == 'number') {
                    message.status = object.status;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (message.status != null && message.hasOwnProperty('status')) {
              object.status =
                options.enums === String
                  ? $root.workflows.v1.Status[message.status] === undefined
                    ? message.status
                    : $root.workflows.v1.Status[message.status]
                  : message.status;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.ListRequest.Filter';
          }
        }

        Filter.prototype.status = null;

        return Filter;
      })();

      return ListRequest;
    })();

    v1.ListResponse = (() => {
      class ListResponse {
        constructor(properties) {
          this.workflows = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflows != null &&
            Object.hasOwnProperty.call(message, 'workflows')
          ) {
            for (const element of message.workflows) {
              $root.workflows.v1.Workflow.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.workflows || !message.workflows.length) {
                  message.workflows = [];
                }
                message.workflows.push(
                  $root.workflows.v1.Workflow.decode(reader, reader.uint32())
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListResponse();
          if (object.workflows) {
            if (!Array.isArray(object.workflows)) {
              throw new TypeError(
                '.workflows.v1.ListResponse.workflows: array type expected, but got ' +
                  typeof object.workflows
              );
            }
            message.workflows = new Array(object.workflows.length);
            for (let i = 0; i < object.workflows.length; ++i) {
              if (typeof object.workflows[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListResponse.workflows: object expected, but got ' +
                    typeof object.workflows[i]
                );
              }
              message.workflows[i] = $root.workflows.v1.Workflow.fromObject(
                object.workflows[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflows = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.workflows && message.workflows.length) {
            object.workflows = new Array(message.workflows.length);
            for (let i = 0; i < message.workflows.length; ++i) {
              object.workflows[i] = $root.workflows.v1.Workflow.toObject(
                message.workflows[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListResponse';
        }
      }

      ListResponse.prototype.workflows = $util.emptyArray;

      return ListResponse;
    })();

    v1.ListWorkflowVersionsRequest = (() => {
      class ListWorkflowVersionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowVersionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(32).uint64(message.limit);
          }
          if (
            message.returnLightweight != null &&
            Object.hasOwnProperty.call(message, 'returnLightweight')
          ) {
            writer.uint32(40).bool(message.returnLightweight);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowVersionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.limit = reader.uint64();
                break;
              }
              case 5: {
                message.returnLightweight = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListWorkflowVersionsRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListWorkflowVersionsRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.returnLightweight != null) {
            message.returnLightweight = Boolean(object.returnLightweight);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          if (
            message.returnLightweight != null &&
            message.hasOwnProperty('returnLightweight')
          ) {
            object.returnLightweight = message.returnLightweight;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListWorkflowVersionsRequest';
        }
      }

      ListWorkflowVersionsRequest.prototype.workflowId = '';
      ListWorkflowVersionsRequest.prototype.earliestTimestampInclusive = null;
      ListWorkflowVersionsRequest.prototype.latestTimestampExclusive = null;
      ListWorkflowVersionsRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      ListWorkflowVersionsRequest.prototype.returnLightweight = null;

      return ListWorkflowVersionsRequest;
    })();

    v1.ListWorkflowVersionsResponse = (() => {
      class ListWorkflowVersionsResponse {
        constructor(properties) {
          this.versions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowVersionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.versions != null &&
            Object.hasOwnProperty.call(message, 'versions')
          ) {
            for (const element of message.versions) {
              $root.workflows.v1.Version.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowVersionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.versions || !message.versions.length) {
                  message.versions = [];
                }
                message.versions.push(
                  $root.workflows.v1.Version.decode(reader, reader.uint32())
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListWorkflowVersionsResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListWorkflowVersionsResponse();
          if (object.versions) {
            if (!Array.isArray(object.versions)) {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsResponse.versions: array type expected, but got ' +
                  typeof object.versions
              );
            }
            message.versions = new Array(object.versions.length);
            for (let i = 0; i < object.versions.length; ++i) {
              if (typeof object.versions[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListWorkflowVersionsResponse.versions: object expected, but got ' +
                    typeof object.versions[i]
                );
              }
              message.versions[i] = $root.workflows.v1.Version.fromObject(
                object.versions[i]
              );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowVersionsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.versions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.versions && message.versions.length) {
            object.versions = new Array(message.versions.length);
            for (let i = 0; i < message.versions.length; ++i) {
              object.versions[i] = $root.workflows.v1.Version.toObject(
                message.versions[i],
                options
              );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListWorkflowVersionsResponse';
        }
      }

      ListWorkflowVersionsResponse.prototype.versions = $util.emptyArray;
      ListWorkflowVersionsResponse.prototype.earliestFetchedTimestamp = null;

      return ListWorkflowVersionsResponse;
    })();

    v1.GetWorkflowVersionResponse = (() => {
      class GetWorkflowVersionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetWorkflowVersionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            $root.workflows.v1.Version.encode(
              message.version,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetWorkflowVersionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.version = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetWorkflowVersionResponse) {
            return object;
          }
          const message = new $root.workflows.v1.GetWorkflowVersionResponse();
          if (object.version != null) {
            if (typeof object.version !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetWorkflowVersionResponse.version: object expected, but got ' +
                  typeof object.version
              );
            }
            message.version = $root.workflows.v1.Version.fromObject(
              object.version
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.version = null;
          }
          let keys;
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version = $root.workflows.v1.Version.toObject(
              message.version,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetWorkflowVersionResponse';
        }
      }

      GetWorkflowVersionResponse.prototype.version = null;

      return GetWorkflowVersionResponse;
    })();

    v1.GetValidTriggerEventsRequest = (() => {
      class GetValidTriggerEventsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetValidTriggerEventsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetValidTriggerEventsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.GetValidTriggerEventsRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.GetValidTriggerEventsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetValidTriggerEventsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetValidTriggerEventsRequest';
        }
      }

      GetValidTriggerEventsRequest.prototype.stub = null;

      return GetValidTriggerEventsRequest;
    })();

    v1.GetValidTriggerEventsResponse = (() => {
      class GetValidTriggerEventsResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetValidTriggerEventsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.validTriggerEvents != null &&
            Object.hasOwnProperty.call(message, 'validTriggerEvents')
          ) {
            $root.workflows.v1.ValidTriggerEvents.encode(
              message.validTriggerEvents,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetValidTriggerEventsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.validTriggerEvents =
                  $root.workflows.v1.ValidTriggerEvents.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.GetValidTriggerEventsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetValidTriggerEventsResponse();
          if (object.validTriggerEvents != null) {
            if (typeof object.validTriggerEvents !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetValidTriggerEventsResponse.validTriggerEvents: object expected, but got ' +
                  typeof object.validTriggerEvents
              );
            }
            message.validTriggerEvents =
              $root.workflows.v1.ValidTriggerEvents.fromObject(
                object.validTriggerEvents
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.validTriggerEvents = null;
          }
          let keys;
          if (
            message.validTriggerEvents != null &&
            message.hasOwnProperty('validTriggerEvents')
          ) {
            object.validTriggerEvents =
              $root.workflows.v1.ValidTriggerEvents.toObject(
                message.validTriggerEvents,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetValidTriggerEventsResponse';
        }
      }

      GetValidTriggerEventsResponse.prototype.validTriggerEvents = null;

      return GetValidTriggerEventsResponse;
    })();

    v1.RetryEventSnapshotRequest = (() => {
      class RetryEventSnapshotRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryEventSnapshotRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(18).string(message.workflowId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryEventSnapshotRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.workflowId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RetryEventSnapshotRequest) {
            return object;
          }
          const message = new $root.workflows.v1.RetryEventSnapshotRequest();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryEventSnapshotRequest';
        }
      }

      RetryEventSnapshotRequest.prototype.id = '';
      RetryEventSnapshotRequest.prototype.workflowId = null;

      return RetryEventSnapshotRequest;
    })();

    v1.RetryEventSnapshotResponse = (() => {
      class RetryEventSnapshotResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryEventSnapshotResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryEventSnapshotResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RetryEventSnapshotResponse) {
            return object;
          }
          const message = new $root.workflows.v1.RetryEventSnapshotResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryEventSnapshotResponse';
        }
      }

      return RetryEventSnapshotResponse;
    })();

    v1.ListGroupedErrorDataRequest = (() => {
      class ListGroupedErrorDataRequest {
        constructor(properties) {
          this.errorClasses = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListGroupedErrorDataRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errorClasses != null &&
            Object.hasOwnProperty.call(message, 'errorClasses')
          ) {
            writer.uint32(10).fork();
            for (const element of message.errorClasses) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(32).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListGroupedErrorDataRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.errorClasses || !message.errorClasses.length) {
                  message.errorClasses = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.errorClasses.push(reader.int32());
                } else {
                  message.errorClasses.push(reader.int32());
                }
                break;
              }
              case 2: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListGroupedErrorDataRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListGroupedErrorDataRequest();
          if (object.errorClasses) {
            if (!Array.isArray(object.errorClasses)) {
              throw new TypeError(
                '.workflows.v1.ListGroupedErrorDataRequest.errorClasses: array type expected, but got ' +
                  typeof object.errorClasses
              );
            }
            message.errorClasses = new Array(object.errorClasses.length);
            for (let i = 0; i < object.errorClasses.length; ++i) {
              switch (object.errorClasses[i]) {
                case 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS':
                case 0: {
                  message.errorClasses[i] = 0;
                  break;
                }
                case 'ERROR':
                case 1: {
                  message.errorClasses[i] = 1;
                  break;
                }
                case 'WARNING':
                case 2: {
                  message.errorClasses[i] = 2;
                  break;
                }
                case 'RETRY':
                case 3: {
                  message.errorClasses[i] = 3;
                  break;
                }
                default: {
                  if (typeof object.errorClasses[i] == 'number') {
                    message.errorClasses[i] = object.errorClasses[i];
                    break;
                  }
                  message.errorClasses[i] = 0;
                  break;
                }
              }
            }
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListGroupedErrorDataRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListGroupedErrorDataRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errorClasses = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (message.errorClasses && message.errorClasses.length) {
            object.errorClasses = new Array(message.errorClasses.length);
            for (let i = 0; i < message.errorClasses.length; ++i) {
              object.errorClasses[i] =
                options.enums === String
                  ? workflow_evaluation_results$1.v1.ErrorClass[
                      message.errorClasses[i]
                    ] === undefined
                    ? message.errorClasses[i]
                    : workflow_evaluation_results$1.v1.ErrorClass[
                        message.errorClasses[i]
                      ]
                  : message.errorClasses[i];
            }
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListGroupedErrorDataRequest';
        }
      }

      ListGroupedErrorDataRequest.prototype.errorClasses = $util.emptyArray;
      ListGroupedErrorDataRequest.prototype.earliestTimestampInclusive = null;
      ListGroupedErrorDataRequest.prototype.latestTimestampExclusive = null;
      ListGroupedErrorDataRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListGroupedErrorDataRequest;
    })();

    v1.ListGroupedErrorDataResponse = (() => {
      class ListGroupedErrorDataResponse {
        constructor(properties) {
          this.groupedErrors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListGroupedErrorDataResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.groupedErrors != null &&
            Object.hasOwnProperty.call(message, 'groupedErrors')
          ) {
            for (const element of message.groupedErrors) {
              $root.workflows.v1.GroupedErrorData.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListGroupedErrorDataResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.groupedErrors || !message.groupedErrors.length) {
                  message.groupedErrors = [];
                }
                message.groupedErrors.push(
                  $root.workflows.v1.GroupedErrorData.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListGroupedErrorDataResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListGroupedErrorDataResponse();
          if (object.groupedErrors) {
            if (!Array.isArray(object.groupedErrors)) {
              throw new TypeError(
                '.workflows.v1.ListGroupedErrorDataResponse.groupedErrors: array type expected, but got ' +
                  typeof object.groupedErrors
              );
            }
            message.groupedErrors = new Array(object.groupedErrors.length);
            for (let i = 0; i < object.groupedErrors.length; ++i) {
              if (typeof object.groupedErrors[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListGroupedErrorDataResponse.groupedErrors: object expected, but got ' +
                    typeof object.groupedErrors[i]
                );
              }
              message.groupedErrors[i] =
                $root.workflows.v1.GroupedErrorData.fromObject(
                  object.groupedErrors[i]
                );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListGroupedErrorDataResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.groupedErrors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.groupedErrors && message.groupedErrors.length) {
            object.groupedErrors = new Array(message.groupedErrors.length);
            for (let i = 0; i < message.groupedErrors.length; ++i) {
              object.groupedErrors[i] =
                $root.workflows.v1.GroupedErrorData.toObject(
                  message.groupedErrors[i],
                  options
                );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListGroupedErrorDataResponse';
        }
      }

      ListGroupedErrorDataResponse.prototype.groupedErrors = $util.emptyArray;
      ListGroupedErrorDataResponse.prototype.earliestFetchedTimestamp = null;

      return ListGroupedErrorDataResponse;
    })();

    v1.ListWorkflowGroupedWorkflowErrorsRequest = (() => {
      class ListWorkflowGroupedWorkflowErrorsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowGroupedWorkflowErrorsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.affectedWorkflow != null &&
            Object.hasOwnProperty.call(message, 'affectedWorkflow')
          ) {
            $root.workflows.v1.WorkflowIdSummary.encode(
              message.affectedWorkflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.errorString != null &&
            Object.hasOwnProperty.call(message, 'errorString')
          ) {
            writer.uint32(18).string(message.errorString);
          }
          if (
            message.errorClass != null &&
            Object.hasOwnProperty.call(message, 'errorClass')
          ) {
            writer.uint32(24).int32(message.errorClass);
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(48).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowGroupedWorkflowErrorsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.affectedWorkflow =
                  $root.workflows.v1.WorkflowIdSummary.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.errorString = reader.string();
                break;
              }
              case 3: {
                message.errorClass = reader.int32();
                break;
              }
              case 4: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 5: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest();
          if (object.affectedWorkflow != null) {
            if (typeof object.affectedWorkflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest.affectedWorkflow: object expected, but got ' +
                  typeof object.affectedWorkflow
              );
            }
            message.affectedWorkflow =
              $root.workflows.v1.WorkflowIdSummary.fromObject(
                object.affectedWorkflow
              );
          }
          if (object.errorString != null) {
            message.errorString = String(object.errorString);
          }
          if (object.errorClass != null) {
            switch (object.errorClass) {
              case 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS':
              case 0: {
                message.errorClass = 0;
                break;
              }
              case 'ERROR':
              case 1: {
                message.errorClass = 1;
                break;
              }
              case 'WARNING':
              case 2: {
                message.errorClass = 2;
                break;
              }
              case 'RETRY':
              case 3: {
                message.errorClass = 3;
                break;
              }
              default: {
                if (typeof object.errorClass == 'number') {
                  message.errorClass = object.errorClass;
                  break;
                }
                break;
              }
            }
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.affectedWorkflow = null;
            object.errorString = '';
            object.errorClass =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS'
                : 0;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.affectedWorkflow != null &&
            message.hasOwnProperty('affectedWorkflow')
          ) {
            object.affectedWorkflow =
              $root.workflows.v1.WorkflowIdSummary.toObject(
                message.affectedWorkflow,
                options
              );
          }
          if (
            message.errorString != null &&
            message.hasOwnProperty('errorString')
          ) {
            object.errorString = message.errorString;
          }
          if (
            message.errorClass != null &&
            message.hasOwnProperty('errorClass')
          ) {
            object.errorClass =
              options.enums === String
                ? workflow_evaluation_results$1.v1.ErrorClass[
                    message.errorClass
                  ] === undefined
                  ? message.errorClass
                  : workflow_evaluation_results$1.v1.ErrorClass[
                      message.errorClass
                    ]
                : message.errorClass;
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest'
          );
        }
      }

      ListWorkflowGroupedWorkflowErrorsRequest.prototype.affectedWorkflow =
        null;
      ListWorkflowGroupedWorkflowErrorsRequest.prototype.errorString = '';
      ListWorkflowGroupedWorkflowErrorsRequest.prototype.errorClass = 0;
      ListWorkflowGroupedWorkflowErrorsRequest.prototype.earliestTimestampInclusive =
        null;
      ListWorkflowGroupedWorkflowErrorsRequest.prototype.latestTimestampExclusive =
        null;
      ListWorkflowGroupedWorkflowErrorsRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListWorkflowGroupedWorkflowErrorsRequest;
    })();

    v1.ListWorkflowGroupedWorkflowErrorsResponse = (() => {
      class ListWorkflowGroupedWorkflowErrorsResponse {
        constructor(properties) {
          this.errors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowGroupedWorkflowErrorsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errors != null &&
            Object.hasOwnProperty.call(message, 'errors')
          ) {
            for (const element of message.errors) {
              $root.workflows.v1.WorkflowErrorEvent.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowGroupedWorkflowErrorsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.errors || !message.errors.length) {
                  message.errors = [];
                }
                message.errors.push(
                  $root.workflows.v1.WorkflowErrorEvent.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse();
          if (object.errors) {
            if (!Array.isArray(object.errors)) {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse.errors: array type expected, but got ' +
                  typeof object.errors
              );
            }
            message.errors = new Array(object.errors.length);
            for (let i = 0; i < object.errors.length; ++i) {
              if (typeof object.errors[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse.errors: object expected, but got ' +
                    typeof object.errors[i]
                );
              }
              message.errors[i] =
                $root.workflows.v1.WorkflowErrorEvent.fromObject(
                  object.errors[i]
                );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.errors && message.errors.length) {
            object.errors = new Array(message.errors.length);
            for (let i = 0; i < message.errors.length; ++i) {
              object.errors[i] = $root.workflows.v1.WorkflowErrorEvent.toObject(
                message.errors[i],
                options
              );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse'
          );
        }
      }

      ListWorkflowGroupedWorkflowErrorsResponse.prototype.errors =
        $util.emptyArray;
      ListWorkflowGroupedWorkflowErrorsResponse.prototype.earliestFetchedTimestamp =
        null;

      return ListWorkflowGroupedWorkflowErrorsResponse;
    })();

    v1.ListWorkflowGroupedDestinationActionErrorsRequest = (() => {
      class ListWorkflowGroupedDestinationActionErrorsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowGroupedDestinationActionErrorsRequest(
            properties
          );
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.affectedWorkflow != null &&
            Object.hasOwnProperty.call(message, 'affectedWorkflow')
          ) {
            $root.workflows.v1.WorkflowIdSummary.encode(
              message.affectedWorkflow,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.destinationActionErrorApplication != null &&
            Object.hasOwnProperty.call(
              message,
              'destinationActionErrorApplication'
            )
          ) {
            writer.uint32(16).int32(message.destinationActionErrorApplication);
          }
          if (
            message.destinationActionName != null &&
            Object.hasOwnProperty.call(message, 'destinationActionName')
          ) {
            writer.uint32(26).string(message.destinationActionName);
          }
          if (
            message.errorString != null &&
            Object.hasOwnProperty.call(message, 'errorString')
          ) {
            writer.uint32(34).string(message.errorString);
          }
          if (
            message.errorClass != null &&
            Object.hasOwnProperty.call(message, 'errorClass')
          ) {
            writer.uint32(40).int32(message.errorClass);
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(64).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowGroupedDestinationActionErrorsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.affectedWorkflow =
                  $root.workflows.v1.WorkflowIdSummary.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.destinationActionErrorApplication = reader.int32();
                break;
              }
              case 3: {
                message.destinationActionName = reader.string();
                break;
              }
              case 4: {
                message.errorString = reader.string();
                break;
              }
              case 5: {
                message.errorClass = reader.int32();
                break;
              }
              case 6: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 7: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.ListWorkflowGroupedDestinationActionErrorsRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListWorkflowGroupedDestinationActionErrorsRequest();
          if (object.affectedWorkflow != null) {
            if (typeof object.affectedWorkflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedDestinationActionErrorsRequest.affectedWorkflow: object expected, but got ' +
                  typeof object.affectedWorkflow
              );
            }
            message.affectedWorkflow =
              $root.workflows.v1.WorkflowIdSummary.fromObject(
                object.affectedWorkflow
              );
          }
          if (object.destinationActionErrorApplication != null) {
            switch (object.destinationActionErrorApplication) {
              case 'UNSPECIFIED_DESTINATION_APPLICATION':
              case 0: {
                message.destinationActionErrorApplication = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 1: {
                message.destinationActionErrorApplication = 1;
                break;
              }
              case 'CUSTOMER_IO':
              case 2: {
                message.destinationActionErrorApplication = 2;
                break;
              }
              case 'FACEBOOK':
              case 3: {
                message.destinationActionErrorApplication = 3;
                break;
              }
              case 'GOOGLE':
              case 4: {
                message.destinationActionErrorApplication = 4;
                break;
              }
              case 'HEALTHIE':
              case 5: {
                message.destinationActionErrorApplication = 5;
                break;
              }
              case 'MORF':
              case 6: {
                message.destinationActionErrorApplication = 6;
                break;
              }
              case 'INTAKEQ':
              case 7: {
                message.destinationActionErrorApplication = 7;
                break;
              }
              case 'SEGMENT':
              case 8: {
                message.destinationActionErrorApplication = 8;
                break;
              }
              case 'SPRUCE':
              case 9: {
                message.destinationActionErrorApplication = 9;
                break;
              }
              case 'SLACK':
              case 10: {
                message.destinationActionErrorApplication = 10;
                break;
              }
              case 'FRESHDESK':
              case 11: {
                message.destinationActionErrorApplication = 11;
                break;
              }
              case 'HUBSPOT':
              case 12: {
                message.destinationActionErrorApplication = 12;
                break;
              }
              case 'INTERCOM':
              case 13: {
                message.destinationActionErrorApplication = 13;
                break;
              }
              default: {
                if (
                  typeof object.destinationActionErrorApplication == 'number'
                ) {
                  message.destinationActionErrorApplication =
                    object.destinationActionErrorApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationActionName != null) {
            message.destinationActionName = String(
              object.destinationActionName
            );
          }
          if (object.errorString != null) {
            message.errorString = String(object.errorString);
          }
          if (object.errorClass != null) {
            switch (object.errorClass) {
              case 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS':
              case 0: {
                message.errorClass = 0;
                break;
              }
              case 'ERROR':
              case 1: {
                message.errorClass = 1;
                break;
              }
              case 'WARNING':
              case 2: {
                message.errorClass = 2;
                break;
              }
              case 'RETRY':
              case 3: {
                message.errorClass = 3;
                break;
              }
              default: {
                if (typeof object.errorClass == 'number') {
                  message.errorClass = object.errorClass;
                  break;
                }
                break;
              }
            }
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedDestinationActionErrorsRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedDestinationActionErrorsRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.affectedWorkflow = null;
            object.destinationActionErrorApplication =
              options.enums === String
                ? 'UNSPECIFIED_DESTINATION_APPLICATION'
                : 0;
            object.destinationActionName = '';
            object.errorString = '';
            object.errorClass =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS'
                : 0;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.affectedWorkflow != null &&
            message.hasOwnProperty('affectedWorkflow')
          ) {
            object.affectedWorkflow =
              $root.workflows.v1.WorkflowIdSummary.toObject(
                message.affectedWorkflow,
                options
              );
          }
          if (
            message.destinationActionErrorApplication != null &&
            message.hasOwnProperty('destinationActionErrorApplication')
          ) {
            object.destinationActionErrorApplication =
              options.enums === String
                ? workflow_destination_applications$1.v1.DestinationApplication
                    .Application[message.destinationActionErrorApplication] ===
                  undefined
                  ? message.destinationActionErrorApplication
                  : workflow_destination_applications$1.v1
                      .DestinationApplication.Application[
                      message.destinationActionErrorApplication
                    ]
                : message.destinationActionErrorApplication;
          }
          if (
            message.destinationActionName != null &&
            message.hasOwnProperty('destinationActionName')
          ) {
            object.destinationActionName = message.destinationActionName;
          }
          if (
            message.errorString != null &&
            message.hasOwnProperty('errorString')
          ) {
            object.errorString = message.errorString;
          }
          if (
            message.errorClass != null &&
            message.hasOwnProperty('errorClass')
          ) {
            object.errorClass =
              options.enums === String
                ? workflow_evaluation_results$1.v1.ErrorClass[
                    message.errorClass
                  ] === undefined
                  ? message.errorClass
                  : workflow_evaluation_results$1.v1.ErrorClass[
                      message.errorClass
                    ]
                : message.errorClass;
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListWorkflowGroupedDestinationActionErrorsRequest'
          );
        }
      }

      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.affectedWorkflow =
        null;
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.destinationActionErrorApplication = 0;
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.destinationActionName =
        '';
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.errorString =
        '';
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.errorClass = 0;
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.earliestTimestampInclusive =
        null;
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.latestTimestampExclusive =
        null;
      ListWorkflowGroupedDestinationActionErrorsRequest.prototype.limit =
        $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

      return ListWorkflowGroupedDestinationActionErrorsRequest;
    })();

    v1.ListWorkflowGroupedDestinationActionErrorsResponse = (() => {
      class ListWorkflowGroupedDestinationActionErrorsResponse {
        constructor(properties) {
          this.errors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowGroupedDestinationActionErrorsResponse(
            properties
          );
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errors != null &&
            Object.hasOwnProperty.call(message, 'errors')
          ) {
            for (const element of message.errors) {
              $root.workflows.v1.DestinationActionErrorEvent.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message =
            new ListWorkflowGroupedDestinationActionErrorsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.errors || !message.errors.length) {
                  message.errors = [];
                }
                message.errors.push(
                  $root.workflows.v1.DestinationActionErrorEvent.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1
              .ListWorkflowGroupedDestinationActionErrorsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListWorkflowGroupedDestinationActionErrorsResponse();
          if (object.errors) {
            if (!Array.isArray(object.errors)) {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedDestinationActionErrorsResponse.errors: array type expected, but got ' +
                  typeof object.errors
              );
            }
            message.errors = new Array(object.errors.length);
            for (let i = 0; i < object.errors.length; ++i) {
              if (typeof object.errors[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListWorkflowGroupedDestinationActionErrorsResponse.errors: object expected, but got ' +
                    typeof object.errors[i]
                );
              }
              message.errors[i] =
                $root.workflows.v1.DestinationActionErrorEvent.fromObject(
                  object.errors[i]
                );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowGroupedDestinationActionErrorsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.errors && message.errors.length) {
            object.errors = new Array(message.errors.length);
            for (let i = 0; i < message.errors.length; ++i) {
              object.errors[i] =
                $root.workflows.v1.DestinationActionErrorEvent.toObject(
                  message.errors[i],
                  options
                );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListWorkflowGroupedDestinationActionErrorsResponse'
          );
        }
      }

      ListWorkflowGroupedDestinationActionErrorsResponse.prototype.errors =
        $util.emptyArray;
      ListWorkflowGroupedDestinationActionErrorsResponse.prototype.earliestFetchedTimestamp =
        null;

      return ListWorkflowGroupedDestinationActionErrorsResponse;
    })();

    v1.ListProfileOutcomesRequest = (() => {
      class ListProfileOutcomesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfileOutcomesRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.profileThirdPartyIds != null &&
            Object.hasOwnProperty.call(message, 'profileThirdPartyIds')
          ) {
            profiles$1.v1.ThirdPartyIds.encode(
              message.profileThirdPartyIds,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(32).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfileOutcomesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.profileThirdPartyIds =
                  profiles$1.v1.ThirdPartyIds.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListProfileOutcomesRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListProfileOutcomesRequest();
          if (object.profileThirdPartyIds != null) {
            if (typeof object.profileThirdPartyIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListProfileOutcomesRequest.profileThirdPartyIds: object expected, but got ' +
                  typeof object.profileThirdPartyIds
              );
            }
            message.profileThirdPartyIds =
              profiles$1.v1.ThirdPartyIds.fromObject(
                object.profileThirdPartyIds
              );
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListProfileOutcomesRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListProfileOutcomesRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.profileThirdPartyIds = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.profileThirdPartyIds != null &&
            message.hasOwnProperty('profileThirdPartyIds')
          ) {
            object.profileThirdPartyIds = profiles$1.v1.ThirdPartyIds.toObject(
              message.profileThirdPartyIds,
              options
            );
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListProfileOutcomesRequest';
        }
      }

      ListProfileOutcomesRequest.prototype.profileThirdPartyIds = null;
      ListProfileOutcomesRequest.prototype.earliestTimestampInclusive = null;
      ListProfileOutcomesRequest.prototype.latestTimestampExclusive = null;
      ListProfileOutcomesRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListProfileOutcomesRequest;
    })();

    v1.ListProfileDestinationActionOutcomesResponse = (() => {
      class ListProfileDestinationActionOutcomesResponse {
        constructor(properties) {
          this.destinationActionOutcomes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfileDestinationActionOutcomesResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationActionOutcomes != null &&
            Object.hasOwnProperty.call(message, 'destinationActionOutcomes')
          ) {
            for (const element of message.destinationActionOutcomes) {
              $root.workflows.v1.WorkflowExecutionDestinationActionOutcome.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfileDestinationActionOutcomesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.destinationActionOutcomes ||
                  !message.destinationActionOutcomes.length
                ) {
                  message.destinationActionOutcomes = [];
                }
                message.destinationActionOutcomes.push(
                  $root.workflows.v1.WorkflowExecutionDestinationActionOutcome.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.ListProfileDestinationActionOutcomesResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListProfileDestinationActionOutcomesResponse();
          if (object.destinationActionOutcomes) {
            if (!Array.isArray(object.destinationActionOutcomes)) {
              throw new TypeError(
                '.workflows.v1.ListProfileDestinationActionOutcomesResponse.destinationActionOutcomes: array type expected, but got ' +
                  typeof object.destinationActionOutcomes
              );
            }
            message.destinationActionOutcomes = new Array(
              object.destinationActionOutcomes.length
            );
            for (let i = 0; i < object.destinationActionOutcomes.length; ++i) {
              if (typeof object.destinationActionOutcomes[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListProfileDestinationActionOutcomesResponse.destinationActionOutcomes: object expected, but got ' +
                    typeof object.destinationActionOutcomes[i]
                );
              }
              message.destinationActionOutcomes[i] =
                $root.workflows.v1.WorkflowExecutionDestinationActionOutcome.fromObject(
                  object.destinationActionOutcomes[i]
                );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListProfileDestinationActionOutcomesResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.destinationActionOutcomes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.destinationActionOutcomes &&
            message.destinationActionOutcomes.length
          ) {
            object.destinationActionOutcomes = new Array(
              message.destinationActionOutcomes.length
            );
            for (let i = 0; i < message.destinationActionOutcomes.length; ++i) {
              object.destinationActionOutcomes[i] =
                $root.workflows.v1.WorkflowExecutionDestinationActionOutcome.toObject(
                  message.destinationActionOutcomes[i],
                  options
                );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListProfileDestinationActionOutcomesResponse'
          );
        }
      }

      ListProfileDestinationActionOutcomesResponse.prototype.destinationActionOutcomes =
        $util.emptyArray;
      ListProfileDestinationActionOutcomesResponse.prototype.earliestFetchedTimestamp =
        null;

      return ListProfileDestinationActionOutcomesResponse;
    })();

    v1.ListProfileWorkflowEventsResponse = (() => {
      class ListProfileWorkflowEventsResponse {
        constructor(properties) {
          this.workflowEvents = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListProfileWorkflowEventsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowEvents != null &&
            Object.hasOwnProperty.call(message, 'workflowEvents')
          ) {
            for (const element of message.workflowEvents) {
              $root.workflows.v1.WorkflowEvent.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListProfileWorkflowEventsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.workflowEvents || !message.workflowEvents.length) {
                  message.workflowEvents = [];
                }
                message.workflowEvents.push(
                  $root.workflows.v1.WorkflowEvent.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.ListProfileWorkflowEventsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListProfileWorkflowEventsResponse();
          if (object.workflowEvents) {
            if (!Array.isArray(object.workflowEvents)) {
              throw new TypeError(
                '.workflows.v1.ListProfileWorkflowEventsResponse.workflowEvents: array type expected, but got ' +
                  typeof object.workflowEvents
              );
            }
            message.workflowEvents = new Array(object.workflowEvents.length);
            for (let i = 0; i < object.workflowEvents.length; ++i) {
              if (typeof object.workflowEvents[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListProfileWorkflowEventsResponse.workflowEvents: object expected, but got ' +
                    typeof object.workflowEvents[i]
                );
              }
              message.workflowEvents[i] =
                $root.workflows.v1.WorkflowEvent.fromObject(
                  object.workflowEvents[i]
                );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListProfileWorkflowEventsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflowEvents = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.workflowEvents && message.workflowEvents.length) {
            object.workflowEvents = new Array(message.workflowEvents.length);
            for (let i = 0; i < message.workflowEvents.length; ++i) {
              object.workflowEvents[i] =
                $root.workflows.v1.WorkflowEvent.toObject(
                  message.workflowEvents[i],
                  options
                );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.ListProfileWorkflowEventsResponse'
          );
        }
      }

      ListProfileWorkflowEventsResponse.prototype.workflowEvents =
        $util.emptyArray;
      ListProfileWorkflowEventsResponse.prototype.earliestFetchedTimestamp =
        null;

      return ListProfileWorkflowEventsResponse;
    })();

    v1.ListWorkflowExecutionDestinationActionOutcomesRequest = (() => {
      class ListWorkflowExecutionDestinationActionOutcomesRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowExecutionDestinationActionOutcomesRequest(
            properties
          );
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message =
            new ListWorkflowExecutionDestinationActionOutcomesRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1
              .ListWorkflowExecutionDestinationActionOutcomesRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListWorkflowExecutionDestinationActionOutcomesRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListWorkflowExecutionDestinationActionOutcomesRequest'
          );
        }
      }

      ListWorkflowExecutionDestinationActionOutcomesRequest.prototype.workflowExecutionId =
        '';

      return ListWorkflowExecutionDestinationActionOutcomesRequest;
    })();

    v1.ListWorkflowExecutionDestinationActionOutcomesResponse = (() => {
      class ListWorkflowExecutionDestinationActionOutcomesResponse {
        constructor(properties) {
          this.destinationActionOutcomes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowExecutionDestinationActionOutcomesResponse(
            properties
          );
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationActionOutcomes != null &&
            Object.hasOwnProperty.call(message, 'destinationActionOutcomes')
          ) {
            for (const element of message.destinationActionOutcomes) {
              $root.workflows.v1.DestinationActionOutcome.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message =
            new ListWorkflowExecutionDestinationActionOutcomesResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.destinationActionOutcomes ||
                  !message.destinationActionOutcomes.length
                ) {
                  message.destinationActionOutcomes = [];
                }
                message.destinationActionOutcomes.push(
                  $root.workflows.v1.DestinationActionOutcome.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1
              .ListWorkflowExecutionDestinationActionOutcomesResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListWorkflowExecutionDestinationActionOutcomesResponse();
          if (object.destinationActionOutcomes) {
            if (!Array.isArray(object.destinationActionOutcomes)) {
              throw new TypeError(
                '.workflows.v1.ListWorkflowExecutionDestinationActionOutcomesResponse.destinationActionOutcomes: array type expected, but got ' +
                  typeof object.destinationActionOutcomes
              );
            }
            message.destinationActionOutcomes = new Array(
              object.destinationActionOutcomes.length
            );
            for (let i = 0; i < object.destinationActionOutcomes.length; ++i) {
              if (typeof object.destinationActionOutcomes[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListWorkflowExecutionDestinationActionOutcomesResponse.destinationActionOutcomes: object expected, but got ' +
                    typeof object.destinationActionOutcomes[i]
                );
              }
              message.destinationActionOutcomes[i] =
                $root.workflows.v1.DestinationActionOutcome.fromObject(
                  object.destinationActionOutcomes[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.destinationActionOutcomes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.destinationActionOutcomes &&
            message.destinationActionOutcomes.length
          ) {
            object.destinationActionOutcomes = new Array(
              message.destinationActionOutcomes.length
            );
            for (let i = 0; i < message.destinationActionOutcomes.length; ++i) {
              object.destinationActionOutcomes[i] =
                $root.workflows.v1.DestinationActionOutcome.toObject(
                  message.destinationActionOutcomes[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.ListWorkflowExecutionDestinationActionOutcomesResponse'
          );
        }
      }

      ListWorkflowExecutionDestinationActionOutcomesResponse.prototype.destinationActionOutcomes =
        $util.emptyArray;

      return ListWorkflowExecutionDestinationActionOutcomesResponse;
    })();

    v1.ListWorkflowIdEventsRequest = (() => {
      class ListWorkflowIdEventsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowIdEventsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(32).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowIdEventsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              case 2: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 3: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListWorkflowIdEventsRequest
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListWorkflowIdEventsRequest();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowIdEventsRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowIdEventsRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListWorkflowIdEventsRequest';
        }
      }

      ListWorkflowIdEventsRequest.prototype.workflowId = '';
      ListWorkflowIdEventsRequest.prototype.earliestTimestampInclusive = null;
      ListWorkflowIdEventsRequest.prototype.latestTimestampExclusive = null;
      ListWorkflowIdEventsRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListWorkflowIdEventsRequest;
    })();

    v1.ListWorkflowIdEventsResponse = (() => {
      class ListWorkflowIdEventsResponse {
        constructor(properties) {
          this.workflowIdEvents = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListWorkflowIdEventsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowIdEvents != null &&
            Object.hasOwnProperty.call(message, 'workflowIdEvents')
          ) {
            for (const element of message.workflowIdEvents) {
              $root.workflows.v1.WorkflowIdEvent.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListWorkflowIdEventsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.workflowIdEvents ||
                  !message.workflowIdEvents.length
                ) {
                  message.workflowIdEvents = [];
                }
                message.workflowIdEvents.push(
                  $root.workflows.v1.WorkflowIdEvent.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListWorkflowIdEventsResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.ListWorkflowIdEventsResponse();
          if (object.workflowIdEvents) {
            if (!Array.isArray(object.workflowIdEvents)) {
              throw new TypeError(
                '.workflows.v1.ListWorkflowIdEventsResponse.workflowIdEvents: array type expected, but got ' +
                  typeof object.workflowIdEvents
              );
            }
            message.workflowIdEvents = new Array(
              object.workflowIdEvents.length
            );
            for (let i = 0; i < object.workflowIdEvents.length; ++i) {
              if (typeof object.workflowIdEvents[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListWorkflowIdEventsResponse.workflowIdEvents: object expected, but got ' +
                    typeof object.workflowIdEvents[i]
                );
              }
              message.workflowIdEvents[i] =
                $root.workflows.v1.WorkflowIdEvent.fromObject(
                  object.workflowIdEvents[i]
                );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListWorkflowIdEventsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflowIdEvents = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.workflowIdEvents && message.workflowIdEvents.length) {
            object.workflowIdEvents = new Array(
              message.workflowIdEvents.length
            );
            for (let i = 0; i < message.workflowIdEvents.length; ++i) {
              object.workflowIdEvents[i] =
                $root.workflows.v1.WorkflowIdEvent.toObject(
                  message.workflowIdEvents[i],
                  options
                );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListWorkflowIdEventsResponse';
        }
      }

      ListWorkflowIdEventsResponse.prototype.workflowIdEvents =
        $util.emptyArray;
      ListWorkflowIdEventsResponse.prototype.earliestFetchedTimestamp = null;

      return ListWorkflowIdEventsResponse;
    })();

    v1.ListGlobalLogsRequest = (() => {
      class ListGlobalLogsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListGlobalLogsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.earliestTimestampInclusive != null &&
            Object.hasOwnProperty.call(message, 'earliestTimestampInclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestTimestampInclusive,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.latestTimestampExclusive != null &&
            Object.hasOwnProperty.call(message, 'latestTimestampExclusive')
          ) {
            timing$1.v1.Timestamp.encode(
              message.latestTimestampExclusive,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(24).uint64(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListGlobalLogsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.earliestTimestampInclusive =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 2: {
                message.latestTimestampExclusive = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.limit = reader.uint64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListGlobalLogsRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListGlobalLogsRequest();
          if (object.earliestTimestampInclusive != null) {
            if (typeof object.earliestTimestampInclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListGlobalLogsRequest.earliestTimestampInclusive: object expected, but got ' +
                  typeof object.earliestTimestampInclusive
              );
            }
            message.earliestTimestampInclusive =
              timing$1.v1.Timestamp.fromObject(
                object.earliestTimestampInclusive
              );
          }
          if (object.latestTimestampExclusive != null) {
            if (typeof object.latestTimestampExclusive !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListGlobalLogsRequest.latestTimestampExclusive: object expected, but got ' +
                  typeof object.latestTimestampExclusive
              );
            }
            message.latestTimestampExclusive = timing$1.v1.Timestamp.fromObject(
              object.latestTimestampExclusive
            );
          }
          if (object.limit != null) {
            if ($util.Long) {
              (message.limit = $util.Long.fromValue(
                object.limit
              )).unsigned = true;
            } else if (typeof object.limit === 'string') {
              message.limit = parseInt(object.limit, 10);
            } else if (typeof object.limit === 'number') {
              message.limit = object.limit;
            } else if (typeof object.limit === 'object') {
              message.limit = new $util.LongBits(
                object.limit.low >>> 0,
                object.limit.high >>> 0
              ).toNumber(true);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.limit =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.limit = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.earliestTimestampInclusive != null &&
            message.hasOwnProperty('earliestTimestampInclusive')
          ) {
            object.earliestTimestampInclusive = timing$1.v1.Timestamp.toObject(
              message.earliestTimestampInclusive,
              options
            );
          }
          if (
            message.latestTimestampExclusive != null &&
            message.hasOwnProperty('latestTimestampExclusive')
          ) {
            object.latestTimestampExclusive = timing$1.v1.Timestamp.toObject(
              message.latestTimestampExclusive,
              options
            );
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit =
              typeof message.limit === 'number'
                ? options.longs === String
                  ? String(message.limit)
                  : message.limit
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.limit)
                : options.longs === Number
                ? new $util.LongBits(
                    message.limit.low >>> 0,
                    message.limit.high >>> 0
                  ).toNumber(true)
                : message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListGlobalLogsRequest';
        }
      }

      ListGlobalLogsRequest.prototype.earliestTimestampInclusive = null;
      ListGlobalLogsRequest.prototype.latestTimestampExclusive = null;
      ListGlobalLogsRequest.prototype.limit = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;

      return ListGlobalLogsRequest;
    })();

    v1.ListGlobalLogsResponse = (() => {
      class ListGlobalLogsResponse {
        constructor(properties) {
          this.globalLogs = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListGlobalLogsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.globalLogs != null &&
            Object.hasOwnProperty.call(message, 'globalLogs')
          ) {
            for (const element of message.globalLogs) {
              $root.workflows.v1.GlobalLog.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            Object.hasOwnProperty.call(message, 'earliestFetchedTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.earliestFetchedTimestamp,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListGlobalLogsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.globalLogs || !message.globalLogs.length) {
                  message.globalLogs = [];
                }
                message.globalLogs.push(
                  $root.workflows.v1.GlobalLog.decode(reader, reader.uint32())
                );
                break;
              }
              case 2: {
                message.earliestFetchedTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListGlobalLogsResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListGlobalLogsResponse();
          if (object.globalLogs) {
            if (!Array.isArray(object.globalLogs)) {
              throw new TypeError(
                '.workflows.v1.ListGlobalLogsResponse.globalLogs: array type expected, but got ' +
                  typeof object.globalLogs
              );
            }
            message.globalLogs = new Array(object.globalLogs.length);
            for (let i = 0; i < object.globalLogs.length; ++i) {
              if (typeof object.globalLogs[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListGlobalLogsResponse.globalLogs: object expected, but got ' +
                    typeof object.globalLogs[i]
                );
              }
              message.globalLogs[i] = $root.workflows.v1.GlobalLog.fromObject(
                object.globalLogs[i]
              );
            }
          }
          if (object.earliestFetchedTimestamp != null) {
            if (typeof object.earliestFetchedTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListGlobalLogsResponse.earliestFetchedTimestamp: object expected, but got ' +
                  typeof object.earliestFetchedTimestamp
              );
            }
            message.earliestFetchedTimestamp = timing$1.v1.Timestamp.fromObject(
              object.earliestFetchedTimestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.globalLogs = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.globalLogs && message.globalLogs.length) {
            object.globalLogs = new Array(message.globalLogs.length);
            for (let i = 0; i < message.globalLogs.length; ++i) {
              object.globalLogs[i] = $root.workflows.v1.GlobalLog.toObject(
                message.globalLogs[i],
                options
              );
            }
          }
          if (
            message.earliestFetchedTimestamp != null &&
            message.hasOwnProperty('earliestFetchedTimestamp')
          ) {
            object.earliestFetchedTimestamp = timing$1.v1.Timestamp.toObject(
              message.earliestFetchedTimestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListGlobalLogsResponse';
        }
      }

      ListGlobalLogsResponse.prototype.globalLogs = $util.emptyArray;
      ListGlobalLogsResponse.prototype.earliestFetchedTimestamp = null;

      return ListGlobalLogsResponse;
    })();

    v1.RetryWorkflowExecutionRequest = (() => {
      class RetryWorkflowExecutionRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryWorkflowExecutionRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.node != null &&
            Object.hasOwnProperty.call(message, 'node')
          ) {
            $root.workflows.v1.RetryWorkflowExecutionRequest.Node.encode(
              message.node,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryWorkflowExecutionRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.node =
                  $root.workflows.v1.RetryWorkflowExecutionRequest.Node.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.RetryWorkflowExecutionRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.RetryWorkflowExecutionRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.node != null) {
            if (typeof object.node !== 'object') {
              throw new TypeError(
                '.workflows.v1.RetryWorkflowExecutionRequest.node: object expected, but got ' +
                  typeof object.node
              );
            }
            message.node =
              $root.workflows.v1.RetryWorkflowExecutionRequest.Node.fromObject(
                object.node
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (message.node != null && message.hasOwnProperty('node')) {
            object.node =
              $root.workflows.v1.RetryWorkflowExecutionRequest.Node.toObject(
                message.node,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryWorkflowExecutionRequest';
        }
      }

      RetryWorkflowExecutionRequest.prototype.workflowExecutionId = '';
      RetryWorkflowExecutionRequest.prototype.node = null;
      RetryWorkflowExecutionRequest.Node = (() => {
        class Node {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Node(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.id != null &&
              Object.hasOwnProperty.call(message, 'id')
            ) {
              writer.uint32(10).string(message.id);
            }
            if (
              message.runDownstream != null &&
              Object.hasOwnProperty.call(message, 'runDownstream')
            ) {
              writer.uint32(16).bool(message.runDownstream);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Node();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.id = reader.string();
                  break;
                }
                case 2: {
                  message.runDownstream = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.RetryWorkflowExecutionRequest.Node
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.RetryWorkflowExecutionRequest.Node();
            if (object.id != null) {
              message.id = String(object.id);
            }
            if (object.runDownstream != null) {
              message.runDownstream = Boolean(object.runDownstream);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.id = '';
              object.runDownstream = false;
            }
            let keys;
            if (message.id != null && message.hasOwnProperty('id')) {
              object.id = message.id;
            }
            if (
              message.runDownstream != null &&
              message.hasOwnProperty('runDownstream')
            ) {
              object.runDownstream = message.runDownstream;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'workflows.v1.RetryWorkflowExecutionRequest.Node'
            );
          }
        }

        Node.prototype.id = '';
        Node.prototype.runDownstream = false;

        return Node;
      })();

      return RetryWorkflowExecutionRequest;
    })();

    v1.RetryWorkflowExecutionResponse = (() => {
      class RetryWorkflowExecutionResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RetryWorkflowExecutionResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.executionIds != null &&
            Object.hasOwnProperty.call(message, 'executionIds')
          ) {
            $root.workflows.v1.InternalWorkflowExecutionIds.encode(
              message.executionIds,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RetryWorkflowExecutionResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.executionIds =
                  $root.workflows.v1.InternalWorkflowExecutionIds.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.RetryWorkflowExecutionResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.RetryWorkflowExecutionResponse();
          if (object.executionIds != null) {
            if (typeof object.executionIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.RetryWorkflowExecutionResponse.executionIds: object expected, but got ' +
                  typeof object.executionIds
              );
            }
            message.executionIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.fromObject(
                object.executionIds
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.executionIds = null;
          }
          let keys;
          if (
            message.executionIds != null &&
            message.hasOwnProperty('executionIds')
          ) {
            object.executionIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.toObject(
                message.executionIds,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RetryWorkflowExecutionResponse';
        }
      }

      RetryWorkflowExecutionResponse.prototype.executionIds = null;

      return RetryWorkflowExecutionResponse;
    })();

    v1.ListDestinationActionsRequest = (() => {
      class ListDestinationActionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDestinationActionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDestinationActionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListDestinationActionsRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListDestinationActionsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListDestinationActionsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListDestinationActionsRequest';
        }
      }

      ListDestinationActionsRequest.prototype.stub = null;

      return ListDestinationActionsRequest;
    })();

    v1.ListDestinationActionsResponse = (() => {
      class ListDestinationActionsResponse {
        constructor(properties) {
          this.destinationActions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListDestinationActionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationActions != null &&
            Object.hasOwnProperty.call(message, 'destinationActions')
          ) {
            for (const element of message.destinationActions) {
              $root.workflows.v1.WorkflowDestinationAction.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListDestinationActionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.destinationActions ||
                  !message.destinationActions.length
                ) {
                  message.destinationActions = [];
                }
                message.destinationActions.push(
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.ListDestinationActionsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.ListDestinationActionsResponse();
          if (object.destinationActions) {
            if (!Array.isArray(object.destinationActions)) {
              throw new TypeError(
                '.workflows.v1.ListDestinationActionsResponse.destinationActions: array type expected, but got ' +
                  typeof object.destinationActions
              );
            }
            message.destinationActions = new Array(
              object.destinationActions.length
            );
            for (let i = 0; i < object.destinationActions.length; ++i) {
              if (typeof object.destinationActions[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListDestinationActionsResponse.destinationActions: object expected, but got ' +
                    typeof object.destinationActions[i]
                );
              }
              message.destinationActions[i] =
                $root.workflows.v1.WorkflowDestinationAction.fromObject(
                  object.destinationActions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.destinationActions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.destinationActions && message.destinationActions.length) {
            object.destinationActions = new Array(
              message.destinationActions.length
            );
            for (let i = 0; i < message.destinationActions.length; ++i) {
              object.destinationActions[i] =
                $root.workflows.v1.WorkflowDestinationAction.toObject(
                  message.destinationActions[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListDestinationActionsResponse';
        }
      }

      ListDestinationActionsResponse.prototype.destinationActions =
        $util.emptyArray;

      return ListDestinationActionsResponse;
    })();

    v1.ListFetchActionsRequest = (() => {
      class ListFetchActionsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListFetchActionsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.stub != null &&
            Object.hasOwnProperty.call(message, 'stub')
          ) {
            google$1.protobuf.Empty.encode(
              message.stub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListFetchActionsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.stub = google$1.protobuf.Empty.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListFetchActionsRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListFetchActionsRequest();
          if (object.stub != null) {
            if (typeof object.stub !== 'object') {
              throw new TypeError(
                '.workflows.v1.ListFetchActionsRequest.stub: object expected, but got ' +
                  typeof object.stub
              );
            }
            message.stub = google$1.protobuf.Empty.fromObject(object.stub);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.stub = null;
          }
          let keys;
          if (message.stub != null && message.hasOwnProperty('stub')) {
            object.stub = google$1.protobuf.Empty.toObject(
              message.stub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListFetchActionsRequest';
        }
      }

      ListFetchActionsRequest.prototype.stub = null;

      return ListFetchActionsRequest;
    })();

    v1.ListFetchActionsResponse = (() => {
      class ListFetchActionsResponse {
        constructor(properties) {
          this.fetchActions = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListFetchActionsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.fetchActions != null &&
            Object.hasOwnProperty.call(message, 'fetchActions')
          ) {
            for (const element of message.fetchActions) {
              $root.workflows.v1.WorkflowFetchAction.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListFetchActionsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.fetchActions || !message.fetchActions.length) {
                  message.fetchActions = [];
                }
                message.fetchActions.push(
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListFetchActionsResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListFetchActionsResponse();
          if (object.fetchActions) {
            if (!Array.isArray(object.fetchActions)) {
              throw new TypeError(
                '.workflows.v1.ListFetchActionsResponse.fetchActions: array type expected, but got ' +
                  typeof object.fetchActions
              );
            }
            message.fetchActions = new Array(object.fetchActions.length);
            for (let i = 0; i < object.fetchActions.length; ++i) {
              if (typeof object.fetchActions[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListFetchActionsResponse.fetchActions: object expected, but got ' +
                    typeof object.fetchActions[i]
                );
              }
              message.fetchActions[i] =
                $root.workflows.v1.WorkflowFetchAction.fromObject(
                  object.fetchActions[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.fetchActions = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.fetchActions && message.fetchActions.length) {
            object.fetchActions = new Array(message.fetchActions.length);
            for (let i = 0; i < message.fetchActions.length; ++i) {
              object.fetchActions[i] =
                $root.workflows.v1.WorkflowFetchAction.toObject(
                  message.fetchActions[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListFetchActionsResponse';
        }
      }

      ListFetchActionsResponse.prototype.fetchActions = $util.emptyArray;

      return ListFetchActionsResponse;
    })();

    v1.GetEventPayloadFieldSchemasRequest = (() => {
      class GetEventPayloadFieldSchemasRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetEventPayloadFieldSchemasRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventPayloadStub != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadStub')
          ) {
            event_payload$1.v2.EventPayload.encode(
              message.eventPayloadStub,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetEventPayloadFieldSchemasRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.eventPayloadStub =
                  event_payload$1.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetEventPayloadFieldSchemasRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetEventPayloadFieldSchemasRequest();
          if (object.eventPayloadStub != null) {
            if (typeof object.eventPayloadStub !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetEventPayloadFieldSchemasRequest.eventPayloadStub: object expected, but got ' +
                  typeof object.eventPayloadStub
              );
            }
            message.eventPayloadStub =
              event_payload$1.v2.EventPayload.fromObject(
                object.eventPayloadStub
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventPayloadStub = null;
          }
          let keys;
          if (
            message.eventPayloadStub != null &&
            message.hasOwnProperty('eventPayloadStub')
          ) {
            object.eventPayloadStub = event_payload$1.v2.EventPayload.toObject(
              message.eventPayloadStub,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetEventPayloadFieldSchemasRequest'
          );
        }
      }

      GetEventPayloadFieldSchemasRequest.prototype.eventPayloadStub = null;

      return GetEventPayloadFieldSchemasRequest;
    })();

    v1.GetEventPayloadFieldSchemasResponse = (() => {
      class GetEventPayloadFieldSchemasResponse {
        constructor(properties) {
          this.eventPayloadFieldSchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetEventPayloadFieldSchemasResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventPayloadFieldSchemas != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadFieldSchemas')
          ) {
            for (const element of message.eventPayloadFieldSchemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.eventPayloadExample != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadExample')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayloadExample,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetEventPayloadFieldSchemasResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.eventPayloadFieldSchemas ||
                  !message.eventPayloadFieldSchemas.length
                ) {
                  message.eventPayloadFieldSchemas = [];
                }
                message.eventPayloadFieldSchemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 2: {
                message.eventPayloadExample = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetEventPayloadFieldSchemasResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetEventPayloadFieldSchemasResponse();
          if (object.eventPayloadFieldSchemas) {
            if (!Array.isArray(object.eventPayloadFieldSchemas)) {
              throw new TypeError(
                '.workflows.v1.GetEventPayloadFieldSchemasResponse.eventPayloadFieldSchemas: array type expected, but got ' +
                  typeof object.eventPayloadFieldSchemas
              );
            }
            message.eventPayloadFieldSchemas = new Array(
              object.eventPayloadFieldSchemas.length
            );
            for (let i = 0; i < object.eventPayloadFieldSchemas.length; ++i) {
              if (typeof object.eventPayloadFieldSchemas[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GetEventPayloadFieldSchemasResponse.eventPayloadFieldSchemas: object expected, but got ' +
                    typeof object.eventPayloadFieldSchemas[i]
                );
              }
              message.eventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.eventPayloadFieldSchemas[i]
                );
            }
          }
          if (object.eventPayloadExample != null) {
            if (typeof object.eventPayloadExample !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetEventPayloadFieldSchemasResponse.eventPayloadExample: object expected, but got ' +
                  typeof object.eventPayloadExample
              );
            }
            message.eventPayloadExample = google$2.protobuf.Struct.fromObject(
              object.eventPayloadExample
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventPayloadFieldSchemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.eventPayloadExample = null;
          }
          let keys;
          if (
            message.eventPayloadFieldSchemas &&
            message.eventPayloadFieldSchemas.length
          ) {
            object.eventPayloadFieldSchemas = new Array(
              message.eventPayloadFieldSchemas.length
            );
            for (let i = 0; i < message.eventPayloadFieldSchemas.length; ++i) {
              object.eventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.eventPayloadFieldSchemas[i],
                  options
                );
            }
          }
          if (
            message.eventPayloadExample != null &&
            message.hasOwnProperty('eventPayloadExample')
          ) {
            object.eventPayloadExample = google$2.protobuf.Struct.toObject(
              message.eventPayloadExample,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetEventPayloadFieldSchemasResponse'
          );
        }
      }

      GetEventPayloadFieldSchemasResponse.prototype.eventPayloadFieldSchemas =
        $util.emptyArray;
      GetEventPayloadFieldSchemasResponse.prototype.eventPayloadExample = null;

      return GetEventPayloadFieldSchemasResponse;
    })();

    v1.Workflow = (() => {
      class Workflow {
        constructor(properties) {
          this.tags = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Workflow(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.tags != null &&
            Object.hasOwnProperty.call(message, 'tags')
          ) {
            for (const element of message.tags) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.timing != null &&
            Object.hasOwnProperty.call(message, 'timing')
          ) {
            timing$1.v1.Timing.encode(
              message.timing,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(48).int32(message.status);
          }
          if (
            message.sourceId != null &&
            Object.hasOwnProperty.call(message, 'sourceId')
          ) {
            writer.uint32(58).string(message.sourceId);
          }
          if (
            message.activeVersion != null &&
            Object.hasOwnProperty.call(message, 'activeVersion')
          ) {
            $root.workflows.v1.Version.encode(
              message.activeVersion,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.latestDraftVersion != null &&
            Object.hasOwnProperty.call(message, 'latestDraftVersion')
          ) {
            $root.workflows.v1.Version.encode(
              message.latestDraftVersion,
              writer.uint32(74).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Workflow();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                if (!message.tags || !message.tags.length) {
                  message.tags = [];
                }
                message.tags.push(reader.string());
                break;
              }
              case 5: {
                message.timing = timing$1.v1.Timing.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.status = reader.int32();
                break;
              }
              case 7: {
                message.sourceId = reader.string();
                break;
              }
              case 8: {
                message.activeVersion = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.latestDraftVersion = $root.workflows.v1.Version.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.Workflow) {
            return object;
          }
          const message = new $root.workflows.v1.Workflow();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.tags) {
            if (!Array.isArray(object.tags)) {
              throw new TypeError(
                '.workflows.v1.Workflow.tags: array type expected, but got ' +
                  typeof object.tags
              );
            }
            message.tags = new Array(object.tags.length);
            for (let i = 0; i < object.tags.length; ++i) {
              message.tags[i] = String(object.tags[i]);
            }
          }
          if (object.timing != null) {
            if (typeof object.timing !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.timing: object expected, but got ' +
                  typeof object.timing
              );
            }
            message.timing = timing$1.v1.Timing.fromObject(object.timing);
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.sourceId != null) {
            message.sourceId = String(object.sourceId);
          }
          if (object.activeVersion != null) {
            if (typeof object.activeVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.activeVersion: object expected, but got ' +
                  typeof object.activeVersion
              );
            }
            message.activeVersion = $root.workflows.v1.Version.fromObject(
              object.activeVersion
            );
          }
          if (object.latestDraftVersion != null) {
            if (typeof object.latestDraftVersion !== 'object') {
              throw new TypeError(
                '.workflows.v1.Workflow.latestDraftVersion: object expected, but got ' +
                  typeof object.latestDraftVersion
              );
            }
            message.latestDraftVersion = $root.workflows.v1.Version.fromObject(
              object.latestDraftVersion
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.tags = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
            object.name = '';
            object.timing = null;
            object.status =
              options.enums === String ? 'UNSPECIFIED_WORKFLOW_STATUS' : 0;
            object.sourceId = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (message.tags && message.tags.length) {
            object.tags = new Array(message.tags.length);
            for (let i = 0; i < message.tags.length; ++i) {
              object.tags[i] = message.tags[i];
            }
          }
          if (message.timing != null && message.hasOwnProperty('timing')) {
            object.timing = timing$1.v1.Timing.toObject(
              message.timing,
              options
            );
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (message.sourceId != null && message.hasOwnProperty('sourceId')) {
            object.sourceId = message.sourceId;
          }
          if (
            message.activeVersion != null &&
            message.hasOwnProperty('activeVersion')
          ) {
            object.activeVersion = $root.workflows.v1.Version.toObject(
              message.activeVersion,
              options
            );
          }
          if (
            message.latestDraftVersion != null &&
            message.hasOwnProperty('latestDraftVersion')
          ) {
            object.latestDraftVersion = $root.workflows.v1.Version.toObject(
              message.latestDraftVersion,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.Workflow';
        }
      }

      Workflow.prototype.id = '';
      Workflow.prototype.name = '';
      Workflow.prototype.description = null;
      Workflow.prototype.tags = $util.emptyArray;
      Workflow.prototype.timing = null;
      Workflow.prototype.status = 0;
      Workflow.prototype.sourceId = '';
      Workflow.prototype.activeVersion = null;
      Workflow.prototype.latestDraftVersion = null;

      return Workflow;
    })();

    v1.Version = (() => {
      class Version {
        constructor(properties) {
          this.workflowEventPayloadFieldSchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Version(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(8).int64(message.version);
          }
          if (
            message.trigger != null &&
            Object.hasOwnProperty.call(message, 'trigger')
          ) {
            $root.workflows.v1.Trigger.encode(
              message.trigger,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.createdBy != null &&
            Object.hasOwnProperty.call(message, 'createdBy')
          ) {
            writer.uint32(26).string(message.createdBy);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            $root.workflows.v1.WorkflowNodes.encode(
              message.nodes,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            $root.workflows.v1.WorkflowEdges.encode(
              message.edges,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.eventPayloadTypeHash != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadTypeHash')
          ) {
            writer.uint32(64).int64(message.eventPayloadTypeHash);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(72).int32(message.status);
          }
          if (
            message.updatedBy != null &&
            Object.hasOwnProperty.call(message, 'updatedBy')
          ) {
            writer.uint32(82).string(message.updatedBy);
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.workflowEventPayloadFieldSchemas != null &&
            Object.hasOwnProperty.call(
              message,
              'workflowEventPayloadFieldSchemas'
            )
          ) {
            for (const element of message.workflowEventPayloadFieldSchemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(98).fork()
              ).ldelim();
            }
          }
          if (
            message.eventPayloadExample != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadExample')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayloadExample,
              writer.uint32(106).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Version();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.version = reader.int64();
                break;
              }
              case 2: {
                message.trigger = $root.workflows.v1.Trigger.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.createdBy = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.nodes = $root.workflows.v1.WorkflowNodes.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.edges = $root.workflows.v1.WorkflowEdges.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.eventPayloadTypeHash = reader.int64();
                break;
              }
              case 9: {
                message.status = reader.int32();
                break;
              }
              case 10: {
                message.updatedBy = reader.string();
                break;
              }
              case 11: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 12: {
                if (
                  !message.workflowEventPayloadFieldSchemas ||
                  !message.workflowEventPayloadFieldSchemas.length
                ) {
                  message.workflowEventPayloadFieldSchemas = [];
                }
                message.workflowEventPayloadFieldSchemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 13: {
                message.eventPayloadExample = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.Version) {
            return object;
          }
          const message = new $root.workflows.v1.Version();
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.trigger != null) {
            if (typeof object.trigger !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.trigger: object expected, but got ' +
                  typeof object.trigger
              );
            }
            message.trigger = $root.workflows.v1.Trigger.fromObject(
              object.trigger
            );
          }
          if (object.createdBy != null) {
            message.createdBy = String(object.createdBy);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.nodes != null) {
            if (typeof object.nodes !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.nodes: object expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes = $root.workflows.v1.WorkflowNodes.fromObject(
              object.nodes
            );
          }
          if (object.edges != null) {
            if (typeof object.edges !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.edges: object expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges = $root.workflows.v1.WorkflowEdges.fromObject(
              object.edges
            );
          }
          if (object.eventPayloadTypeHash != null) {
            if ($util.Long) {
              (message.eventPayloadTypeHash = $util.Long.fromValue(
                object.eventPayloadTypeHash
              )).unsigned = false;
            } else if (typeof object.eventPayloadTypeHash === 'string') {
              message.eventPayloadTypeHash = parseInt(
                object.eventPayloadTypeHash,
                10
              );
            } else if (typeof object.eventPayloadTypeHash === 'number') {
              message.eventPayloadTypeHash = object.eventPayloadTypeHash;
            } else if (typeof object.eventPayloadTypeHash === 'object') {
              message.eventPayloadTypeHash = new $util.LongBits(
                object.eventPayloadTypeHash.low >>> 0,
                object.eventPayloadTypeHash.high >>> 0
              ).toNumber();
            }
          }
          if (object.status != null) {
            switch (object.status) {
              case 'UNSPECIFIED_WORKFLOW_STATUS':
              case 0: {
                message.status = 0;
                break;
              }
              case 'DRAFT':
              case 1: {
                message.status = 1;
                break;
              }
              case 'ACTIVE':
              case 2: {
                message.status = 2;
                break;
              }
              case 'INACTIVE':
              case 3: {
                message.status = 3;
                break;
              }
              default: {
                if (typeof object.status == 'number') {
                  message.status = object.status;
                  break;
                }
                break;
              }
            }
          }
          if (object.updatedBy != null) {
            message.updatedBy = String(object.updatedBy);
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.workflowEventPayloadFieldSchemas) {
            if (!Array.isArray(object.workflowEventPayloadFieldSchemas)) {
              throw new TypeError(
                '.workflows.v1.Version.workflowEventPayloadFieldSchemas: array type expected, but got ' +
                  typeof object.workflowEventPayloadFieldSchemas
              );
            }
            message.workflowEventPayloadFieldSchemas = new Array(
              object.workflowEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < object.workflowEventPayloadFieldSchemas.length;
              ++i
            ) {
              if (
                typeof object.workflowEventPayloadFieldSchemas[i] !== 'object'
              ) {
                throw new TypeError(
                  '.workflows.v1.Version.workflowEventPayloadFieldSchemas: object expected, but got ' +
                    typeof object.workflowEventPayloadFieldSchemas[i]
                );
              }
              message.workflowEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.workflowEventPayloadFieldSchemas[i]
                );
            }
          }
          if (object.eventPayloadExample != null) {
            if (typeof object.eventPayloadExample !== 'object') {
              throw new TypeError(
                '.workflows.v1.Version.eventPayloadExample: object expected, but got ' +
                  typeof object.eventPayloadExample
              );
            }
            message.eventPayloadExample = google$2.protobuf.Struct.fromObject(
              object.eventPayloadExample
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.workflowEventPayloadFieldSchemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.trigger = null;
            object.createdBy = '';
            object.createdAt = null;
            object.profileLookupConfiguration = null;
            object.nodes = null;
            object.edges = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.eventPayloadTypeHash =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.eventPayloadTypeHash = options.longs === String ? '0' : 0;
            }
            object.status =
              options.enums === String ? 'UNSPECIFIED_WORKFLOW_STATUS' : 0;
            object.updatedBy = '';
            object.updatedAt = null;
            object.eventPayloadExample = null;
          }
          let keys;
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.trigger != null && message.hasOwnProperty('trigger')) {
            object.trigger = $root.workflows.v1.Trigger.toObject(
              message.trigger,
              options
            );
          }
          if (
            message.createdBy != null &&
            message.hasOwnProperty('createdBy')
          ) {
            object.createdBy = message.createdBy;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
          }
          if (message.nodes != null && message.hasOwnProperty('nodes')) {
            object.nodes = $root.workflows.v1.WorkflowNodes.toObject(
              message.nodes,
              options
            );
          }
          if (message.edges != null && message.hasOwnProperty('edges')) {
            object.edges = $root.workflows.v1.WorkflowEdges.toObject(
              message.edges,
              options
            );
          }
          if (
            message.eventPayloadTypeHash != null &&
            message.hasOwnProperty('eventPayloadTypeHash')
          ) {
            object.eventPayloadTypeHash =
              typeof message.eventPayloadTypeHash === 'number'
                ? options.longs === String
                  ? String(message.eventPayloadTypeHash)
                  : message.eventPayloadTypeHash
                : options.longs === String
                ? $util.Long.prototype.toString.call(
                    message.eventPayloadTypeHash
                  )
                : options.longs === Number
                ? new $util.LongBits(
                    message.eventPayloadTypeHash.low >>> 0,
                    message.eventPayloadTypeHash.high >>> 0
                  ).toNumber()
                : message.eventPayloadTypeHash;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status =
              options.enums === String
                ? $root.workflows.v1.Status[message.status] === undefined
                  ? message.status
                  : $root.workflows.v1.Status[message.status]
                : message.status;
          }
          if (
            message.updatedBy != null &&
            message.hasOwnProperty('updatedBy')
          ) {
            object.updatedBy = message.updatedBy;
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (
            message.workflowEventPayloadFieldSchemas &&
            message.workflowEventPayloadFieldSchemas.length
          ) {
            object.workflowEventPayloadFieldSchemas = new Array(
              message.workflowEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < message.workflowEventPayloadFieldSchemas.length;
              ++i
            ) {
              object.workflowEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.workflowEventPayloadFieldSchemas[i],
                  options
                );
            }
          }
          if (
            message.eventPayloadExample != null &&
            message.hasOwnProperty('eventPayloadExample')
          ) {
            object.eventPayloadExample = google$2.protobuf.Struct.toObject(
              message.eventPayloadExample,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.Version';
        }
      }

      Version.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      Version.prototype.trigger = null;
      Version.prototype.createdBy = '';
      Version.prototype.createdAt = null;
      Version.prototype.profileLookupConfiguration = null;
      Version.prototype.nodes = null;
      Version.prototype.edges = null;
      Version.prototype.eventPayloadTypeHash = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      Version.prototype.status = 0;
      Version.prototype.updatedBy = '';
      Version.prototype.updatedAt = null;
      Version.prototype.workflowEventPayloadFieldSchemas = $util.emptyArray;
      Version.prototype.eventPayloadExample = null;

      return Version;
    })();

    v1.Trigger = (() => {
      class Trigger {
        constructor(properties) {
          this.objectTypeFilters = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Trigger(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceApplication != null &&
            Object.hasOwnProperty.call(message, 'sourceApplication')
          ) {
            writer.uint32(8).int32(message.sourceApplication);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(16).int32(message.objectCategory);
          }
          if (
            message.triggerEvents != null &&
            Object.hasOwnProperty.call(message, 'triggerEvents')
          ) {
            event_payload$1.v2.EventTypes.encode(
              message.triggerEvents,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.objectTypeFilters != null &&
            Object.hasOwnProperty.call(message, 'objectTypeFilters')
          ) {
            for (const element of message.objectTypeFilters) {
              writer.uint32(34).string(element);
            }
          }
          if (
            message.extraFilter != null &&
            Object.hasOwnProperty.call(message, 'extraFilter')
          ) {
            $root.workflows.v1.WorkflowFilter.encode(
              message.extraFilter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.eventPayloadStub != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadStub')
          ) {
            event_payload$1.v2.EventPayload.encode(
              message.eventPayloadStub,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.uniquenessConflictResolution != null &&
            Object.hasOwnProperty.call(message, 'uniquenessConflictResolution')
          ) {
            $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
              message.uniquenessConflictResolution,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Trigger();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.sourceApplication = reader.int32();
                break;
              }
              case 2: {
                message.objectCategory = reader.int32();
                break;
              }
              case 3: {
                message.triggerEvents = event_payload$1.v2.EventTypes.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                if (
                  !message.objectTypeFilters ||
                  !message.objectTypeFilters.length
                ) {
                  message.objectTypeFilters = [];
                }
                message.objectTypeFilters.push(reader.string());
                break;
              }
              case 5: {
                message.extraFilter = $root.workflows.v1.WorkflowFilter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.eventPayloadStub =
                  event_payload$1.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.uniquenessConflictResolution =
                  $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.Trigger) {
            return object;
          }
          const message = new $root.workflows.v1.Trigger();
          if (object.sourceApplication != null) {
            switch (object.sourceApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.sourceApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.sourceApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.sourceApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.sourceApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.sourceApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.sourceApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.sourceApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.sourceApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.sourceApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.sourceApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.sourceApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.sourceApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.sourceApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.sourceApplication = 13;
                break;
              }
              default: {
                if (typeof object.sourceApplication == 'number') {
                  message.sourceApplication = object.sourceApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.triggerEvents != null) {
            if (typeof object.triggerEvents !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.triggerEvents: object expected, but got ' +
                  typeof object.triggerEvents
              );
            }
            message.triggerEvents = event_payload$1.v2.EventTypes.fromObject(
              object.triggerEvents
            );
          }
          if (object.objectTypeFilters) {
            if (!Array.isArray(object.objectTypeFilters)) {
              throw new TypeError(
                '.workflows.v1.Trigger.objectTypeFilters: array type expected, but got ' +
                  typeof object.objectTypeFilters
              );
            }
            message.objectTypeFilters = new Array(
              object.objectTypeFilters.length
            );
            for (let i = 0; i < object.objectTypeFilters.length; ++i) {
              message.objectTypeFilters[i] = String(
                object.objectTypeFilters[i]
              );
            }
          }
          if (object.extraFilter != null) {
            if (typeof object.extraFilter !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.extraFilter: object expected, but got ' +
                  typeof object.extraFilter
              );
            }
            message.extraFilter = $root.workflows.v1.WorkflowFilter.fromObject(
              object.extraFilter
            );
          }
          if (object.eventPayloadStub != null) {
            if (typeof object.eventPayloadStub !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.eventPayloadStub: object expected, but got ' +
                  typeof object.eventPayloadStub
              );
            }
            message.eventPayloadStub =
              event_payload$1.v2.EventPayload.fromObject(
                object.eventPayloadStub
              );
          }
          if (object.uniquenessConflictResolution != null) {
            if (typeof object.uniquenessConflictResolution !== 'object') {
              throw new TypeError(
                '.workflows.v1.Trigger.uniquenessConflictResolution: object expected, but got ' +
                  typeof object.uniquenessConflictResolution
              );
            }
            message.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                object.uniquenessConflictResolution
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypeFilters = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.sourceApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            object.triggerEvents = null;
            object.eventPayloadStub = null;
          }
          let keys;
          if (
            message.sourceApplication != null &&
            message.hasOwnProperty('sourceApplication')
          ) {
            object.sourceApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.sourceApplication
                  ] === undefined
                  ? message.sourceApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ]
                : message.sourceApplication;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.objectCategory
                  ] === undefined
                  ? message.objectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ]
                : message.objectCategory;
          }
          if (
            message.triggerEvents != null &&
            message.hasOwnProperty('triggerEvents')
          ) {
            object.triggerEvents = event_payload$1.v2.EventTypes.toObject(
              message.triggerEvents,
              options
            );
          }
          if (message.objectTypeFilters && message.objectTypeFilters.length) {
            object.objectTypeFilters = new Array(
              message.objectTypeFilters.length
            );
            for (let i = 0; i < message.objectTypeFilters.length; ++i) {
              object.objectTypeFilters[i] = message.objectTypeFilters[i];
            }
          }
          if (
            message.extraFilter != null &&
            message.hasOwnProperty('extraFilter')
          ) {
            object.extraFilter = $root.workflows.v1.WorkflowFilter.toObject(
              message.extraFilter,
              options
            );
          }
          if (
            message.eventPayloadStub != null &&
            message.hasOwnProperty('eventPayloadStub')
          ) {
            object.eventPayloadStub = event_payload$1.v2.EventPayload.toObject(
              message.eventPayloadStub,
              options
            );
          }
          if (
            message.uniquenessConflictResolution != null &&
            message.hasOwnProperty('uniquenessConflictResolution')
          ) {
            object.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                message.uniquenessConflictResolution,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.Trigger';
        }
      }

      Trigger.prototype.sourceApplication = 0;
      Trigger.prototype.objectCategory = 0;
      Trigger.prototype.triggerEvents = null;
      Trigger.prototype.objectTypeFilters = $util.emptyArray;
      Trigger.prototype.extraFilter = null;
      Trigger.prototype.eventPayloadStub = null;
      Trigger.prototype.uniquenessConflictResolution = null;

      return Trigger;
    })();

    v1.ValidTriggerEvents = (() => {
      class ValidTriggerEvents {
        constructor(properties) {
          this.sourceApplicationObjectCategories = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ValidTriggerEvents(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.sourceApplicationObjectCategories != null &&
            Object.hasOwnProperty.call(
              message,
              'sourceApplicationObjectCategories'
            )
          ) {
            for (const element of message.sourceApplicationObjectCategories) {
              $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ValidTriggerEvents();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (
                  !message.sourceApplicationObjectCategories ||
                  !message.sourceApplicationObjectCategories.length
                ) {
                  message.sourceApplicationObjectCategories = [];
                }
                message.sourceApplicationObjectCategories.push(
                  $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ValidTriggerEvents) {
            return object;
          }
          const message = new $root.workflows.v1.ValidTriggerEvents();
          if (object.sourceApplicationObjectCategories) {
            if (!Array.isArray(object.sourceApplicationObjectCategories)) {
              throw new TypeError(
                '.workflows.v1.ValidTriggerEvents.sourceApplicationObjectCategories: array type expected, but got ' +
                  typeof object.sourceApplicationObjectCategories
              );
            }
            message.sourceApplicationObjectCategories = new Array(
              object.sourceApplicationObjectCategories.length
            );
            for (
              let i = 0;
              i < object.sourceApplicationObjectCategories.length;
              ++i
            ) {
              if (
                typeof object.sourceApplicationObjectCategories[i] !== 'object'
              ) {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.sourceApplicationObjectCategories: object expected, but got ' +
                    typeof object.sourceApplicationObjectCategories[i]
                );
              }
              message.sourceApplicationObjectCategories[i] =
                $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.fromObject(
                  object.sourceApplicationObjectCategories[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.sourceApplicationObjectCategories = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.sourceApplicationObjectCategories &&
            message.sourceApplicationObjectCategories.length
          ) {
            object.sourceApplicationObjectCategories = new Array(
              message.sourceApplicationObjectCategories.length
            );
            for (
              let i = 0;
              i < message.sourceApplicationObjectCategories.length;
              ++i
            ) {
              object.sourceApplicationObjectCategories[i] =
                $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.toObject(
                  message.sourceApplicationObjectCategories[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ValidTriggerEvents';
        }
      }

      ValidTriggerEvents.prototype.sourceApplicationObjectCategories =
        $util.emptyArray;
      ValidTriggerEvents.SourceApplicationObjectCategories = (() => {
        class SourceApplicationObjectCategories {
          constructor(properties) {
            this.objectCategoryEventPayloads = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SourceApplicationObjectCategories(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.sourceApplication != null &&
              Object.hasOwnProperty.call(message, 'sourceApplication')
            ) {
              writer.uint32(8).int32(message.sourceApplication);
            }
            if (
              message.objectCategoryEventPayloads != null &&
              Object.hasOwnProperty.call(message, 'objectCategoryEventPayloads')
            ) {
              for (const element of message.objectCategoryEventPayloads) {
                $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.sourceAlreadyExists != null &&
              Object.hasOwnProperty.call(message, 'sourceAlreadyExists')
            ) {
              writer.uint32(24).bool(message.sourceAlreadyExists);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SourceApplicationObjectCategories();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sourceApplication = reader.int32();
                  break;
                }
                case 2: {
                  if (
                    !message.objectCategoryEventPayloads ||
                    !message.objectCategoryEventPayloads.length
                  ) {
                    message.objectCategoryEventPayloads = [];
                  }
                  message.objectCategoryEventPayloads.push(
                    $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 3: {
                  message.sourceAlreadyExists = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.ValidTriggerEvents
                .SourceApplicationObjectCategories
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories();
            if (object.sourceApplication != null) {
              switch (object.sourceApplication) {
                case 'UNSPECIFIED_SOURCE_APPLICATION':
                case 0: {
                  message.sourceApplication = 0;
                  break;
                }
                case 'FORMSORT':
                case 1: {
                  message.sourceApplication = 1;
                  break;
                }
                case 'HEALTHIE':
                case 2: {
                  message.sourceApplication = 2;
                  break;
                }
                case 'AXLE_HEALTH':
                case 3: {
                  message.sourceApplication = 3;
                  break;
                }
                case 'BUTTERFLY_LABS':
                case 4: {
                  message.sourceApplication = 4;
                  break;
                }
                case 'MORF':
                case 5: {
                  message.sourceApplication = 5;
                  break;
                }
                case 'PUSH_JSON':
                case 6: {
                  message.sourceApplication = 6;
                  break;
                }
                case 'ACUITY':
                case 7: {
                  message.sourceApplication = 7;
                  break;
                }
                case 'VITAL':
                case 8: {
                  message.sourceApplication = 8;
                  break;
                }
                case 'SEGMENT':
                case 9: {
                  message.sourceApplication = 9;
                  break;
                }
                case 'ACTIVE_CAMPAIGN':
                case 10: {
                  message.sourceApplication = 10;
                  break;
                }
                case 'INTAKEQ':
                case 11: {
                  message.sourceApplication = 11;
                  break;
                }
                case 'CUSTOMER_IO':
                case 12: {
                  message.sourceApplication = 12;
                  break;
                }
                case 'HUBSPOT':
                case 13: {
                  message.sourceApplication = 13;
                  break;
                }
                default: {
                  if (typeof object.sourceApplication == 'number') {
                    message.sourceApplication = object.sourceApplication;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.objectCategoryEventPayloads) {
              if (!Array.isArray(object.objectCategoryEventPayloads)) {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.objectCategoryEventPayloads: array type expected, but got ' +
                    typeof object.objectCategoryEventPayloads
                );
              }
              message.objectCategoryEventPayloads = new Array(
                object.objectCategoryEventPayloads.length
              );
              for (
                let i = 0;
                i < object.objectCategoryEventPayloads.length;
                ++i
              ) {
                if (typeof object.objectCategoryEventPayloads[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories.objectCategoryEventPayloads: object expected, but got ' +
                      typeof object.objectCategoryEventPayloads[i]
                  );
                }
                message.objectCategoryEventPayloads[i] =
                  $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.fromObject(
                    object.objectCategoryEventPayloads[i]
                  );
              }
            }
            if (object.sourceAlreadyExists != null) {
              message.sourceAlreadyExists = Boolean(object.sourceAlreadyExists);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.objectCategoryEventPayloads = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.sourceApplication =
                options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
              object.sourceAlreadyExists = false;
            }
            let keys;
            if (
              message.sourceApplication != null &&
              message.hasOwnProperty('sourceApplication')
            ) {
              object.sourceApplication =
                options.enums === String
                  ? source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ] === undefined
                    ? message.sourceApplication
                    : source_application$1.v2.SourceApplication.Application[
                        message.sourceApplication
                      ]
                  : message.sourceApplication;
            }
            if (
              message.objectCategoryEventPayloads &&
              message.objectCategoryEventPayloads.length
            ) {
              object.objectCategoryEventPayloads = new Array(
                message.objectCategoryEventPayloads.length
              );
              for (
                let i = 0;
                i < message.objectCategoryEventPayloads.length;
                ++i
              ) {
                object.objectCategoryEventPayloads[i] =
                  $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.toObject(
                    message.objectCategoryEventPayloads[i],
                    options
                  );
              }
            }
            if (
              message.sourceAlreadyExists != null &&
              message.hasOwnProperty('sourceAlreadyExists')
            ) {
              object.sourceAlreadyExists = message.sourceAlreadyExists;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.ValidTriggerEvents.SourceApplicationObjectCategories'
            );
          }
        }

        SourceApplicationObjectCategories.prototype.sourceApplication = 0;
        SourceApplicationObjectCategories.prototype.objectCategoryEventPayloads =
          $util.emptyArray;
        SourceApplicationObjectCategories.prototype.sourceAlreadyExists = false;

        return SourceApplicationObjectCategories;
      })();

      ValidTriggerEvents.ObjectCategoryEventPayloads = (() => {
        class ObjectCategoryEventPayloads {
          constructor(properties) {
            this.eventPayloadEventTypes = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ObjectCategoryEventPayloads(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.objectCategory != null &&
              Object.hasOwnProperty.call(message, 'objectCategory')
            ) {
              writer.uint32(8).int32(message.objectCategory);
            }
            if (
              message.eventPayloadEventTypes != null &&
              Object.hasOwnProperty.call(message, 'eventPayloadEventTypes')
            ) {
              for (const element of message.eventPayloadEventTypes) {
                $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ObjectCategoryEventPayloads();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.objectCategory = reader.int32();
                  break;
                }
                case 2: {
                  if (
                    !message.eventPayloadEventTypes ||
                    !message.eventPayloadEventTypes.length
                  ) {
                    message.eventPayloadEventTypes = [];
                  }
                  message.eventPayloadEventTypes.push(
                    $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads();
            if (object.objectCategory != null) {
              switch (object.objectCategory) {
                case 'UNSPECIFIED_OBJECT_CATEGORY':
                case 0: {
                  message.objectCategory = 0;
                  break;
                }
                case 'FORM':
                case 1: {
                  message.objectCategory = 1;
                  break;
                }
                case 'APPOINTMENT':
                case 2: {
                  message.objectCategory = 2;
                  break;
                }
                case 'PATIENT':
                case 3: {
                  message.objectCategory = 3;
                  break;
                }
                case 'LAB_ORDER':
                case 4: {
                  message.objectCategory = 4;
                  break;
                }
                default: {
                  if (typeof object.objectCategory == 'number') {
                    message.objectCategory = object.objectCategory;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.eventPayloadEventTypes) {
              if (!Array.isArray(object.eventPayloadEventTypes)) {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.eventPayloadEventTypes: array type expected, but got ' +
                    typeof object.eventPayloadEventTypes
                );
              }
              message.eventPayloadEventTypes = new Array(
                object.eventPayloadEventTypes.length
              );
              for (let i = 0; i < object.eventPayloadEventTypes.length; ++i) {
                if (typeof object.eventPayloadEventTypes[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads.eventPayloadEventTypes: object expected, but got ' +
                      typeof object.eventPayloadEventTypes[i]
                  );
                }
                message.eventPayloadEventTypes[i] =
                  $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.fromObject(
                    object.eventPayloadEventTypes[i]
                  );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.eventPayloadEventTypes = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.objectCategory =
                options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
            }
            let keys;
            if (
              message.objectCategory != null &&
              message.hasOwnProperty('objectCategory')
            ) {
              object.objectCategory =
                options.enums === String
                  ? object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ] === undefined
                    ? message.objectCategory
                    : object_category$1.v1.ObjectCategory.Category[
                        message.objectCategory
                      ]
                  : message.objectCategory;
            }
            if (
              message.eventPayloadEventTypes &&
              message.eventPayloadEventTypes.length
            ) {
              object.eventPayloadEventTypes = new Array(
                message.eventPayloadEventTypes.length
              );
              for (let i = 0; i < message.eventPayloadEventTypes.length; ++i) {
                object.eventPayloadEventTypes[i] =
                  $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.toObject(
                    message.eventPayloadEventTypes[i],
                    options
                  );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.ValidTriggerEvents.ObjectCategoryEventPayloads'
            );
          }
        }

        ObjectCategoryEventPayloads.prototype.objectCategory = 0;
        ObjectCategoryEventPayloads.prototype.eventPayloadEventTypes =
          $util.emptyArray;

        return ObjectCategoryEventPayloads;
      })();

      ValidTriggerEvents.EventPayloadEventTypes = (() => {
        class EventPayloadEventTypes {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EventPayloadEventTypes(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.validPayloadEventTypes != null &&
              Object.hasOwnProperty.call(message, 'validPayloadEventTypes')
            ) {
              event_payload$1.v2.EventTypes.encode(
                message.validPayloadEventTypes,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.payloadName != null &&
              Object.hasOwnProperty.call(message, 'payloadName')
            ) {
              writer.uint32(18).string(message.payloadName);
            }
            if (
              message.payloadStub != null &&
              Object.hasOwnProperty.call(message, 'payloadStub')
            ) {
              event_payload$1.v2.EventPayload.encode(
                message.payloadStub,
                writer.uint32(26).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EventPayloadEventTypes();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.validPayloadEventTypes =
                    event_payload$1.v2.EventTypes.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.payloadName = reader.string();
                  break;
                }
                case 3: {
                  message.payloadStub = event_payload$1.v2.EventPayload.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes();
            if (object.validPayloadEventTypes != null) {
              if (typeof object.validPayloadEventTypes !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.validPayloadEventTypes: object expected, but got ' +
                    typeof object.validPayloadEventTypes
                );
              }
              message.validPayloadEventTypes =
                event_payload$1.v2.EventTypes.fromObject(
                  object.validPayloadEventTypes
                );
            }
            if (object.payloadName != null) {
              message.payloadName = String(object.payloadName);
            }
            if (object.payloadStub != null) {
              if (typeof object.payloadStub !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ValidTriggerEvents.EventPayloadEventTypes.payloadStub: object expected, but got ' +
                    typeof object.payloadStub
                );
              }
              message.payloadStub = event_payload$1.v2.EventPayload.fromObject(
                object.payloadStub
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.validPayloadEventTypes = null;
              object.payloadName = '';
              object.payloadStub = null;
            }
            let keys;
            if (
              message.validPayloadEventTypes != null &&
              message.hasOwnProperty('validPayloadEventTypes')
            ) {
              object.validPayloadEventTypes =
                event_payload$1.v2.EventTypes.toObject(
                  message.validPayloadEventTypes,
                  options
                );
            }
            if (
              message.payloadName != null &&
              message.hasOwnProperty('payloadName')
            ) {
              object.payloadName = message.payloadName;
            }
            if (
              message.payloadStub != null &&
              message.hasOwnProperty('payloadStub')
            ) {
              object.payloadStub = event_payload$1.v2.EventPayload.toObject(
                message.payloadStub,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.ValidTriggerEvents.EventPayloadEventTypes'
            );
          }
        }

        EventPayloadEventTypes.prototype.validPayloadEventTypes = null;
        EventPayloadEventTypes.prototype.payloadName = '';
        EventPayloadEventTypes.prototype.payloadStub = null;

        return EventPayloadEventTypes;
      })();

      return ValidTriggerEvents;
    })();

    v1.WorkflowEdge = (() => {
      class WorkflowEdge {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEdge(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.from != null &&
            Object.hasOwnProperty.call(message, 'from')
          ) {
            writer.uint32(10).string(message.from);
          }
          if (message.to != null && Object.hasOwnProperty.call(message, 'to')) {
            writer.uint32(18).string(message.to);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEdge();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.from = reader.string();
                break;
              }
              case 2: {
                message.to = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowEdge) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowEdge();
          if (object.from != null) {
            message.from = String(object.from);
          }
          if (object.to != null) {
            message.to = String(object.to);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.from = '';
            object.to = '';
          }
          let keys;
          if (message.from != null && message.hasOwnProperty('from')) {
            object.from = message.from;
          }
          if (message.to != null && message.hasOwnProperty('to')) {
            object.to = message.to;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowEdge';
        }
      }

      WorkflowEdge.prototype.from = '';
      WorkflowEdge.prototype.to = '';

      return WorkflowEdge;
    })();

    v1.WorkflowNode = (() => {
      class WorkflowNode {
        get type() {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'destinationAction',
            'filter',
            'pause',
            'fetchAction',
            'restart',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.nodeSpecificEventPayloadFieldSchemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowNode(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (message.id != null && Object.hasOwnProperty.call(message, 'id')) {
            writer.uint32(10).string(message.id);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.description != null &&
            Object.hasOwnProperty.call(message, 'description')
          ) {
            writer.uint32(26).string(message.description);
          }
          if (
            message.destinationAction != null &&
            Object.hasOwnProperty.call(message, 'destinationAction')
          ) {
            $root.workflows.v1.WorkflowDestinationAction.encode(
              message.destinationAction,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.filter != null &&
            Object.hasOwnProperty.call(message, 'filter')
          ) {
            $root.workflows.v1.WorkflowFilter.encode(
              message.filter,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.pause != null &&
            Object.hasOwnProperty.call(message, 'pause')
          ) {
            $root.workflows.v1.WorkflowPause.encode(
              message.pause,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.fetchAction != null &&
            Object.hasOwnProperty.call(message, 'fetchAction')
          ) {
            $root.workflows.v1.WorkflowFetchAction.encode(
              message.fetchAction,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.restart != null &&
            Object.hasOwnProperty.call(message, 'restart')
          ) {
            $root.workflows.v1.WorkflowRestart.encode(
              message.restart,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.nodeSpecificEventPayloadFieldSchemas != null &&
            Object.hasOwnProperty.call(
              message,
              'nodeSpecificEventPayloadFieldSchemas'
            )
          ) {
            for (const element of message.nodeSpecificEventPayloadFieldSchemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(74).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowNode();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.id = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.description = reader.string();
                break;
              }
              case 4: {
                message.destinationAction =
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.filter = $root.workflows.v1.WorkflowFilter.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.pause = $root.workflows.v1.WorkflowPause.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.fetchAction =
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.restart = $root.workflows.v1.WorkflowRestart.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                if (
                  !message.nodeSpecificEventPayloadFieldSchemas ||
                  !message.nodeSpecificEventPayloadFieldSchemas.length
                ) {
                  message.nodeSpecificEventPayloadFieldSchemas = [];
                }
                message.nodeSpecificEventPayloadFieldSchemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowNode) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowNode();
          if (object.id != null) {
            message.id = String(object.id);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.description != null) {
            message.description = String(object.description);
          }
          if (object.destinationAction != null) {
            if (typeof object.destinationAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.destinationAction: object expected, but got ' +
                  typeof object.destinationAction
              );
            }
            message.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.fromObject(
                object.destinationAction
              );
          }
          if (object.filter != null) {
            if (typeof object.filter !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.filter: object expected, but got ' +
                  typeof object.filter
              );
            }
            message.filter = $root.workflows.v1.WorkflowFilter.fromObject(
              object.filter
            );
          }
          if (object.pause != null) {
            if (typeof object.pause !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.pause: object expected, but got ' +
                  typeof object.pause
              );
            }
            message.pause = $root.workflows.v1.WorkflowPause.fromObject(
              object.pause
            );
          }
          if (object.fetchAction != null) {
            if (typeof object.fetchAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.fetchAction: object expected, but got ' +
                  typeof object.fetchAction
              );
            }
            message.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.fromObject(
                object.fetchAction
              );
          }
          if (object.restart != null) {
            if (typeof object.restart !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.restart: object expected, but got ' +
                  typeof object.restart
              );
            }
            message.restart = $root.workflows.v1.WorkflowRestart.fromObject(
              object.restart
            );
          }
          if (object.nodeSpecificEventPayloadFieldSchemas) {
            if (!Array.isArray(object.nodeSpecificEventPayloadFieldSchemas)) {
              throw new TypeError(
                '.workflows.v1.WorkflowNode.nodeSpecificEventPayloadFieldSchemas: array type expected, but got ' +
                  typeof object.nodeSpecificEventPayloadFieldSchemas
              );
            }
            message.nodeSpecificEventPayloadFieldSchemas = new Array(
              object.nodeSpecificEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < object.nodeSpecificEventPayloadFieldSchemas.length;
              ++i
            ) {
              if (
                typeof object.nodeSpecificEventPayloadFieldSchemas[i] !==
                'object'
              ) {
                throw new TypeError(
                  '.workflows.v1.WorkflowNode.nodeSpecificEventPayloadFieldSchemas: object expected, but got ' +
                    typeof object.nodeSpecificEventPayloadFieldSchemas[i]
                );
              }
              message.nodeSpecificEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.nodeSpecificEventPayloadFieldSchemas[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.nodeSpecificEventPayloadFieldSchemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.id = '';
          }
          let keys;
          if (message.id != null && message.hasOwnProperty('id')) {
            object.id = message.id;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.description != null &&
            message.hasOwnProperty('description')
          ) {
            object.description = message.description;
          }
          if (
            message.destinationAction != null &&
            message.hasOwnProperty('destinationAction')
          ) {
            object.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.toObject(
                message.destinationAction,
                options
              );
            if (options.oneofs) {
              object.type = 'destinationAction';
            }
          }
          if (message.filter != null && message.hasOwnProperty('filter')) {
            object.filter = $root.workflows.v1.WorkflowFilter.toObject(
              message.filter,
              options
            );
            if (options.oneofs) {
              object.type = 'filter';
            }
          }
          if (message.pause != null && message.hasOwnProperty('pause')) {
            object.pause = $root.workflows.v1.WorkflowPause.toObject(
              message.pause,
              options
            );
            if (options.oneofs) {
              object.type = 'pause';
            }
          }
          if (
            message.fetchAction != null &&
            message.hasOwnProperty('fetchAction')
          ) {
            object.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.toObject(
                message.fetchAction,
                options
              );
            if (options.oneofs) {
              object.type = 'fetchAction';
            }
          }
          if (message.restart != null && message.hasOwnProperty('restart')) {
            object.restart = $root.workflows.v1.WorkflowRestart.toObject(
              message.restart,
              options
            );
            if (options.oneofs) {
              object.type = 'restart';
            }
          }
          if (
            message.nodeSpecificEventPayloadFieldSchemas &&
            message.nodeSpecificEventPayloadFieldSchemas.length
          ) {
            object.nodeSpecificEventPayloadFieldSchemas = new Array(
              message.nodeSpecificEventPayloadFieldSchemas.length
            );
            for (
              let i = 0;
              i < message.nodeSpecificEventPayloadFieldSchemas.length;
              ++i
            ) {
              object.nodeSpecificEventPayloadFieldSchemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.nodeSpecificEventPayloadFieldSchemas[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowNode';
        }
      }

      WorkflowNode.prototype.id = '';
      WorkflowNode.prototype.name = null;
      WorkflowNode.prototype.description = null;
      WorkflowNode.prototype.destinationAction = null;
      WorkflowNode.prototype.filter = null;
      WorkflowNode.prototype.pause = null;
      WorkflowNode.prototype.fetchAction = null;
      WorkflowNode.prototype.restart = null;
      WorkflowNode.prototype.nodeSpecificEventPayloadFieldSchemas =
        $util.emptyArray;

      return WorkflowNode;
    })();

    v1.WorkflowNodes = (() => {
      class WorkflowNodes {
        constructor(properties) {
          this.nodes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowNodes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.nodes != null &&
            Object.hasOwnProperty.call(message, 'nodes')
          ) {
            for (const element of message.nodes) {
              $root.workflows.v1.WorkflowNode.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowNodes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.nodes || !message.nodes.length) {
                  message.nodes = [];
                }
                message.nodes.push(
                  $root.workflows.v1.WorkflowNode.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowNodes) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowNodes();
          if (object.nodes) {
            if (!Array.isArray(object.nodes)) {
              throw new TypeError(
                '.workflows.v1.WorkflowNodes.nodes: array type expected, but got ' +
                  typeof object.nodes
              );
            }
            message.nodes = new Array(object.nodes.length);
            for (let i = 0; i < object.nodes.length; ++i) {
              if (typeof object.nodes[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowNodes.nodes: object expected, but got ' +
                    typeof object.nodes[i]
                );
              }
              message.nodes[i] = $root.workflows.v1.WorkflowNode.fromObject(
                object.nodes[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.nodes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.nodes && message.nodes.length) {
            object.nodes = new Array(message.nodes.length);
            for (let i = 0; i < message.nodes.length; ++i) {
              object.nodes[i] = $root.workflows.v1.WorkflowNode.toObject(
                message.nodes[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowNodes';
        }
      }

      WorkflowNodes.prototype.nodes = $util.emptyArray;

      return WorkflowNodes;
    })();

    v1.WorkflowEdges = (() => {
      class WorkflowEdges {
        constructor(properties) {
          this.edges = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEdges(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.edges != null &&
            Object.hasOwnProperty.call(message, 'edges')
          ) {
            for (const element of message.edges) {
              $root.workflows.v1.WorkflowEdge.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEdges();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.edges || !message.edges.length) {
                  message.edges = [];
                }
                message.edges.push(
                  $root.workflows.v1.WorkflowEdge.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowEdges) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowEdges();
          if (object.edges) {
            if (!Array.isArray(object.edges)) {
              throw new TypeError(
                '.workflows.v1.WorkflowEdges.edges: array type expected, but got ' +
                  typeof object.edges
              );
            }
            message.edges = new Array(object.edges.length);
            for (let i = 0; i < object.edges.length; ++i) {
              if (typeof object.edges[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowEdges.edges: object expected, but got ' +
                    typeof object.edges[i]
                );
              }
              message.edges[i] = $root.workflows.v1.WorkflowEdge.fromObject(
                object.edges[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.edges = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.edges && message.edges.length) {
            object.edges = new Array(message.edges.length);
            for (let i = 0; i < message.edges.length; ++i) {
              object.edges[i] = $root.workflows.v1.WorkflowEdge.toObject(
                message.edges[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowEdges';
        }
      }

      WorkflowEdges.prototype.edges = $util.emptyArray;

      return WorkflowEdges;
    })();

    v1.WorkflowDestinationAction = (() => {
      class WorkflowDestinationAction {
        get type() {
          for (const key of [
            'activeCampaignV1',
            'customerIoV1',
            'facebookV1',
            'googleV1',
            'healthieV1',
            'morfV1',
            'intakeqV1',
            'segmentV1',
            'spruceV1',
            'slackV1',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'activeCampaignV1',
            'customerIoV1',
            'facebookV1',
            'googleV1',
            'healthieV1',
            'morfV1',
            'intakeqV1',
            'segmentV1',
            'spruceV1',
            'slackV1',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowDestinationAction(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.parameterMissingPolicy != null &&
            Object.hasOwnProperty.call(message, 'parameterMissingPolicy')
          ) {
            workflow_parameters$1.v1.ParameterMissingPolicy.encode(
              message.parameterMissingPolicy,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.retryPolicy != null &&
            Object.hasOwnProperty.call(message, 'retryPolicy')
          ) {
            workflow_parameters$1.v1.DestinationActionRetryPolicy.encode(
              message.retryPolicy,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            workflow_destination_applications$1.v1.DestinationApplication.encode(
              message.application,
              writer.uint32(114).fork()
            ).ldelim();
          }
          if (
            message.activeCampaignV1 != null &&
            Object.hasOwnProperty.call(message, 'activeCampaignV1')
          ) {
            destinationactions$1.activecampaign.v1.DestinationAction.encode(
              message.activeCampaignV1,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.customerIoV1 != null &&
            Object.hasOwnProperty.call(message, 'customerIoV1')
          ) {
            destinationactions$2.customerio.v1.DestinationAction.encode(
              message.customerIoV1,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.facebookV1 != null &&
            Object.hasOwnProperty.call(message, 'facebookV1')
          ) {
            destinationactions$3.facebook.v1.DestinationAction.encode(
              message.facebookV1,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.googleV1 != null &&
            Object.hasOwnProperty.call(message, 'googleV1')
          ) {
            destinationactions$4.google.v1.DestinationAction.encode(
              message.googleV1,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.healthieV1 != null &&
            Object.hasOwnProperty.call(message, 'healthieV1')
          ) {
            destinationactions$5.healthie.v1.DestinationAction.encode(
              message.healthieV1,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.morfV1 != null &&
            Object.hasOwnProperty.call(message, 'morfV1')
          ) {
            destinationactions$6.morf.v1.DestinationAction.encode(
              message.morfV1,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.intakeqV1 != null &&
            Object.hasOwnProperty.call(message, 'intakeqV1')
          ) {
            destinationactions$7.intakeq.v1.DestinationAction.encode(
              message.intakeqV1,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.segmentV1 != null &&
            Object.hasOwnProperty.call(message, 'segmentV1')
          ) {
            destinationactions$8.segment.v1.DestinationAction.encode(
              message.segmentV1,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.spruceV1 != null &&
            Object.hasOwnProperty.call(message, 'spruceV1')
          ) {
            destinationactions$9.spruce.v1.DestinationAction.encode(
              message.spruceV1,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.slackV1 != null &&
            Object.hasOwnProperty.call(message, 'slackV1')
          ) {
            destinationactions$10.slack.v1.DestinationAction.encode(
              message.slackV1,
              writer.uint32(98).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowDestinationAction();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.parameterMissingPolicy =
                  workflow_parameters$1.v1.ParameterMissingPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.retryPolicy =
                  workflow_parameters$1.v1.DestinationActionRetryPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.application =
                  workflow_destination_applications$1.v1.DestinationApplication.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.activeCampaignV1 =
                  destinationactions$1.activecampaign.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.customerIoV1 =
                  destinationactions$2.customerio.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.facebookV1 =
                  destinationactions$3.facebook.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.googleV1 =
                  destinationactions$4.google.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.healthieV1 =
                  destinationactions$5.healthie.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.morfV1 =
                  destinationactions$6.morf.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.intakeqV1 =
                  destinationactions$7.intakeq.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 10: {
                message.segmentV1 =
                  destinationactions$8.segment.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.spruceV1 =
                  destinationactions$9.spruce.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 12: {
                message.slackV1 =
                  destinationactions$10.slack.v1.DestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowDestinationAction) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowDestinationAction();
          if (object.parameterMissingPolicy != null) {
            if (typeof object.parameterMissingPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.parameterMissingPolicy: object expected, but got ' +
                  typeof object.parameterMissingPolicy
              );
            }
            message.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.fromObject(
                object.parameterMissingPolicy
              );
          }
          if (object.retryPolicy != null) {
            if (typeof object.retryPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.retryPolicy: object expected, but got ' +
                  typeof object.retryPolicy
              );
            }
            message.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.fromObject(
                object.retryPolicy
              );
          }
          if (object.application != null) {
            if (typeof object.application !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.application: object expected, but got ' +
                  typeof object.application
              );
            }
            message.application =
              workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                object.application
              );
          }
          if (object.activeCampaignV1 != null) {
            if (typeof object.activeCampaignV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.activeCampaignV1: object expected, but got ' +
                  typeof object.activeCampaignV1
              );
            }
            message.activeCampaignV1 =
              destinationactions$1.activecampaign.v1.DestinationAction.fromObject(
                object.activeCampaignV1
              );
          }
          if (object.customerIoV1 != null) {
            if (typeof object.customerIoV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.customerIoV1: object expected, but got ' +
                  typeof object.customerIoV1
              );
            }
            message.customerIoV1 =
              destinationactions$2.customerio.v1.DestinationAction.fromObject(
                object.customerIoV1
              );
          }
          if (object.facebookV1 != null) {
            if (typeof object.facebookV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.facebookV1: object expected, but got ' +
                  typeof object.facebookV1
              );
            }
            message.facebookV1 =
              destinationactions$3.facebook.v1.DestinationAction.fromObject(
                object.facebookV1
              );
          }
          if (object.googleV1 != null) {
            if (typeof object.googleV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.googleV1: object expected, but got ' +
                  typeof object.googleV1
              );
            }
            message.googleV1 =
              destinationactions$4.google.v1.DestinationAction.fromObject(
                object.googleV1
              );
          }
          if (object.healthieV1 != null) {
            if (typeof object.healthieV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.healthieV1: object expected, but got ' +
                  typeof object.healthieV1
              );
            }
            message.healthieV1 =
              destinationactions$5.healthie.v1.DestinationAction.fromObject(
                object.healthieV1
              );
          }
          if (object.morfV1 != null) {
            if (typeof object.morfV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.morfV1: object expected, but got ' +
                  typeof object.morfV1
              );
            }
            message.morfV1 =
              destinationactions$6.morf.v1.DestinationAction.fromObject(
                object.morfV1
              );
          }
          if (object.intakeqV1 != null) {
            if (typeof object.intakeqV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.intakeqV1: object expected, but got ' +
                  typeof object.intakeqV1
              );
            }
            message.intakeqV1 =
              destinationactions$7.intakeq.v1.DestinationAction.fromObject(
                object.intakeqV1
              );
          }
          if (object.segmentV1 != null) {
            if (typeof object.segmentV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.segmentV1: object expected, but got ' +
                  typeof object.segmentV1
              );
            }
            message.segmentV1 =
              destinationactions$8.segment.v1.DestinationAction.fromObject(
                object.segmentV1
              );
          }
          if (object.spruceV1 != null) {
            if (typeof object.spruceV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.spruceV1: object expected, but got ' +
                  typeof object.spruceV1
              );
            }
            message.spruceV1 =
              destinationactions$9.spruce.v1.DestinationAction.fromObject(
                object.spruceV1
              );
          }
          if (object.slackV1 != null) {
            if (typeof object.slackV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowDestinationAction.slackV1: object expected, but got ' +
                  typeof object.slackV1
              );
            }
            message.slackV1 =
              destinationactions$10.slack.v1.DestinationAction.fromObject(
                object.slackV1
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.parameterMissingPolicy = null;
            object.retryPolicy = null;
            object.application = null;
          }
          let keys;
          if (
            message.parameterMissingPolicy != null &&
            message.hasOwnProperty('parameterMissingPolicy')
          ) {
            object.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.toObject(
                message.parameterMissingPolicy,
                options
              );
          }
          if (
            message.retryPolicy != null &&
            message.hasOwnProperty('retryPolicy')
          ) {
            object.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.toObject(
                message.retryPolicy,
                options
              );
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              workflow_destination_applications$1.v1.DestinationApplication.toObject(
                message.application,
                options
              );
          }
          if (
            message.activeCampaignV1 != null &&
            message.hasOwnProperty('activeCampaignV1')
          ) {
            object.activeCampaignV1 =
              destinationactions$1.activecampaign.v1.DestinationAction.toObject(
                message.activeCampaignV1,
                options
              );
            if (options.oneofs) {
              object.type = 'activeCampaignV1';
            }
          }
          if (
            message.customerIoV1 != null &&
            message.hasOwnProperty('customerIoV1')
          ) {
            object.customerIoV1 =
              destinationactions$2.customerio.v1.DestinationAction.toObject(
                message.customerIoV1,
                options
              );
            if (options.oneofs) {
              object.type = 'customerIoV1';
            }
          }
          if (
            message.facebookV1 != null &&
            message.hasOwnProperty('facebookV1')
          ) {
            object.facebookV1 =
              destinationactions$3.facebook.v1.DestinationAction.toObject(
                message.facebookV1,
                options
              );
            if (options.oneofs) {
              object.type = 'facebookV1';
            }
          }
          if (message.googleV1 != null && message.hasOwnProperty('googleV1')) {
            object.googleV1 =
              destinationactions$4.google.v1.DestinationAction.toObject(
                message.googleV1,
                options
              );
            if (options.oneofs) {
              object.type = 'googleV1';
            }
          }
          if (
            message.healthieV1 != null &&
            message.hasOwnProperty('healthieV1')
          ) {
            object.healthieV1 =
              destinationactions$5.healthie.v1.DestinationAction.toObject(
                message.healthieV1,
                options
              );
            if (options.oneofs) {
              object.type = 'healthieV1';
            }
          }
          if (message.morfV1 != null && message.hasOwnProperty('morfV1')) {
            object.morfV1 =
              destinationactions$6.morf.v1.DestinationAction.toObject(
                message.morfV1,
                options
              );
            if (options.oneofs) {
              object.type = 'morfV1';
            }
          }
          if (
            message.intakeqV1 != null &&
            message.hasOwnProperty('intakeqV1')
          ) {
            object.intakeqV1 =
              destinationactions$7.intakeq.v1.DestinationAction.toObject(
                message.intakeqV1,
                options
              );
            if (options.oneofs) {
              object.type = 'intakeqV1';
            }
          }
          if (
            message.segmentV1 != null &&
            message.hasOwnProperty('segmentV1')
          ) {
            object.segmentV1 =
              destinationactions$8.segment.v1.DestinationAction.toObject(
                message.segmentV1,
                options
              );
            if (options.oneofs) {
              object.type = 'segmentV1';
            }
          }
          if (message.spruceV1 != null && message.hasOwnProperty('spruceV1')) {
            object.spruceV1 =
              destinationactions$9.spruce.v1.DestinationAction.toObject(
                message.spruceV1,
                options
              );
            if (options.oneofs) {
              object.type = 'spruceV1';
            }
          }
          if (message.slackV1 != null && message.hasOwnProperty('slackV1')) {
            object.slackV1 =
              destinationactions$10.slack.v1.DestinationAction.toObject(
                message.slackV1,
                options
              );
            if (options.oneofs) {
              object.type = 'slackV1';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowDestinationAction';
        }
      }

      WorkflowDestinationAction.prototype.parameterMissingPolicy = null;
      WorkflowDestinationAction.prototype.retryPolicy = null;
      WorkflowDestinationAction.prototype.application = null;
      WorkflowDestinationAction.prototype.activeCampaignV1 = null;
      WorkflowDestinationAction.prototype.customerIoV1 = null;
      WorkflowDestinationAction.prototype.facebookV1 = null;
      WorkflowDestinationAction.prototype.googleV1 = null;
      WorkflowDestinationAction.prototype.healthieV1 = null;
      WorkflowDestinationAction.prototype.morfV1 = null;
      WorkflowDestinationAction.prototype.intakeqV1 = null;
      WorkflowDestinationAction.prototype.segmentV1 = null;
      WorkflowDestinationAction.prototype.spruceV1 = null;
      WorkflowDestinationAction.prototype.slackV1 = null;

      return WorkflowDestinationAction;
    })();

    v1.WorkflowRestart = (() => {
      class WorkflowRestart {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowRestart(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowRestart();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowRestart) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowRestart();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowRestart';
        }
      }

      return WorkflowRestart;
    })();

    v1.WorkflowFetchAction = (() => {
      class WorkflowFetchAction {
        get type() {
          for (const key of [
            'healthieV1',
            'freshdeskV1',
            'morfV1',
            'hubspotV1',
            'intercomV1',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'healthieV1',
            'freshdeskV1',
            'morfV1',
            'hubspotV1',
            'intercomV1',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowFetchAction(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.resultObjectKey != null &&
            Object.hasOwnProperty.call(message, 'resultObjectKey')
          ) {
            writer.uint32(10).string(message.resultObjectKey);
          }
          if (
            message.parameterMissingPolicy != null &&
            Object.hasOwnProperty.call(message, 'parameterMissingPolicy')
          ) {
            workflow_parameters$1.v1.ParameterMissingPolicy.encode(
              message.parameterMissingPolicy,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.retryPolicy != null &&
            Object.hasOwnProperty.call(message, 'retryPolicy')
          ) {
            workflow_parameters$1.v1.DestinationActionRetryPolicy.encode(
              message.retryPolicy,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            workflow_destination_applications$1.v1.DestinationApplication.encode(
              message.application,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.healthieV1 != null &&
            Object.hasOwnProperty.call(message, 'healthieV1')
          ) {
            fetchactions$1.healthie.v1.FetchAction.encode(
              message.healthieV1,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.freshdeskV1 != null &&
            Object.hasOwnProperty.call(message, 'freshdeskV1')
          ) {
            fetchactions$2.freshdesk.v1.FetchAction.encode(
              message.freshdeskV1,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.morfV1 != null &&
            Object.hasOwnProperty.call(message, 'morfV1')
          ) {
            fetchactions$3.morf.v1.FetchAction.encode(
              message.morfV1,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.hubspotV1 != null &&
            Object.hasOwnProperty.call(message, 'hubspotV1')
          ) {
            fetchactions$4.hubspot.v1.FetchAction.encode(
              message.hubspotV1,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.intercomV1 != null &&
            Object.hasOwnProperty.call(message, 'intercomV1')
          ) {
            fetchactions$5.intercom.v1.FetchAction.encode(
              message.intercomV1,
              writer.uint32(74).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowFetchAction();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.resultObjectKey = reader.string();
                break;
              }
              case 2: {
                message.parameterMissingPolicy =
                  workflow_parameters$1.v1.ParameterMissingPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.retryPolicy =
                  workflow_parameters$1.v1.DestinationActionRetryPolicy.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.application =
                  workflow_destination_applications$1.v1.DestinationApplication.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.healthieV1 =
                  fetchactions$1.healthie.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.freshdeskV1 =
                  fetchactions$2.freshdesk.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.morfV1 = fetchactions$3.morf.v1.FetchAction.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.hubspotV1 =
                  fetchactions$4.hubspot.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 9: {
                message.intercomV1 =
                  fetchactions$5.intercom.v1.FetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowFetchAction) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowFetchAction();
          if (object.resultObjectKey != null) {
            message.resultObjectKey = String(object.resultObjectKey);
          }
          if (object.parameterMissingPolicy != null) {
            if (typeof object.parameterMissingPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.parameterMissingPolicy: object expected, but got ' +
                  typeof object.parameterMissingPolicy
              );
            }
            message.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.fromObject(
                object.parameterMissingPolicy
              );
          }
          if (object.retryPolicy != null) {
            if (typeof object.retryPolicy !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.retryPolicy: object expected, but got ' +
                  typeof object.retryPolicy
              );
            }
            message.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.fromObject(
                object.retryPolicy
              );
          }
          if (object.application != null) {
            if (typeof object.application !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.application: object expected, but got ' +
                  typeof object.application
              );
            }
            message.application =
              workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                object.application
              );
          }
          if (object.healthieV1 != null) {
            if (typeof object.healthieV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.healthieV1: object expected, but got ' +
                  typeof object.healthieV1
              );
            }
            message.healthieV1 =
              fetchactions$1.healthie.v1.FetchAction.fromObject(
                object.healthieV1
              );
          }
          if (object.freshdeskV1 != null) {
            if (typeof object.freshdeskV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.freshdeskV1: object expected, but got ' +
                  typeof object.freshdeskV1
              );
            }
            message.freshdeskV1 =
              fetchactions$2.freshdesk.v1.FetchAction.fromObject(
                object.freshdeskV1
              );
          }
          if (object.morfV1 != null) {
            if (typeof object.morfV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.morfV1: object expected, but got ' +
                  typeof object.morfV1
              );
            }
            message.morfV1 = fetchactions$3.morf.v1.FetchAction.fromObject(
              object.morfV1
            );
          }
          if (object.hubspotV1 != null) {
            if (typeof object.hubspotV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.hubspotV1: object expected, but got ' +
                  typeof object.hubspotV1
              );
            }
            message.hubspotV1 =
              fetchactions$4.hubspot.v1.FetchAction.fromObject(
                object.hubspotV1
              );
          }
          if (object.intercomV1 != null) {
            if (typeof object.intercomV1 !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowFetchAction.intercomV1: object expected, but got ' +
                  typeof object.intercomV1
              );
            }
            message.intercomV1 =
              fetchactions$5.intercom.v1.FetchAction.fromObject(
                object.intercomV1
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.resultObjectKey = '';
            object.parameterMissingPolicy = null;
            object.retryPolicy = null;
            object.application = null;
          }
          let keys;
          if (
            message.resultObjectKey != null &&
            message.hasOwnProperty('resultObjectKey')
          ) {
            object.resultObjectKey = message.resultObjectKey;
          }
          if (
            message.parameterMissingPolicy != null &&
            message.hasOwnProperty('parameterMissingPolicy')
          ) {
            object.parameterMissingPolicy =
              workflow_parameters$1.v1.ParameterMissingPolicy.toObject(
                message.parameterMissingPolicy,
                options
              );
          }
          if (
            message.retryPolicy != null &&
            message.hasOwnProperty('retryPolicy')
          ) {
            object.retryPolicy =
              workflow_parameters$1.v1.DestinationActionRetryPolicy.toObject(
                message.retryPolicy,
                options
              );
          }
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              workflow_destination_applications$1.v1.DestinationApplication.toObject(
                message.application,
                options
              );
          }
          if (
            message.healthieV1 != null &&
            message.hasOwnProperty('healthieV1')
          ) {
            object.healthieV1 = fetchactions$1.healthie.v1.FetchAction.toObject(
              message.healthieV1,
              options
            );
            if (options.oneofs) {
              object.type = 'healthieV1';
            }
          }
          if (
            message.freshdeskV1 != null &&
            message.hasOwnProperty('freshdeskV1')
          ) {
            object.freshdeskV1 =
              fetchactions$2.freshdesk.v1.FetchAction.toObject(
                message.freshdeskV1,
                options
              );
            if (options.oneofs) {
              object.type = 'freshdeskV1';
            }
          }
          if (message.morfV1 != null && message.hasOwnProperty('morfV1')) {
            object.morfV1 = fetchactions$3.morf.v1.FetchAction.toObject(
              message.morfV1,
              options
            );
            if (options.oneofs) {
              object.type = 'morfV1';
            }
          }
          if (
            message.hubspotV1 != null &&
            message.hasOwnProperty('hubspotV1')
          ) {
            object.hubspotV1 = fetchactions$4.hubspot.v1.FetchAction.toObject(
              message.hubspotV1,
              options
            );
            if (options.oneofs) {
              object.type = 'hubspotV1';
            }
          }
          if (
            message.intercomV1 != null &&
            message.hasOwnProperty('intercomV1')
          ) {
            object.intercomV1 = fetchactions$5.intercom.v1.FetchAction.toObject(
              message.intercomV1,
              options
            );
            if (options.oneofs) {
              object.type = 'intercomV1';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowFetchAction';
        }
      }

      WorkflowFetchAction.prototype.resultObjectKey = '';
      WorkflowFetchAction.prototype.parameterMissingPolicy = null;
      WorkflowFetchAction.prototype.retryPolicy = null;
      WorkflowFetchAction.prototype.application = null;
      WorkflowFetchAction.prototype.healthieV1 = null;
      WorkflowFetchAction.prototype.freshdeskV1 = null;
      WorkflowFetchAction.prototype.morfV1 = null;
      WorkflowFetchAction.prototype.hubspotV1 = null;
      WorkflowFetchAction.prototype.intercomV1 = null;

      return WorkflowFetchAction;
    })();

    v1.WorkflowCelEnvironment = (() => {
      class WorkflowCelEnvironment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowCelEnvironment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.thirdPartyIds != null &&
            Object.hasOwnProperty.call(message, 'thirdPartyIds')
          ) {
            profiles$1.v1.ThirdPartyIds.encode(
              message.thirdPartyIds,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.tracing != null &&
            Object.hasOwnProperty.call(message, 'tracing')
          ) {
            google$2.protobuf.Struct.encode(
              message.tracing,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.now != null &&
            Object.hasOwnProperty.call(message, 'now')
          ) {
            timing$1.v1.Timestamp.encode(
              message.now,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowCelEnvironment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                message.thirdPartyIds = profiles$1.v1.ThirdPartyIds.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.tracing = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.now = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowCelEnvironment) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowCelEnvironment();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.thirdPartyIds != null) {
            if (typeof object.thirdPartyIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowCelEnvironment.thirdPartyIds: object expected, but got ' +
                  typeof object.thirdPartyIds
              );
            }
            message.thirdPartyIds = profiles$1.v1.ThirdPartyIds.fromObject(
              object.thirdPartyIds
            );
          }
          if (object.tracing != null) {
            if (typeof object.tracing !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowCelEnvironment.tracing: object expected, but got ' +
                  typeof object.tracing
              );
            }
            message.tracing = google$2.protobuf.Struct.fromObject(
              object.tracing
            );
          }
          if (object.now != null) {
            if (typeof object.now !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowCelEnvironment.now: object expected, but got ' +
                  typeof object.now
              );
            }
            message.now = timing$1.v1.Timestamp.fromObject(object.now);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.thirdPartyIds = null;
            object.tracing = null;
            object.now = null;
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (
            message.thirdPartyIds != null &&
            message.hasOwnProperty('thirdPartyIds')
          ) {
            object.thirdPartyIds = profiles$1.v1.ThirdPartyIds.toObject(
              message.thirdPartyIds,
              options
            );
          }
          if (message.tracing != null && message.hasOwnProperty('tracing')) {
            object.tracing = google$2.protobuf.Struct.toObject(
              message.tracing,
              options
            );
          }
          if (message.now != null && message.hasOwnProperty('now')) {
            object.now = timing$1.v1.Timestamp.toObject(message.now, options);
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowCelEnvironment';
        }
      }

      WorkflowCelEnvironment.prototype.organizationId = '';
      WorkflowCelEnvironment.prototype.thirdPartyIds = null;
      WorkflowCelEnvironment.prototype.tracing = null;
      WorkflowCelEnvironment.prototype.now = null;

      return WorkflowCelEnvironment;
    })();

    v1.WorkflowFilter = (() => {
      class WorkflowFilter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowFilter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.celExpressionStr != null &&
            Object.hasOwnProperty.call(message, 'celExpressionStr')
          ) {
            writer.uint32(18).string(message.celExpressionStr);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowFilter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 2: {
                message.celExpressionStr = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowFilter) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowFilter();
          if (object.celExpressionStr != null) {
            message.celExpressionStr = String(object.celExpressionStr);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.celExpressionStr = '';
          }
          let keys;
          if (
            message.celExpressionStr != null &&
            message.hasOwnProperty('celExpressionStr')
          ) {
            object.celExpressionStr = message.celExpressionStr;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowFilter';
        }
      }

      WorkflowFilter.prototype.celExpressionStr = '';

      return WorkflowFilter;
    })();

    v1.WorkflowPause = (() => {
      class WorkflowPause {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowPause(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.timestamps != null &&
            Object.hasOwnProperty.call(message, 'timestamps')
          ) {
            workflow_parameters$1.v1.DestinationActionParameterSources.encode(
              message.timestamps,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.refreshEventPayload != null &&
            Object.hasOwnProperty.call(message, 'refreshEventPayload')
          ) {
            writer.uint32(16).bool(message.refreshEventPayload);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowPause();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.timestamps =
                  workflow_parameters$1.v1.DestinationActionParameterSources.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.refreshEventPayload = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowPause) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowPause();
          if (object.timestamps != null) {
            if (typeof object.timestamps !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowPause.timestamps: object expected, but got ' +
                  typeof object.timestamps
              );
            }
            message.timestamps =
              workflow_parameters$1.v1.DestinationActionParameterSources.fromObject(
                object.timestamps
              );
          }
          if (object.refreshEventPayload != null) {
            message.refreshEventPayload = Boolean(object.refreshEventPayload);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.timestamps = null;
            object.refreshEventPayload = false;
          }
          let keys;
          if (
            message.timestamps != null &&
            message.hasOwnProperty('timestamps')
          ) {
            object.timestamps =
              workflow_parameters$1.v1.DestinationActionParameterSources.toObject(
                message.timestamps,
                options
              );
          }
          if (
            message.refreshEventPayload != null &&
            message.hasOwnProperty('refreshEventPayload')
          ) {
            object.refreshEventPayload = message.refreshEventPayload;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowPause';
        }
      }

      WorkflowPause.prototype.timestamps = null;
      WorkflowPause.prototype.refreshEventPayload = false;

      return WorkflowPause;
    })();

    v1.WorkflowExecutionGraph = (() => {
      class WorkflowExecutionGraph {
        get profileSource() {
          for (const key of ['profileLookupConfiguration', 'providedProfile']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set profileSource(name) {
          for (const key of ['profileLookupConfiguration', 'providedProfile']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.roots = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowExecutionGraph(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.roots != null &&
            Object.hasOwnProperty.call(message, 'roots')
          ) {
            for (const element of message.roots) {
              $root.workflows.v1.WorkflowExecutionGraph.Node.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(26).string(message.organizationId);
          }
          if (
            message.organizationName != null &&
            Object.hasOwnProperty.call(message, 'organizationName')
          ) {
            writer.uint32(34).string(message.organizationName);
          }
          if (
            message.organizationType != null &&
            Object.hasOwnProperty.call(message, 'organizationType')
          ) {
            writer.uint32(40).int32(message.organizationType);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(50).string(message.workflowId);
          }
          if (
            message.version != null &&
            Object.hasOwnProperty.call(message, 'version')
          ) {
            writer.uint32(56).int64(message.version);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(66).string(message.name);
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(74).string(message.eventSnapshotId);
          }
          if (
            message.eventPayloadIndex != null &&
            Object.hasOwnProperty.call(message, 'eventPayloadIndex')
          ) {
            writer.uint32(80).int64(message.eventPayloadIndex);
          }
          if (
            message.applicationType != null &&
            Object.hasOwnProperty.call(message, 'applicationType')
          ) {
            writer.uint32(88).int32(message.applicationType);
          }
          if (
            message.profileLookupConfiguration != null &&
            Object.hasOwnProperty.call(message, 'profileLookupConfiguration')
          ) {
            profiles$1.v1.LookupConfiguration.encode(
              message.profileLookupConfiguration,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.providedProfile != null &&
            Object.hasOwnProperty.call(message, 'providedProfile')
          ) {
            $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.encode(
              message.providedProfile,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.webhookRequestId != null &&
            Object.hasOwnProperty.call(message, 'webhookRequestId')
          ) {
            writer.uint32(114).string(message.webhookRequestId);
          }
          if (
            message.uniquenessConflictResolution != null &&
            Object.hasOwnProperty.call(message, 'uniquenessConflictResolution')
          ) {
            $root.workflows.v1.WorkflowUniquenessConflictResolution.encode(
              message.uniquenessConflictResolution,
              writer.uint32(122).fork()
            ).ldelim();
          }
          if (
            message.supersededWorkflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'supersededWorkflowExecutionId')
          ) {
            writer.uint32(130).string(message.supersededWorkflowExecutionId);
          }
          if (
            message.retryOriginalWorkflowExecutionId != null &&
            Object.hasOwnProperty.call(
              message,
              'retryOriginalWorkflowExecutionId'
            )
          ) {
            writer.uint32(138).string(message.retryOriginalWorkflowExecutionId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowExecutionGraph();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.roots || !message.roots.length) {
                  message.roots = [];
                }
                message.roots.push(
                  $root.workflows.v1.WorkflowExecutionGraph.Node.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 3: {
                message.organizationId = reader.string();
                break;
              }
              case 4: {
                message.organizationName = reader.string();
                break;
              }
              case 5: {
                message.organizationType = reader.int32();
                break;
              }
              case 6: {
                message.workflowId = reader.string();
                break;
              }
              case 7: {
                message.version = reader.int64();
                break;
              }
              case 8: {
                message.name = reader.string();
                break;
              }
              case 9: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 10: {
                message.eventPayloadIndex = reader.int64();
                break;
              }
              case 11: {
                message.applicationType = reader.int32();
                break;
              }
              case 12: {
                message.profileLookupConfiguration =
                  profiles$1.v1.LookupConfiguration.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 13: {
                message.providedProfile =
                  $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                message.webhookRequestId = reader.string();
                break;
              }
              case 15: {
                message.uniquenessConflictResolution =
                  $root.workflows.v1.WorkflowUniquenessConflictResolution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 16: {
                message.supersededWorkflowExecutionId = reader.string();
                break;
              }
              case 17: {
                message.retryOriginalWorkflowExecutionId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowExecutionGraph) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowExecutionGraph();
          if (object.roots) {
            if (!Array.isArray(object.roots)) {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.roots: array type expected, but got ' +
                  typeof object.roots
              );
            }
            message.roots = new Array(object.roots.length);
            for (let i = 0; i < object.roots.length; ++i) {
              if (typeof object.roots[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.roots: object expected, but got ' +
                    typeof object.roots[i]
                );
              }
              message.roots[i] =
                $root.workflows.v1.WorkflowExecutionGraph.Node.fromObject(
                  object.roots[i]
                );
            }
          }
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.organizationName != null) {
            message.organizationName = String(object.organizationName);
          }
          if (object.organizationType != null) {
            switch (object.organizationType) {
              case 'UNSPECIFIED_ORGANIZATION_TYPE':
              case 0: {
                message.organizationType = 0;
                break;
              }
              case 'MORF_INTERNAL':
              case 1: {
                message.organizationType = 1;
                break;
              }
              case 'CUSTOMER_SANDBOX':
              case 2: {
                message.organizationType = 2;
                break;
              }
              case 'CUSTOMER_PRODUCTION':
              case 3: {
                message.organizationType = 3;
                break;
              }
              default: {
                if (typeof object.organizationType == 'number') {
                  message.organizationType = object.organizationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.version != null) {
            if ($util.Long) {
              (message.version = $util.Long.fromValue(
                object.version
              )).unsigned = false;
            } else if (typeof object.version === 'string') {
              message.version = parseInt(object.version, 10);
            } else if (typeof object.version === 'number') {
              message.version = object.version;
            } else if (typeof object.version === 'object') {
              message.version = new $util.LongBits(
                object.version.low >>> 0,
                object.version.high >>> 0
              ).toNumber();
            }
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventPayloadIndex != null) {
            if ($util.Long) {
              (message.eventPayloadIndex = $util.Long.fromValue(
                object.eventPayloadIndex
              )).unsigned = false;
            } else if (typeof object.eventPayloadIndex === 'string') {
              message.eventPayloadIndex = parseInt(
                object.eventPayloadIndex,
                10
              );
            } else if (typeof object.eventPayloadIndex === 'number') {
              message.eventPayloadIndex = object.eventPayloadIndex;
            } else if (typeof object.eventPayloadIndex === 'object') {
              message.eventPayloadIndex = new $util.LongBits(
                object.eventPayloadIndex.low >>> 0,
                object.eventPayloadIndex.high >>> 0
              ).toNumber();
            }
          }
          if (object.applicationType != null) {
            switch (object.applicationType) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.applicationType = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.applicationType = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.applicationType = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.applicationType = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.applicationType = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.applicationType = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.applicationType = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.applicationType = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.applicationType = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.applicationType = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.applicationType = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.applicationType = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.applicationType = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.applicationType = 13;
                break;
              }
              default: {
                if (typeof object.applicationType == 'number') {
                  message.applicationType = object.applicationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.profileLookupConfiguration != null) {
            if (typeof object.profileLookupConfiguration !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.profileLookupConfiguration: object expected, but got ' +
                  typeof object.profileLookupConfiguration
              );
            }
            message.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.fromObject(
                object.profileLookupConfiguration
              );
          }
          if (object.providedProfile != null) {
            if (typeof object.providedProfile !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.providedProfile: object expected, but got ' +
                  typeof object.providedProfile
              );
            }
            message.providedProfile =
              $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.fromObject(
                object.providedProfile
              );
          }
          if (object.webhookRequestId != null) {
            message.webhookRequestId = String(object.webhookRequestId);
          }
          if (object.uniquenessConflictResolution != null) {
            if (typeof object.uniquenessConflictResolution !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionGraph.uniquenessConflictResolution: object expected, but got ' +
                  typeof object.uniquenessConflictResolution
              );
            }
            message.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.fromObject(
                object.uniquenessConflictResolution
              );
          }
          if (object.supersededWorkflowExecutionId != null) {
            message.supersededWorkflowExecutionId = String(
              object.supersededWorkflowExecutionId
            );
          }
          if (object.retryOriginalWorkflowExecutionId != null) {
            message.retryOriginalWorkflowExecutionId = String(
              object.retryOriginalWorkflowExecutionId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.roots = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
            object.organizationName = '';
            object.organizationType =
              options.enums === String ? 'UNSPECIFIED_ORGANIZATION_TYPE' : 0;
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.version =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.version = options.longs === String ? '0' : 0;
            }
            object.name = '';
            object.eventSnapshotId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.eventPayloadIndex =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.eventPayloadIndex = options.longs === String ? '0' : 0;
            }
            object.applicationType =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
          }
          let keys;
          if (message.roots && message.roots.length) {
            object.roots = new Array(message.roots.length);
            for (let i = 0; i < message.roots.length; ++i) {
              object.roots[i] =
                $root.workflows.v1.WorkflowExecutionGraph.Node.toObject(
                  message.roots[i],
                  options
                );
            }
          }
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (
            message.organizationName != null &&
            message.hasOwnProperty('organizationName')
          ) {
            object.organizationName = message.organizationName;
          }
          if (
            message.organizationType != null &&
            message.hasOwnProperty('organizationType')
          ) {
            object.organizationType =
              options.enums === String
                ? accounts$1.v1.OrganizationType[message.organizationType] ===
                  undefined
                  ? message.organizationType
                  : accounts$1.v1.OrganizationType[message.organizationType]
                : message.organizationType;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (message.version != null && message.hasOwnProperty('version')) {
            object.version =
              typeof message.version === 'number'
                ? options.longs === String
                  ? String(message.version)
                  : message.version
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.version)
                : options.longs === Number
                ? new $util.LongBits(
                    message.version.low >>> 0,
                    message.version.high >>> 0
                  ).toNumber()
                : message.version;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventPayloadIndex != null &&
            message.hasOwnProperty('eventPayloadIndex')
          ) {
            object.eventPayloadIndex =
              typeof message.eventPayloadIndex === 'number'
                ? options.longs === String
                  ? String(message.eventPayloadIndex)
                  : message.eventPayloadIndex
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.eventPayloadIndex)
                : options.longs === Number
                ? new $util.LongBits(
                    message.eventPayloadIndex.low >>> 0,
                    message.eventPayloadIndex.high >>> 0
                  ).toNumber()
                : message.eventPayloadIndex;
          }
          if (
            message.applicationType != null &&
            message.hasOwnProperty('applicationType')
          ) {
            object.applicationType =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.applicationType
                  ] === undefined
                  ? message.applicationType
                  : source_application$1.v2.SourceApplication.Application[
                      message.applicationType
                    ]
                : message.applicationType;
          }
          if (
            message.profileLookupConfiguration != null &&
            message.hasOwnProperty('profileLookupConfiguration')
          ) {
            object.profileLookupConfiguration =
              profiles$1.v1.LookupConfiguration.toObject(
                message.profileLookupConfiguration,
                options
              );
            if (options.oneofs) {
              object.profileSource = 'profileLookupConfiguration';
            }
          }
          if (
            message.providedProfile != null &&
            message.hasOwnProperty('providedProfile')
          ) {
            object.providedProfile =
              $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.toObject(
                message.providedProfile,
                options
              );
            if (options.oneofs) {
              object.profileSource = 'providedProfile';
            }
          }
          if (
            message.webhookRequestId != null &&
            message.hasOwnProperty('webhookRequestId')
          ) {
            object.webhookRequestId = message.webhookRequestId;
          }
          if (
            message.uniquenessConflictResolution != null &&
            message.hasOwnProperty('uniquenessConflictResolution')
          ) {
            object.uniquenessConflictResolution =
              $root.workflows.v1.WorkflowUniquenessConflictResolution.toObject(
                message.uniquenessConflictResolution,
                options
              );
          }
          if (
            message.supersededWorkflowExecutionId != null &&
            message.hasOwnProperty('supersededWorkflowExecutionId')
          ) {
            object.supersededWorkflowExecutionId =
              message.supersededWorkflowExecutionId;
          }
          if (
            message.retryOriginalWorkflowExecutionId != null &&
            message.hasOwnProperty('retryOriginalWorkflowExecutionId')
          ) {
            object.retryOriginalWorkflowExecutionId =
              message.retryOriginalWorkflowExecutionId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowExecutionGraph';
        }
      }

      WorkflowExecutionGraph.prototype.roots = $util.emptyArray;
      WorkflowExecutionGraph.prototype.organizationId = '';
      WorkflowExecutionGraph.prototype.organizationName = '';
      WorkflowExecutionGraph.prototype.organizationType = 0;
      WorkflowExecutionGraph.prototype.workflowId = '';
      WorkflowExecutionGraph.prototype.version = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      WorkflowExecutionGraph.prototype.name = '';
      WorkflowExecutionGraph.prototype.eventSnapshotId = '';
      WorkflowExecutionGraph.prototype.eventPayloadIndex = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      WorkflowExecutionGraph.prototype.applicationType = 0;
      WorkflowExecutionGraph.prototype.profileLookupConfiguration = null;
      WorkflowExecutionGraph.prototype.providedProfile = null;
      WorkflowExecutionGraph.prototype.webhookRequestId = null;
      WorkflowExecutionGraph.prototype.uniquenessConflictResolution = null;
      WorkflowExecutionGraph.prototype.supersededWorkflowExecutionId = null;
      WorkflowExecutionGraph.prototype.retryOriginalWorkflowExecutionId = null;
      WorkflowExecutionGraph.Node = (() => {
        class Node {
          constructor(properties) {
            this.children = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Node(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.node != null &&
              Object.hasOwnProperty.call(message, 'node')
            ) {
              $root.workflows.v1.WorkflowNode.encode(
                message.node,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.children != null &&
              Object.hasOwnProperty.call(message, 'children')
            ) {
              for (const element of message.children) {
                $root.workflows.v1.WorkflowExecutionGraph.Node.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Node();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.node = $root.workflows.v1.WorkflowNode.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  if (!message.children || !message.children.length) {
                    message.children = [];
                  }
                  message.children.push(
                    $root.workflows.v1.WorkflowExecutionGraph.Node.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.workflows.v1.WorkflowExecutionGraph.Node
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.WorkflowExecutionGraph.Node();
            if (object.node != null) {
              if (typeof object.node !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.Node.node: object expected, but got ' +
                    typeof object.node
                );
              }
              message.node = $root.workflows.v1.WorkflowNode.fromObject(
                object.node
              );
            }
            if (object.children) {
              if (!Array.isArray(object.children)) {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.Node.children: array type expected, but got ' +
                    typeof object.children
                );
              }
              message.children = new Array(object.children.length);
              for (let i = 0; i < object.children.length; ++i) {
                if (typeof object.children[i] !== 'object') {
                  throw new TypeError(
                    '.workflows.v1.WorkflowExecutionGraph.Node.children: object expected, but got ' +
                      typeof object.children[i]
                  );
                }
                message.children[i] =
                  $root.workflows.v1.WorkflowExecutionGraph.Node.fromObject(
                    object.children[i]
                  );
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.children = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.node = null;
            }
            let keys;
            if (message.node != null && message.hasOwnProperty('node')) {
              object.node = $root.workflows.v1.WorkflowNode.toObject(
                message.node,
                options
              );
            }
            if (message.children && message.children.length) {
              object.children = new Array(message.children.length);
              for (let i = 0; i < message.children.length; ++i) {
                object.children[i] =
                  $root.workflows.v1.WorkflowExecutionGraph.Node.toObject(
                    message.children[i],
                    options
                  );
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.WorkflowExecutionGraph.Node';
          }
        }

        Node.prototype.node = null;
        Node.prototype.children = $util.emptyArray;

        return Node;
      })();

      WorkflowExecutionGraph.ProvidedProfile = (() => {
        class ProvidedProfile {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ProvidedProfile(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.profile != null &&
              Object.hasOwnProperty.call(message, 'profile')
            ) {
              profiles$1.v1.Profile.encode(
                message.profile,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.originalLookupResult != null &&
              Object.hasOwnProperty.call(message, 'originalLookupResult')
            ) {
              profiles$1.v1.LookupExecutionResult.encode(
                message.originalLookupResult,
                writer.uint32(18).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ProvidedProfile();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.profile = profiles$1.v1.Profile.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 2: {
                  message.originalLookupResult =
                    profiles$1.v1.LookupExecutionResult.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.WorkflowExecutionGraph.ProvidedProfile();
            if (object.profile != null) {
              if (typeof object.profile !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.profile: object expected, but got ' +
                    typeof object.profile
                );
              }
              message.profile = profiles$1.v1.Profile.fromObject(
                object.profile
              );
            }
            if (object.originalLookupResult != null) {
              if (typeof object.originalLookupResult !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowExecutionGraph.ProvidedProfile.originalLookupResult: object expected, but got ' +
                    typeof object.originalLookupResult
                );
              }
              message.originalLookupResult =
                profiles$1.v1.LookupExecutionResult.fromObject(
                  object.originalLookupResult
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.profile = null;
              object.originalLookupResult = null;
            }
            let keys;
            if (message.profile != null && message.hasOwnProperty('profile')) {
              object.profile = profiles$1.v1.Profile.toObject(
                message.profile,
                options
              );
            }
            if (
              message.originalLookupResult != null &&
              message.hasOwnProperty('originalLookupResult')
            ) {
              object.originalLookupResult =
                profiles$1.v1.LookupExecutionResult.toObject(
                  message.originalLookupResult,
                  options
                );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflows.v1.WorkflowExecutionGraph.ProvidedProfile'
            );
          }
        }

        ProvidedProfile.prototype.profile = null;
        ProvidedProfile.prototype.originalLookupResult = null;

        return ProvidedProfile;
      })();

      return WorkflowExecutionGraph;
    })();

    v1.WorkflowIdSummary = (() => {
      class WorkflowIdSummary {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowIdSummary(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowApplication != null &&
            Object.hasOwnProperty.call(message, 'workflowApplication')
          ) {
            writer.uint32(8).int32(message.workflowApplication);
          }
          if (
            message.workflowName != null &&
            Object.hasOwnProperty.call(message, 'workflowName')
          ) {
            writer.uint32(18).string(message.workflowName);
          }
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(26).string(message.workflowId);
          }
          if (
            message.workflowVersion != null &&
            Object.hasOwnProperty.call(message, 'workflowVersion')
          ) {
            writer.uint32(32).int64(message.workflowVersion);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowIdSummary();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowApplication = reader.int32();
                break;
              }
              case 2: {
                message.workflowName = reader.string();
                break;
              }
              case 3: {
                message.workflowId = reader.string();
                break;
              }
              case 4: {
                message.workflowVersion = reader.int64();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowIdSummary) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowIdSummary();
          if (object.workflowApplication != null) {
            switch (object.workflowApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.workflowApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.workflowApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.workflowApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.workflowApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.workflowApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.workflowApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.workflowApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.workflowApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.workflowApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.workflowApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.workflowApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.workflowApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.workflowApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.workflowApplication = 13;
                break;
              }
              default: {
                if (typeof object.workflowApplication == 'number') {
                  message.workflowApplication = object.workflowApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.workflowName != null) {
            message.workflowName = String(object.workflowName);
          }
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          if (object.workflowVersion != null) {
            if ($util.Long) {
              (message.workflowVersion = $util.Long.fromValue(
                object.workflowVersion
              )).unsigned = false;
            } else if (typeof object.workflowVersion === 'string') {
              message.workflowVersion = parseInt(object.workflowVersion, 10);
            } else if (typeof object.workflowVersion === 'number') {
              message.workflowVersion = object.workflowVersion;
            } else if (typeof object.workflowVersion === 'object') {
              message.workflowVersion = new $util.LongBits(
                object.workflowVersion.low >>> 0,
                object.workflowVersion.high >>> 0
              ).toNumber();
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowApplication =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.workflowName = '';
            object.workflowId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.workflowVersion =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.workflowVersion = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowApplication != null &&
            message.hasOwnProperty('workflowApplication')
          ) {
            object.workflowApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.workflowApplication
                  ] === undefined
                  ? message.workflowApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.workflowApplication
                    ]
                : message.workflowApplication;
          }
          if (
            message.workflowName != null &&
            message.hasOwnProperty('workflowName')
          ) {
            object.workflowName = message.workflowName;
          }
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          if (
            message.workflowVersion != null &&
            message.hasOwnProperty('workflowVersion')
          ) {
            object.workflowVersion =
              typeof message.workflowVersion === 'number'
                ? options.longs === String
                  ? String(message.workflowVersion)
                  : message.workflowVersion
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.workflowVersion)
                : options.longs === Number
                ? new $util.LongBits(
                    message.workflowVersion.low >>> 0,
                    message.workflowVersion.high >>> 0
                  ).toNumber()
                : message.workflowVersion;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowIdSummary';
        }
      }

      WorkflowIdSummary.prototype.workflowApplication = 0;
      WorkflowIdSummary.prototype.workflowName = '';
      WorkflowIdSummary.prototype.workflowId = '';
      WorkflowIdSummary.prototype.workflowVersion = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      return WorkflowIdSummary;
    })();

    v1.GroupedErrorData = (() => {
      class GroupedErrorData {
        get type() {
          for (const key of [
            'workflowErrorApplication',
            'destinationActionErrorApplication',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'workflowErrorApplication',
            'destinationActionErrorApplication',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          this.affectedWorkflows = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GroupedErrorData(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.groupingName != null &&
            Object.hasOwnProperty.call(message, 'groupingName')
          ) {
            writer.uint32(10).string(message.groupingName);
          }
          if (
            message.errorString != null &&
            Object.hasOwnProperty.call(message, 'errorString')
          ) {
            writer.uint32(18).string(message.errorString);
          }
          if (
            message.errorDetails != null &&
            Object.hasOwnProperty.call(message, 'errorDetails')
          ) {
            writer.uint32(26).string(message.errorDetails);
          }
          if (
            message.errorClass != null &&
            Object.hasOwnProperty.call(message, 'errorClass')
          ) {
            writer.uint32(32).int32(message.errorClass);
          }
          if (
            message.profilesAffectedCount != null &&
            Object.hasOwnProperty.call(message, 'profilesAffectedCount')
          ) {
            writer.uint32(40).uint64(message.profilesAffectedCount);
          }
          if (
            message.workflowsAffectedCount != null &&
            Object.hasOwnProperty.call(message, 'workflowsAffectedCount')
          ) {
            writer.uint32(48).uint64(message.workflowsAffectedCount);
          }
          if (
            message.affectedEventsCount != null &&
            Object.hasOwnProperty.call(message, 'affectedEventsCount')
          ) {
            writer.uint32(56).uint64(message.affectedEventsCount);
          }
          if (
            message.lastSeenWithinRange != null &&
            Object.hasOwnProperty.call(message, 'lastSeenWithinRange')
          ) {
            timing$1.v1.Timestamp.encode(
              message.lastSeenWithinRange,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.affectedWorkflows != null &&
            Object.hasOwnProperty.call(message, 'affectedWorkflows')
          ) {
            for (const element of message.affectedWorkflows) {
              $root.workflows.v1.WorkflowIdSummary.encode(
                element,
                writer.uint32(74).fork()
              ).ldelim();
            }
          }
          if (
            message.workflowErrorApplication != null &&
            Object.hasOwnProperty.call(message, 'workflowErrorApplication')
          ) {
            writer.uint32(80).int32(message.workflowErrorApplication);
          }
          if (
            message.destinationActionErrorApplication != null &&
            Object.hasOwnProperty.call(
              message,
              'destinationActionErrorApplication'
            )
          ) {
            writer.uint32(88).int32(message.destinationActionErrorApplication);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GroupedErrorData();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.groupingName = reader.string();
                break;
              }
              case 2: {
                message.errorString = reader.string();
                break;
              }
              case 3: {
                message.errorDetails = reader.string();
                break;
              }
              case 4: {
                message.errorClass = reader.int32();
                break;
              }
              case 5: {
                message.profilesAffectedCount = reader.uint64();
                break;
              }
              case 6: {
                message.workflowsAffectedCount = reader.uint64();
                break;
              }
              case 7: {
                message.affectedEventsCount = reader.uint64();
                break;
              }
              case 8: {
                message.lastSeenWithinRange = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                if (
                  !message.affectedWorkflows ||
                  !message.affectedWorkflows.length
                ) {
                  message.affectedWorkflows = [];
                }
                message.affectedWorkflows.push(
                  $root.workflows.v1.WorkflowIdSummary.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 10: {
                message.workflowErrorApplication = reader.int32();
                break;
              }
              case 11: {
                message.destinationActionErrorApplication = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GroupedErrorData) {
            return object;
          }
          const message = new $root.workflows.v1.GroupedErrorData();
          if (object.groupingName != null) {
            message.groupingName = String(object.groupingName);
          }
          if (object.errorString != null) {
            message.errorString = String(object.errorString);
          }
          if (object.errorDetails != null) {
            message.errorDetails = String(object.errorDetails);
          }
          if (object.errorClass != null) {
            switch (object.errorClass) {
              case 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS':
              case 0: {
                message.errorClass = 0;
                break;
              }
              case 'ERROR':
              case 1: {
                message.errorClass = 1;
                break;
              }
              case 'WARNING':
              case 2: {
                message.errorClass = 2;
                break;
              }
              case 'RETRY':
              case 3: {
                message.errorClass = 3;
                break;
              }
              default: {
                if (typeof object.errorClass == 'number') {
                  message.errorClass = object.errorClass;
                  break;
                }
                break;
              }
            }
          }
          if (object.profilesAffectedCount != null) {
            if ($util.Long) {
              (message.profilesAffectedCount = $util.Long.fromValue(
                object.profilesAffectedCount
              )).unsigned = true;
            } else if (typeof object.profilesAffectedCount === 'string') {
              message.profilesAffectedCount = parseInt(
                object.profilesAffectedCount,
                10
              );
            } else if (typeof object.profilesAffectedCount === 'number') {
              message.profilesAffectedCount = object.profilesAffectedCount;
            } else if (typeof object.profilesAffectedCount === 'object') {
              message.profilesAffectedCount = new $util.LongBits(
                object.profilesAffectedCount.low >>> 0,
                object.profilesAffectedCount.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.workflowsAffectedCount != null) {
            if ($util.Long) {
              (message.workflowsAffectedCount = $util.Long.fromValue(
                object.workflowsAffectedCount
              )).unsigned = true;
            } else if (typeof object.workflowsAffectedCount === 'string') {
              message.workflowsAffectedCount = parseInt(
                object.workflowsAffectedCount,
                10
              );
            } else if (typeof object.workflowsAffectedCount === 'number') {
              message.workflowsAffectedCount = object.workflowsAffectedCount;
            } else if (typeof object.workflowsAffectedCount === 'object') {
              message.workflowsAffectedCount = new $util.LongBits(
                object.workflowsAffectedCount.low >>> 0,
                object.workflowsAffectedCount.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.affectedEventsCount != null) {
            if ($util.Long) {
              (message.affectedEventsCount = $util.Long.fromValue(
                object.affectedEventsCount
              )).unsigned = true;
            } else if (typeof object.affectedEventsCount === 'string') {
              message.affectedEventsCount = parseInt(
                object.affectedEventsCount,
                10
              );
            } else if (typeof object.affectedEventsCount === 'number') {
              message.affectedEventsCount = object.affectedEventsCount;
            } else if (typeof object.affectedEventsCount === 'object') {
              message.affectedEventsCount = new $util.LongBits(
                object.affectedEventsCount.low >>> 0,
                object.affectedEventsCount.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.lastSeenWithinRange != null) {
            if (typeof object.lastSeenWithinRange !== 'object') {
              throw new TypeError(
                '.workflows.v1.GroupedErrorData.lastSeenWithinRange: object expected, but got ' +
                  typeof object.lastSeenWithinRange
              );
            }
            message.lastSeenWithinRange = timing$1.v1.Timestamp.fromObject(
              object.lastSeenWithinRange
            );
          }
          if (object.affectedWorkflows) {
            if (!Array.isArray(object.affectedWorkflows)) {
              throw new TypeError(
                '.workflows.v1.GroupedErrorData.affectedWorkflows: array type expected, but got ' +
                  typeof object.affectedWorkflows
              );
            }
            message.affectedWorkflows = new Array(
              object.affectedWorkflows.length
            );
            for (let i = 0; i < object.affectedWorkflows.length; ++i) {
              if (typeof object.affectedWorkflows[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GroupedErrorData.affectedWorkflows: object expected, but got ' +
                    typeof object.affectedWorkflows[i]
                );
              }
              message.affectedWorkflows[i] =
                $root.workflows.v1.WorkflowIdSummary.fromObject(
                  object.affectedWorkflows[i]
                );
            }
          }
          if (object.workflowErrorApplication != null) {
            switch (object.workflowErrorApplication) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.workflowErrorApplication = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.workflowErrorApplication = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.workflowErrorApplication = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.workflowErrorApplication = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.workflowErrorApplication = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.workflowErrorApplication = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.workflowErrorApplication = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.workflowErrorApplication = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.workflowErrorApplication = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.workflowErrorApplication = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.workflowErrorApplication = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.workflowErrorApplication = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.workflowErrorApplication = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.workflowErrorApplication = 13;
                break;
              }
              default: {
                if (typeof object.workflowErrorApplication == 'number') {
                  message.workflowErrorApplication =
                    object.workflowErrorApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationActionErrorApplication != null) {
            switch (object.destinationActionErrorApplication) {
              case 'UNSPECIFIED_DESTINATION_APPLICATION':
              case 0: {
                message.destinationActionErrorApplication = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 1: {
                message.destinationActionErrorApplication = 1;
                break;
              }
              case 'CUSTOMER_IO':
              case 2: {
                message.destinationActionErrorApplication = 2;
                break;
              }
              case 'FACEBOOK':
              case 3: {
                message.destinationActionErrorApplication = 3;
                break;
              }
              case 'GOOGLE':
              case 4: {
                message.destinationActionErrorApplication = 4;
                break;
              }
              case 'HEALTHIE':
              case 5: {
                message.destinationActionErrorApplication = 5;
                break;
              }
              case 'MORF':
              case 6: {
                message.destinationActionErrorApplication = 6;
                break;
              }
              case 'INTAKEQ':
              case 7: {
                message.destinationActionErrorApplication = 7;
                break;
              }
              case 'SEGMENT':
              case 8: {
                message.destinationActionErrorApplication = 8;
                break;
              }
              case 'SPRUCE':
              case 9: {
                message.destinationActionErrorApplication = 9;
                break;
              }
              case 'SLACK':
              case 10: {
                message.destinationActionErrorApplication = 10;
                break;
              }
              case 'FRESHDESK':
              case 11: {
                message.destinationActionErrorApplication = 11;
                break;
              }
              case 'HUBSPOT':
              case 12: {
                message.destinationActionErrorApplication = 12;
                break;
              }
              case 'INTERCOM':
              case 13: {
                message.destinationActionErrorApplication = 13;
                break;
              }
              default: {
                if (
                  typeof object.destinationActionErrorApplication == 'number'
                ) {
                  message.destinationActionErrorApplication =
                    object.destinationActionErrorApplication;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.affectedWorkflows = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.groupingName = '';
            object.errorString = '';
            object.errorDetails = '';
            object.errorClass =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS'
                : 0;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.profilesAffectedCount =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.profilesAffectedCount = options.longs === String ? '0' : 0;
            }
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.workflowsAffectedCount =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.workflowsAffectedCount =
                options.longs === String ? '0' : 0;
            }
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.affectedEventsCount =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.affectedEventsCount = options.longs === String ? '0' : 0;
            }
            object.lastSeenWithinRange = null;
          }
          let keys;
          if (
            message.groupingName != null &&
            message.hasOwnProperty('groupingName')
          ) {
            object.groupingName = message.groupingName;
          }
          if (
            message.errorString != null &&
            message.hasOwnProperty('errorString')
          ) {
            object.errorString = message.errorString;
          }
          if (
            message.errorDetails != null &&
            message.hasOwnProperty('errorDetails')
          ) {
            object.errorDetails = message.errorDetails;
          }
          if (
            message.errorClass != null &&
            message.hasOwnProperty('errorClass')
          ) {
            object.errorClass =
              options.enums === String
                ? workflow_evaluation_results$1.v1.ErrorClass[
                    message.errorClass
                  ] === undefined
                  ? message.errorClass
                  : workflow_evaluation_results$1.v1.ErrorClass[
                      message.errorClass
                    ]
                : message.errorClass;
          }
          if (
            message.profilesAffectedCount != null &&
            message.hasOwnProperty('profilesAffectedCount')
          ) {
            object.profilesAffectedCount =
              typeof message.profilesAffectedCount === 'number'
                ? options.longs === String
                  ? String(message.profilesAffectedCount)
                  : message.profilesAffectedCount
                : options.longs === String
                ? $util.Long.prototype.toString.call(
                    message.profilesAffectedCount
                  )
                : options.longs === Number
                ? new $util.LongBits(
                    message.profilesAffectedCount.low >>> 0,
                    message.profilesAffectedCount.high >>> 0
                  ).toNumber(true)
                : message.profilesAffectedCount;
          }
          if (
            message.workflowsAffectedCount != null &&
            message.hasOwnProperty('workflowsAffectedCount')
          ) {
            object.workflowsAffectedCount =
              typeof message.workflowsAffectedCount === 'number'
                ? options.longs === String
                  ? String(message.workflowsAffectedCount)
                  : message.workflowsAffectedCount
                : options.longs === String
                ? $util.Long.prototype.toString.call(
                    message.workflowsAffectedCount
                  )
                : options.longs === Number
                ? new $util.LongBits(
                    message.workflowsAffectedCount.low >>> 0,
                    message.workflowsAffectedCount.high >>> 0
                  ).toNumber(true)
                : message.workflowsAffectedCount;
          }
          if (
            message.affectedEventsCount != null &&
            message.hasOwnProperty('affectedEventsCount')
          ) {
            object.affectedEventsCount =
              typeof message.affectedEventsCount === 'number'
                ? options.longs === String
                  ? String(message.affectedEventsCount)
                  : message.affectedEventsCount
                : options.longs === String
                ? $util.Long.prototype.toString.call(
                    message.affectedEventsCount
                  )
                : options.longs === Number
                ? new $util.LongBits(
                    message.affectedEventsCount.low >>> 0,
                    message.affectedEventsCount.high >>> 0
                  ).toNumber(true)
                : message.affectedEventsCount;
          }
          if (
            message.lastSeenWithinRange != null &&
            message.hasOwnProperty('lastSeenWithinRange')
          ) {
            object.lastSeenWithinRange = timing$1.v1.Timestamp.toObject(
              message.lastSeenWithinRange,
              options
            );
          }
          if (message.affectedWorkflows && message.affectedWorkflows.length) {
            object.affectedWorkflows = new Array(
              message.affectedWorkflows.length
            );
            for (let i = 0; i < message.affectedWorkflows.length; ++i) {
              object.affectedWorkflows[i] =
                $root.workflows.v1.WorkflowIdSummary.toObject(
                  message.affectedWorkflows[i],
                  options
                );
            }
          }
          if (
            message.workflowErrorApplication != null &&
            message.hasOwnProperty('workflowErrorApplication')
          ) {
            object.workflowErrorApplication =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.workflowErrorApplication
                  ] === undefined
                  ? message.workflowErrorApplication
                  : source_application$1.v2.SourceApplication.Application[
                      message.workflowErrorApplication
                    ]
                : message.workflowErrorApplication;
            if (options.oneofs) {
              object.type = 'workflowErrorApplication';
            }
          }
          if (
            message.destinationActionErrorApplication != null &&
            message.hasOwnProperty('destinationActionErrorApplication')
          ) {
            object.destinationActionErrorApplication =
              options.enums === String
                ? workflow_destination_applications$1.v1.DestinationApplication
                    .Application[message.destinationActionErrorApplication] ===
                  undefined
                  ? message.destinationActionErrorApplication
                  : workflow_destination_applications$1.v1
                      .DestinationApplication.Application[
                      message.destinationActionErrorApplication
                    ]
                : message.destinationActionErrorApplication;
            if (options.oneofs) {
              object.type = 'destinationActionErrorApplication';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GroupedErrorData';
        }
      }

      GroupedErrorData.prototype.groupingName = '';
      GroupedErrorData.prototype.errorString = '';
      GroupedErrorData.prototype.errorDetails = '';
      GroupedErrorData.prototype.errorClass = 0;
      GroupedErrorData.prototype.profilesAffectedCount = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      GroupedErrorData.prototype.workflowsAffectedCount = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      GroupedErrorData.prototype.affectedEventsCount = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      GroupedErrorData.prototype.lastSeenWithinRange = null;
      GroupedErrorData.prototype.affectedWorkflows = $util.emptyArray;
      GroupedErrorData.prototype.workflowErrorApplication = 0;
      GroupedErrorData.prototype.destinationActionErrorApplication = 0;

      return GroupedErrorData;
    })();

    v1.WorkflowErrorEvent = (() => {
      class WorkflowErrorEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowErrorEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.matchedProfile != null &&
            Object.hasOwnProperty.call(message, 'matchedProfile')
          ) {
            profiles$1.v1.Profile.encode(
              message.matchedProfile,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(34).string(message.eventSnapshotId);
          }
          if (
            message.eventSnapshotEventTime != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotEventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventSnapshotEventTime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.errorEventData != null &&
            Object.hasOwnProperty.call(message, 'errorEventData')
          ) {
            workflow_evaluation_results$1.v1.WorkflowEvent.encode(
              message.errorEventData,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.timestamp != null &&
            Object.hasOwnProperty.call(message, 'timestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.timestamp,
              writer.uint32(58).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowErrorEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.matchedProfile = profiles$1.v1.Profile.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.eventPayload = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 5: {
                message.eventSnapshotEventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.errorEventData =
                  workflow_evaluation_results$1.v1.WorkflowEvent.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.timestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowErrorEvent) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowErrorEvent();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.matchedProfile != null) {
            if (typeof object.matchedProfile !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowErrorEvent.matchedProfile: object expected, but got ' +
                  typeof object.matchedProfile
              );
            }
            message.matchedProfile = profiles$1.v1.Profile.fromObject(
              object.matchedProfile
            );
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowErrorEvent.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload = google$2.protobuf.Struct.fromObject(
              object.eventPayload
            );
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventSnapshotEventTime != null) {
            if (typeof object.eventSnapshotEventTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowErrorEvent.eventSnapshotEventTime: object expected, but got ' +
                  typeof object.eventSnapshotEventTime
              );
            }
            message.eventSnapshotEventTime = timing$1.v1.Timestamp.fromObject(
              object.eventSnapshotEventTime
            );
          }
          if (object.errorEventData != null) {
            if (typeof object.errorEventData !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowErrorEvent.errorEventData: object expected, but got ' +
                  typeof object.errorEventData
              );
            }
            message.errorEventData =
              workflow_evaluation_results$1.v1.WorkflowEvent.fromObject(
                object.errorEventData
              );
          }
          if (object.timestamp != null) {
            if (typeof object.timestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowErrorEvent.timestamp: object expected, but got ' +
                  typeof object.timestamp
              );
            }
            message.timestamp = timing$1.v1.Timestamp.fromObject(
              object.timestamp
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            object.eventPayload = null;
            object.eventSnapshotId = '';
            object.eventSnapshotEventTime = null;
            object.errorEventData = null;
            object.timestamp = null;
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (
            message.matchedProfile != null &&
            message.hasOwnProperty('matchedProfile')
          ) {
            object.matchedProfile = profiles$1.v1.Profile.toObject(
              message.matchedProfile,
              options
            );
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = google$2.protobuf.Struct.toObject(
              message.eventPayload,
              options
            );
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventSnapshotEventTime != null &&
            message.hasOwnProperty('eventSnapshotEventTime')
          ) {
            object.eventSnapshotEventTime = timing$1.v1.Timestamp.toObject(
              message.eventSnapshotEventTime,
              options
            );
          }
          if (
            message.errorEventData != null &&
            message.hasOwnProperty('errorEventData')
          ) {
            object.errorEventData =
              workflow_evaluation_results$1.v1.WorkflowEvent.toObject(
                message.errorEventData,
                options
              );
          }
          if (
            message.timestamp != null &&
            message.hasOwnProperty('timestamp')
          ) {
            object.timestamp = timing$1.v1.Timestamp.toObject(
              message.timestamp,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowErrorEvent';
        }
      }

      WorkflowErrorEvent.prototype.workflowExecutionId = '';
      WorkflowErrorEvent.prototype.matchedProfile = null;
      WorkflowErrorEvent.prototype.eventPayload = null;
      WorkflowErrorEvent.prototype.eventSnapshotId = '';
      WorkflowErrorEvent.prototype.eventSnapshotEventTime = null;
      WorkflowErrorEvent.prototype.errorEventData = null;
      WorkflowErrorEvent.prototype.timestamp = null;

      return WorkflowErrorEvent;
    })();

    v1.DestinationActionErrorEvent = (() => {
      class DestinationActionErrorEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionErrorEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.matchedProfile != null &&
            Object.hasOwnProperty.call(message, 'matchedProfile')
          ) {
            profiles$1.v1.Profile.encode(
              message.matchedProfile,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(34).string(message.eventSnapshotId);
          }
          if (
            message.eventSnapshotEventTime != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotEventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventSnapshotEventTime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.destinationActionNodeId != null &&
            Object.hasOwnProperty.call(message, 'destinationActionNodeId')
          ) {
            writer.uint32(50).string(message.destinationActionNodeId);
          }
          if (
            message.destinationActionName != null &&
            Object.hasOwnProperty.call(message, 'destinationActionName')
          ) {
            writer.uint32(58).string(message.destinationActionName);
          }
          if (
            message.evaluatedParams != null &&
            Object.hasOwnProperty.call(message, 'evaluatedParams')
          ) {
            google$2.protobuf.Struct.encode(
              message.evaluatedParams,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.executionLog != null &&
            Object.hasOwnProperty.call(message, 'executionLog')
          ) {
            google$2.protobuf.Struct.encode(
              message.executionLog,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.destinationActionExecutionStart != null &&
            Object.hasOwnProperty.call(
              message,
              'destinationActionExecutionStart'
            )
          ) {
            timing$1.v1.Timestamp.encode(
              message.destinationActionExecutionStart,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.destinationActionExecutionEnd != null &&
            Object.hasOwnProperty.call(message, 'destinationActionExecutionEnd')
          ) {
            timing$1.v1.Timestamp.encode(
              message.destinationActionExecutionEnd,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.retryNumber != null &&
            Object.hasOwnProperty.call(message, 'retryNumber')
          ) {
            writer.uint32(96).uint64(message.retryNumber);
          }
          if (
            message.expectedDestinationActionExecutionEnd != null &&
            Object.hasOwnProperty.call(
              message,
              'expectedDestinationActionExecutionEnd'
            )
          ) {
            timing$1.v1.Timestamp.encode(
              message.expectedDestinationActionExecutionEnd,
              writer.uint32(106).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionErrorEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.matchedProfile = profiles$1.v1.Profile.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.eventPayload = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 5: {
                message.eventSnapshotEventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.destinationActionNodeId = reader.string();
                break;
              }
              case 7: {
                message.destinationActionName = reader.string();
                break;
              }
              case 8: {
                message.evaluatedParams = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.executionLog = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 10: {
                message.destinationActionExecutionStart =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 11: {
                message.destinationActionExecutionEnd =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 12: {
                message.retryNumber = reader.uint64();
                break;
              }
              case 13: {
                message.expectedDestinationActionExecutionEnd =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.DestinationActionErrorEvent
          ) {
            return object;
          }
          const message = new $root.workflows.v1.DestinationActionErrorEvent();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.matchedProfile != null) {
            if (typeof object.matchedProfile !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.matchedProfile: object expected, but got ' +
                  typeof object.matchedProfile
              );
            }
            message.matchedProfile = profiles$1.v1.Profile.fromObject(
              object.matchedProfile
            );
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload = google$2.protobuf.Struct.fromObject(
              object.eventPayload
            );
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventSnapshotEventTime != null) {
            if (typeof object.eventSnapshotEventTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.eventSnapshotEventTime: object expected, but got ' +
                  typeof object.eventSnapshotEventTime
              );
            }
            message.eventSnapshotEventTime = timing$1.v1.Timestamp.fromObject(
              object.eventSnapshotEventTime
            );
          }
          if (object.destinationActionNodeId != null) {
            message.destinationActionNodeId = String(
              object.destinationActionNodeId
            );
          }
          if (object.destinationActionName != null) {
            message.destinationActionName = String(
              object.destinationActionName
            );
          }
          if (object.evaluatedParams != null) {
            if (typeof object.evaluatedParams !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.evaluatedParams: object expected, but got ' +
                  typeof object.evaluatedParams
              );
            }
            message.evaluatedParams = google$2.protobuf.Struct.fromObject(
              object.evaluatedParams
            );
          }
          if (object.executionLog != null) {
            if (typeof object.executionLog !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.executionLog: object expected, but got ' +
                  typeof object.executionLog
              );
            }
            message.executionLog = google$2.protobuf.Struct.fromObject(
              object.executionLog
            );
          }
          if (object.destinationActionExecutionStart != null) {
            if (typeof object.destinationActionExecutionStart !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.destinationActionExecutionStart: object expected, but got ' +
                  typeof object.destinationActionExecutionStart
              );
            }
            message.destinationActionExecutionStart =
              timing$1.v1.Timestamp.fromObject(
                object.destinationActionExecutionStart
              );
          }
          if (object.destinationActionExecutionEnd != null) {
            if (typeof object.destinationActionExecutionEnd !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.destinationActionExecutionEnd: object expected, but got ' +
                  typeof object.destinationActionExecutionEnd
              );
            }
            message.destinationActionExecutionEnd =
              timing$1.v1.Timestamp.fromObject(
                object.destinationActionExecutionEnd
              );
          }
          if (object.retryNumber != null) {
            if ($util.Long) {
              (message.retryNumber = $util.Long.fromValue(
                object.retryNumber
              )).unsigned = true;
            } else if (typeof object.retryNumber === 'string') {
              message.retryNumber = parseInt(object.retryNumber, 10);
            } else if (typeof object.retryNumber === 'number') {
              message.retryNumber = object.retryNumber;
            } else if (typeof object.retryNumber === 'object') {
              message.retryNumber = new $util.LongBits(
                object.retryNumber.low >>> 0,
                object.retryNumber.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.expectedDestinationActionExecutionEnd != null) {
            if (
              typeof object.expectedDestinationActionExecutionEnd !== 'object'
            ) {
              throw new TypeError(
                '.workflows.v1.DestinationActionErrorEvent.expectedDestinationActionExecutionEnd: object expected, but got ' +
                  typeof object.expectedDestinationActionExecutionEnd
              );
            }
            message.expectedDestinationActionExecutionEnd =
              timing$1.v1.Timestamp.fromObject(
                object.expectedDestinationActionExecutionEnd
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            object.matchedProfile = null;
            object.eventPayload = null;
            object.eventSnapshotId = '';
            object.eventSnapshotEventTime = null;
            object.destinationActionNodeId = '';
            object.destinationActionName = '';
            object.evaluatedParams = null;
            object.executionLog = null;
            object.destinationActionExecutionStart = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.retryNumber =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.retryNumber = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (
            message.matchedProfile != null &&
            message.hasOwnProperty('matchedProfile')
          ) {
            object.matchedProfile = profiles$1.v1.Profile.toObject(
              message.matchedProfile,
              options
            );
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = google$2.protobuf.Struct.toObject(
              message.eventPayload,
              options
            );
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventSnapshotEventTime != null &&
            message.hasOwnProperty('eventSnapshotEventTime')
          ) {
            object.eventSnapshotEventTime = timing$1.v1.Timestamp.toObject(
              message.eventSnapshotEventTime,
              options
            );
          }
          if (
            message.destinationActionNodeId != null &&
            message.hasOwnProperty('destinationActionNodeId')
          ) {
            object.destinationActionNodeId = message.destinationActionNodeId;
          }
          if (
            message.destinationActionName != null &&
            message.hasOwnProperty('destinationActionName')
          ) {
            object.destinationActionName = message.destinationActionName;
          }
          if (
            message.evaluatedParams != null &&
            message.hasOwnProperty('evaluatedParams')
          ) {
            object.evaluatedParams = google$2.protobuf.Struct.toObject(
              message.evaluatedParams,
              options
            );
          }
          if (
            message.executionLog != null &&
            message.hasOwnProperty('executionLog')
          ) {
            object.executionLog = google$2.protobuf.Struct.toObject(
              message.executionLog,
              options
            );
          }
          if (
            message.destinationActionExecutionStart != null &&
            message.hasOwnProperty('destinationActionExecutionStart')
          ) {
            object.destinationActionExecutionStart =
              timing$1.v1.Timestamp.toObject(
                message.destinationActionExecutionStart,
                options
              );
          }
          if (
            message.destinationActionExecutionEnd != null &&
            message.hasOwnProperty('destinationActionExecutionEnd')
          ) {
            object.destinationActionExecutionEnd =
              timing$1.v1.Timestamp.toObject(
                message.destinationActionExecutionEnd,
                options
              );
          }
          if (
            message.retryNumber != null &&
            message.hasOwnProperty('retryNumber')
          ) {
            object.retryNumber =
              typeof message.retryNumber === 'number'
                ? options.longs === String
                  ? String(message.retryNumber)
                  : message.retryNumber
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.retryNumber)
                : options.longs === Number
                ? new $util.LongBits(
                    message.retryNumber.low >>> 0,
                    message.retryNumber.high >>> 0
                  ).toNumber(true)
                : message.retryNumber;
          }
          if (
            message.expectedDestinationActionExecutionEnd != null &&
            message.hasOwnProperty('expectedDestinationActionExecutionEnd')
          ) {
            object.expectedDestinationActionExecutionEnd =
              timing$1.v1.Timestamp.toObject(
                message.expectedDestinationActionExecutionEnd,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.DestinationActionErrorEvent';
        }
      }

      DestinationActionErrorEvent.prototype.workflowExecutionId = '';
      DestinationActionErrorEvent.prototype.matchedProfile = null;
      DestinationActionErrorEvent.prototype.eventPayload = null;
      DestinationActionErrorEvent.prototype.eventSnapshotId = '';
      DestinationActionErrorEvent.prototype.eventSnapshotEventTime = null;
      DestinationActionErrorEvent.prototype.destinationActionNodeId = '';
      DestinationActionErrorEvent.prototype.destinationActionName = '';
      DestinationActionErrorEvent.prototype.evaluatedParams = null;
      DestinationActionErrorEvent.prototype.executionLog = null;
      DestinationActionErrorEvent.prototype.destinationActionExecutionStart =
        null;
      DestinationActionErrorEvent.prototype.destinationActionExecutionEnd =
        null;
      DestinationActionErrorEvent.prototype.retryNumber = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      DestinationActionErrorEvent.prototype.expectedDestinationActionExecutionEnd =
        null;

      return DestinationActionErrorEvent;
    })();

    v1.DestinationActionOutcome = (() => {
      class DestinationActionOutcome {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionOutcome(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationActionName != null &&
            Object.hasOwnProperty.call(message, 'destinationActionName')
          ) {
            writer.uint32(10).string(message.destinationActionName);
          }
          if (
            message.destinationActionErrorApplication != null &&
            Object.hasOwnProperty.call(
              message,
              'destinationActionErrorApplication'
            )
          ) {
            writer.uint32(16).int32(message.destinationActionErrorApplication);
          }
          if (
            message.destinationActionNodeId != null &&
            Object.hasOwnProperty.call(message, 'destinationActionNodeId')
          ) {
            writer.uint32(26).string(message.destinationActionNodeId);
          }
          if (
            message.evaluatedParams != null &&
            Object.hasOwnProperty.call(message, 'evaluatedParams')
          ) {
            google$2.protobuf.Struct.encode(
              message.evaluatedParams,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.executionLog != null &&
            Object.hasOwnProperty.call(message, 'executionLog')
          ) {
            google$2.protobuf.Struct.encode(
              message.executionLog,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.error != null &&
            Object.hasOwnProperty.call(message, 'error')
          ) {
            workflow_evaluation_results$1.v1.FormattedError.encode(
              message.error,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.destinationActionExecutionStart != null &&
            Object.hasOwnProperty.call(
              message,
              'destinationActionExecutionStart'
            )
          ) {
            timing$1.v1.Timestamp.encode(
              message.destinationActionExecutionStart,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.destinationActionExecutionEnd != null &&
            Object.hasOwnProperty.call(message, 'destinationActionExecutionEnd')
          ) {
            timing$1.v1.Timestamp.encode(
              message.destinationActionExecutionEnd,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.retryNumber != null &&
            Object.hasOwnProperty.call(message, 'retryNumber')
          ) {
            writer.uint32(72).uint64(message.retryNumber);
          }
          if (
            message.filterOutcome != null &&
            Object.hasOwnProperty.call(message, 'filterOutcome')
          ) {
            writer.uint32(80).bool(message.filterOutcome);
          }
          if (
            message.expectedDestinationActionExecutionEnd != null &&
            Object.hasOwnProperty.call(
              message,
              'expectedDestinationActionExecutionEnd'
            )
          ) {
            timing$1.v1.Timestamp.encode(
              message.expectedDestinationActionExecutionEnd,
              writer.uint32(90).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionOutcome();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.destinationActionName = reader.string();
                break;
              }
              case 2: {
                message.destinationActionErrorApplication = reader.int32();
                break;
              }
              case 3: {
                message.destinationActionNodeId = reader.string();
                break;
              }
              case 4: {
                message.evaluatedParams = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.executionLog = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.error =
                  workflow_evaluation_results$1.v1.FormattedError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.destinationActionExecutionStart =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 8: {
                message.destinationActionExecutionEnd =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              case 9: {
                message.retryNumber = reader.uint64();
                break;
              }
              case 10: {
                message.filterOutcome = reader.bool();
                break;
              }
              case 11: {
                message.expectedDestinationActionExecutionEnd =
                  timing$1.v1.Timestamp.decode(reader, reader.uint32());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.DestinationActionOutcome) {
            return object;
          }
          const message = new $root.workflows.v1.DestinationActionOutcome();
          if (object.destinationActionName != null) {
            message.destinationActionName = String(
              object.destinationActionName
            );
          }
          if (object.destinationActionErrorApplication != null) {
            switch (object.destinationActionErrorApplication) {
              case 'UNSPECIFIED_DESTINATION_APPLICATION':
              case 0: {
                message.destinationActionErrorApplication = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 1: {
                message.destinationActionErrorApplication = 1;
                break;
              }
              case 'CUSTOMER_IO':
              case 2: {
                message.destinationActionErrorApplication = 2;
                break;
              }
              case 'FACEBOOK':
              case 3: {
                message.destinationActionErrorApplication = 3;
                break;
              }
              case 'GOOGLE':
              case 4: {
                message.destinationActionErrorApplication = 4;
                break;
              }
              case 'HEALTHIE':
              case 5: {
                message.destinationActionErrorApplication = 5;
                break;
              }
              case 'MORF':
              case 6: {
                message.destinationActionErrorApplication = 6;
                break;
              }
              case 'INTAKEQ':
              case 7: {
                message.destinationActionErrorApplication = 7;
                break;
              }
              case 'SEGMENT':
              case 8: {
                message.destinationActionErrorApplication = 8;
                break;
              }
              case 'SPRUCE':
              case 9: {
                message.destinationActionErrorApplication = 9;
                break;
              }
              case 'SLACK':
              case 10: {
                message.destinationActionErrorApplication = 10;
                break;
              }
              case 'FRESHDESK':
              case 11: {
                message.destinationActionErrorApplication = 11;
                break;
              }
              case 'HUBSPOT':
              case 12: {
                message.destinationActionErrorApplication = 12;
                break;
              }
              case 'INTERCOM':
              case 13: {
                message.destinationActionErrorApplication = 13;
                break;
              }
              default: {
                if (
                  typeof object.destinationActionErrorApplication == 'number'
                ) {
                  message.destinationActionErrorApplication =
                    object.destinationActionErrorApplication;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationActionNodeId != null) {
            message.destinationActionNodeId = String(
              object.destinationActionNodeId
            );
          }
          if (object.evaluatedParams != null) {
            if (typeof object.evaluatedParams !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionOutcome.evaluatedParams: object expected, but got ' +
                  typeof object.evaluatedParams
              );
            }
            message.evaluatedParams = google$2.protobuf.Struct.fromObject(
              object.evaluatedParams
            );
          }
          if (object.executionLog != null) {
            if (typeof object.executionLog !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionOutcome.executionLog: object expected, but got ' +
                  typeof object.executionLog
              );
            }
            message.executionLog = google$2.protobuf.Struct.fromObject(
              object.executionLog
            );
          }
          if (object.error != null) {
            if (typeof object.error !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionOutcome.error: object expected, but got ' +
                  typeof object.error
              );
            }
            message.error =
              workflow_evaluation_results$1.v1.FormattedError.fromObject(
                object.error
              );
          }
          if (object.destinationActionExecutionStart != null) {
            if (typeof object.destinationActionExecutionStart !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionOutcome.destinationActionExecutionStart: object expected, but got ' +
                  typeof object.destinationActionExecutionStart
              );
            }
            message.destinationActionExecutionStart =
              timing$1.v1.Timestamp.fromObject(
                object.destinationActionExecutionStart
              );
          }
          if (object.destinationActionExecutionEnd != null) {
            if (typeof object.destinationActionExecutionEnd !== 'object') {
              throw new TypeError(
                '.workflows.v1.DestinationActionOutcome.destinationActionExecutionEnd: object expected, but got ' +
                  typeof object.destinationActionExecutionEnd
              );
            }
            message.destinationActionExecutionEnd =
              timing$1.v1.Timestamp.fromObject(
                object.destinationActionExecutionEnd
              );
          }
          if (object.retryNumber != null) {
            if ($util.Long) {
              (message.retryNumber = $util.Long.fromValue(
                object.retryNumber
              )).unsigned = true;
            } else if (typeof object.retryNumber === 'string') {
              message.retryNumber = parseInt(object.retryNumber, 10);
            } else if (typeof object.retryNumber === 'number') {
              message.retryNumber = object.retryNumber;
            } else if (typeof object.retryNumber === 'object') {
              message.retryNumber = new $util.LongBits(
                object.retryNumber.low >>> 0,
                object.retryNumber.high >>> 0
              ).toNumber(true);
            }
          }
          if (object.filterOutcome != null) {
            message.filterOutcome = Boolean(object.filterOutcome);
          }
          if (object.expectedDestinationActionExecutionEnd != null) {
            if (
              typeof object.expectedDestinationActionExecutionEnd !== 'object'
            ) {
              throw new TypeError(
                '.workflows.v1.DestinationActionOutcome.expectedDestinationActionExecutionEnd: object expected, but got ' +
                  typeof object.expectedDestinationActionExecutionEnd
              );
            }
            message.expectedDestinationActionExecutionEnd =
              timing$1.v1.Timestamp.fromObject(
                object.expectedDestinationActionExecutionEnd
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.destinationActionName = '';
            object.destinationActionErrorApplication =
              options.enums === String
                ? 'UNSPECIFIED_DESTINATION_APPLICATION'
                : 0;
            object.destinationActionNodeId = '';
            object.evaluatedParams = null;
            object.executionLog = null;
            object.destinationActionExecutionStart = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, true);
              object.retryNumber =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.retryNumber = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.destinationActionName != null &&
            message.hasOwnProperty('destinationActionName')
          ) {
            object.destinationActionName = message.destinationActionName;
          }
          if (
            message.destinationActionErrorApplication != null &&
            message.hasOwnProperty('destinationActionErrorApplication')
          ) {
            object.destinationActionErrorApplication =
              options.enums === String
                ? workflow_destination_applications$1.v1.DestinationApplication
                    .Application[message.destinationActionErrorApplication] ===
                  undefined
                  ? message.destinationActionErrorApplication
                  : workflow_destination_applications$1.v1
                      .DestinationApplication.Application[
                      message.destinationActionErrorApplication
                    ]
                : message.destinationActionErrorApplication;
          }
          if (
            message.destinationActionNodeId != null &&
            message.hasOwnProperty('destinationActionNodeId')
          ) {
            object.destinationActionNodeId = message.destinationActionNodeId;
          }
          if (
            message.evaluatedParams != null &&
            message.hasOwnProperty('evaluatedParams')
          ) {
            object.evaluatedParams = google$2.protobuf.Struct.toObject(
              message.evaluatedParams,
              options
            );
          }
          if (
            message.executionLog != null &&
            message.hasOwnProperty('executionLog')
          ) {
            object.executionLog = google$2.protobuf.Struct.toObject(
              message.executionLog,
              options
            );
          }
          if (message.error != null && message.hasOwnProperty('error')) {
            object.error =
              workflow_evaluation_results$1.v1.FormattedError.toObject(
                message.error,
                options
              );
          }
          if (
            message.destinationActionExecutionStart != null &&
            message.hasOwnProperty('destinationActionExecutionStart')
          ) {
            object.destinationActionExecutionStart =
              timing$1.v1.Timestamp.toObject(
                message.destinationActionExecutionStart,
                options
              );
          }
          if (
            message.destinationActionExecutionEnd != null &&
            message.hasOwnProperty('destinationActionExecutionEnd')
          ) {
            object.destinationActionExecutionEnd =
              timing$1.v1.Timestamp.toObject(
                message.destinationActionExecutionEnd,
                options
              );
          }
          if (
            message.retryNumber != null &&
            message.hasOwnProperty('retryNumber')
          ) {
            object.retryNumber =
              typeof message.retryNumber === 'number'
                ? options.longs === String
                  ? String(message.retryNumber)
                  : message.retryNumber
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.retryNumber)
                : options.longs === Number
                ? new $util.LongBits(
                    message.retryNumber.low >>> 0,
                    message.retryNumber.high >>> 0
                  ).toNumber(true)
                : message.retryNumber;
          }
          if (
            message.filterOutcome != null &&
            message.hasOwnProperty('filterOutcome')
          ) {
            object.filterOutcome = message.filterOutcome;
          }
          if (
            message.expectedDestinationActionExecutionEnd != null &&
            message.hasOwnProperty('expectedDestinationActionExecutionEnd')
          ) {
            object.expectedDestinationActionExecutionEnd =
              timing$1.v1.Timestamp.toObject(
                message.expectedDestinationActionExecutionEnd,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.DestinationActionOutcome';
        }
      }

      DestinationActionOutcome.prototype.destinationActionName = '';
      DestinationActionOutcome.prototype.destinationActionErrorApplication = 0;
      DestinationActionOutcome.prototype.destinationActionNodeId = '';
      DestinationActionOutcome.prototype.evaluatedParams = null;
      DestinationActionOutcome.prototype.executionLog = null;
      DestinationActionOutcome.prototype.error = null;
      DestinationActionOutcome.prototype.destinationActionExecutionStart = null;
      DestinationActionOutcome.prototype.destinationActionExecutionEnd = null;
      DestinationActionOutcome.prototype.retryNumber = $util.Long
        ? $util.Long.fromBits(0, 0, true)
        : 0;
      DestinationActionOutcome.prototype.filterOutcome = null;
      DestinationActionOutcome.prototype.expectedDestinationActionExecutionEnd =
        null;

      return DestinationActionOutcome;
    })();

    v1.WorkflowExecutionDestinationActionOutcome = (() => {
      class WorkflowExecutionDestinationActionOutcome {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowExecutionDestinationActionOutcome(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.workflowIdSummary != null &&
            Object.hasOwnProperty.call(message, 'workflowIdSummary')
          ) {
            $root.workflows.v1.WorkflowIdSummary.encode(
              message.workflowIdSummary,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(34).string(message.eventSnapshotId);
          }
          if (
            message.eventSnapshotEventTime != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotEventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventSnapshotEventTime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.outcome != null &&
            Object.hasOwnProperty.call(message, 'outcome')
          ) {
            $root.workflows.v1.DestinationActionOutcome.encode(
              message.outcome,
              writer.uint32(50).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowExecutionDestinationActionOutcome();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.workflowIdSummary =
                  $root.workflows.v1.WorkflowIdSummary.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.eventPayload = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 5: {
                message.eventSnapshotEventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.outcome =
                  $root.workflows.v1.DestinationActionOutcome.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.WorkflowExecutionDestinationActionOutcome
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.WorkflowExecutionDestinationActionOutcome();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.workflowIdSummary != null) {
            if (typeof object.workflowIdSummary !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionDestinationActionOutcome.workflowIdSummary: object expected, but got ' +
                  typeof object.workflowIdSummary
              );
            }
            message.workflowIdSummary =
              $root.workflows.v1.WorkflowIdSummary.fromObject(
                object.workflowIdSummary
              );
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionDestinationActionOutcome.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload = google$2.protobuf.Struct.fromObject(
              object.eventPayload
            );
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventSnapshotEventTime != null) {
            if (typeof object.eventSnapshotEventTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionDestinationActionOutcome.eventSnapshotEventTime: object expected, but got ' +
                  typeof object.eventSnapshotEventTime
              );
            }
            message.eventSnapshotEventTime = timing$1.v1.Timestamp.fromObject(
              object.eventSnapshotEventTime
            );
          }
          if (object.outcome != null) {
            if (typeof object.outcome !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowExecutionDestinationActionOutcome.outcome: object expected, but got ' +
                  typeof object.outcome
              );
            }
            message.outcome =
              $root.workflows.v1.DestinationActionOutcome.fromObject(
                object.outcome
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            object.workflowIdSummary = null;
            object.eventPayload = null;
            object.eventSnapshotId = '';
            object.eventSnapshotEventTime = null;
            object.outcome = null;
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (
            message.workflowIdSummary != null &&
            message.hasOwnProperty('workflowIdSummary')
          ) {
            object.workflowIdSummary =
              $root.workflows.v1.WorkflowIdSummary.toObject(
                message.workflowIdSummary,
                options
              );
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = google$2.protobuf.Struct.toObject(
              message.eventPayload,
              options
            );
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventSnapshotEventTime != null &&
            message.hasOwnProperty('eventSnapshotEventTime')
          ) {
            object.eventSnapshotEventTime = timing$1.v1.Timestamp.toObject(
              message.eventSnapshotEventTime,
              options
            );
          }
          if (message.outcome != null && message.hasOwnProperty('outcome')) {
            object.outcome =
              $root.workflows.v1.DestinationActionOutcome.toObject(
                message.outcome,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflows.v1.WorkflowExecutionDestinationActionOutcome'
          );
        }
      }

      WorkflowExecutionDestinationActionOutcome.prototype.workflowExecutionId =
        '';
      WorkflowExecutionDestinationActionOutcome.prototype.workflowIdSummary =
        null;
      WorkflowExecutionDestinationActionOutcome.prototype.eventPayload = null;
      WorkflowExecutionDestinationActionOutcome.prototype.eventSnapshotId = '';
      WorkflowExecutionDestinationActionOutcome.prototype.eventSnapshotEventTime =
        null;
      WorkflowExecutionDestinationActionOutcome.prototype.outcome = null;

      return WorkflowExecutionDestinationActionOutcome;
    })();

    v1.InternalWorkflowExecutionIds = (() => {
      class InternalWorkflowExecutionIds {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InternalWorkflowExecutionIds(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.temporalWorkflowId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowId')
          ) {
            writer.uint32(10).string(message.temporalWorkflowId);
          }
          if (
            message.temporalWorkflowRunId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowRunId')
          ) {
            writer.uint32(18).string(message.temporalWorkflowRunId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InternalWorkflowExecutionIds();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.temporalWorkflowId = reader.string();
                break;
              }
              case 2: {
                message.temporalWorkflowRunId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.InternalWorkflowExecutionIds
          ) {
            return object;
          }
          const message = new $root.workflows.v1.InternalWorkflowExecutionIds();
          if (object.temporalWorkflowId != null) {
            message.temporalWorkflowId = String(object.temporalWorkflowId);
          }
          if (object.temporalWorkflowRunId != null) {
            message.temporalWorkflowRunId = String(
              object.temporalWorkflowRunId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.temporalWorkflowId = '';
            object.temporalWorkflowRunId = '';
          }
          let keys;
          if (
            message.temporalWorkflowId != null &&
            message.hasOwnProperty('temporalWorkflowId')
          ) {
            object.temporalWorkflowId = message.temporalWorkflowId;
          }
          if (
            message.temporalWorkflowRunId != null &&
            message.hasOwnProperty('temporalWorkflowRunId')
          ) {
            object.temporalWorkflowRunId = message.temporalWorkflowRunId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.InternalWorkflowExecutionIds';
        }
      }

      InternalWorkflowExecutionIds.prototype.temporalWorkflowId = '';
      InternalWorkflowExecutionIds.prototype.temporalWorkflowRunId = '';

      return InternalWorkflowExecutionIds;
    })();

    v1.WorkflowEvent = (() => {
      class WorkflowEvent {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.workflowIdSummary != null &&
            Object.hasOwnProperty.call(message, 'workflowIdSummary')
          ) {
            $root.workflows.v1.WorkflowIdSummary.encode(
              message.workflowIdSummary,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(34).string(message.eventSnapshotId);
          }
          if (
            message.eventSnapshotEventTime != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotEventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventSnapshotEventTime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.executedWorkflowIds != null &&
            Object.hasOwnProperty.call(message, 'executedWorkflowIds')
          ) {
            $root.workflows.v1.InternalWorkflowExecutionIds.encode(
              message.executedWorkflowIds,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.triggerExecutionTime != null &&
            Object.hasOwnProperty.call(message, 'triggerExecutionTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.triggerExecutionTime,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.errored != null &&
            Object.hasOwnProperty.call(message, 'errored')
          ) {
            writer.uint32(64).bool(message.errored);
          }
          if (
            message.executionInProgress != null &&
            Object.hasOwnProperty.call(message, 'executionInProgress')
          ) {
            writer.uint32(72).bool(message.executionInProgress);
          }
          if (
            message.formattedError != null &&
            Object.hasOwnProperty.call(message, 'formattedError')
          ) {
            workflow_evaluation_results$1.v1.FormattedError.encode(
              message.formattedError,
              writer.uint32(82).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.workflowIdSummary =
                  $root.workflows.v1.WorkflowIdSummary.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.eventPayload = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 5: {
                message.eventSnapshotEventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.executedWorkflowIds =
                  $root.workflows.v1.InternalWorkflowExecutionIds.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.triggerExecutionTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.errored = reader.bool();
                break;
              }
              case 9: {
                message.executionInProgress = reader.bool();
                break;
              }
              case 10: {
                message.formattedError =
                  workflow_evaluation_results$1.v1.FormattedError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowEvent) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowEvent();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.workflowIdSummary != null) {
            if (typeof object.workflowIdSummary !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowEvent.workflowIdSummary: object expected, but got ' +
                  typeof object.workflowIdSummary
              );
            }
            message.workflowIdSummary =
              $root.workflows.v1.WorkflowIdSummary.fromObject(
                object.workflowIdSummary
              );
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowEvent.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload = google$2.protobuf.Struct.fromObject(
              object.eventPayload
            );
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventSnapshotEventTime != null) {
            if (typeof object.eventSnapshotEventTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowEvent.eventSnapshotEventTime: object expected, but got ' +
                  typeof object.eventSnapshotEventTime
              );
            }
            message.eventSnapshotEventTime = timing$1.v1.Timestamp.fromObject(
              object.eventSnapshotEventTime
            );
          }
          if (object.executedWorkflowIds != null) {
            if (typeof object.executedWorkflowIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowEvent.executedWorkflowIds: object expected, but got ' +
                  typeof object.executedWorkflowIds
              );
            }
            message.executedWorkflowIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.fromObject(
                object.executedWorkflowIds
              );
          }
          if (object.triggerExecutionTime != null) {
            if (typeof object.triggerExecutionTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowEvent.triggerExecutionTime: object expected, but got ' +
                  typeof object.triggerExecutionTime
              );
            }
            message.triggerExecutionTime = timing$1.v1.Timestamp.fromObject(
              object.triggerExecutionTime
            );
          }
          if (object.errored != null) {
            message.errored = Boolean(object.errored);
          }
          if (object.executionInProgress != null) {
            message.executionInProgress = Boolean(object.executionInProgress);
          }
          if (object.formattedError != null) {
            if (typeof object.formattedError !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowEvent.formattedError: object expected, but got ' +
                  typeof object.formattedError
              );
            }
            message.formattedError =
              workflow_evaluation_results$1.v1.FormattedError.fromObject(
                object.formattedError
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            object.workflowIdSummary = null;
            object.eventPayload = null;
            object.eventSnapshotId = '';
            object.eventSnapshotEventTime = null;
            object.triggerExecutionTime = null;
            object.errored = false;
            object.executionInProgress = false;
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (
            message.workflowIdSummary != null &&
            message.hasOwnProperty('workflowIdSummary')
          ) {
            object.workflowIdSummary =
              $root.workflows.v1.WorkflowIdSummary.toObject(
                message.workflowIdSummary,
                options
              );
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = google$2.protobuf.Struct.toObject(
              message.eventPayload,
              options
            );
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventSnapshotEventTime != null &&
            message.hasOwnProperty('eventSnapshotEventTime')
          ) {
            object.eventSnapshotEventTime = timing$1.v1.Timestamp.toObject(
              message.eventSnapshotEventTime,
              options
            );
          }
          if (
            message.executedWorkflowIds != null &&
            message.hasOwnProperty('executedWorkflowIds')
          ) {
            object.executedWorkflowIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.toObject(
                message.executedWorkflowIds,
                options
              );
          }
          if (
            message.triggerExecutionTime != null &&
            message.hasOwnProperty('triggerExecutionTime')
          ) {
            object.triggerExecutionTime = timing$1.v1.Timestamp.toObject(
              message.triggerExecutionTime,
              options
            );
          }
          if (message.errored != null && message.hasOwnProperty('errored')) {
            object.errored = message.errored;
          }
          if (
            message.executionInProgress != null &&
            message.hasOwnProperty('executionInProgress')
          ) {
            object.executionInProgress = message.executionInProgress;
          }
          if (
            message.formattedError != null &&
            message.hasOwnProperty('formattedError')
          ) {
            object.formattedError =
              workflow_evaluation_results$1.v1.FormattedError.toObject(
                message.formattedError,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowEvent';
        }
      }

      WorkflowEvent.prototype.workflowExecutionId = '';
      WorkflowEvent.prototype.workflowIdSummary = null;
      WorkflowEvent.prototype.eventPayload = null;
      WorkflowEvent.prototype.eventSnapshotId = '';
      WorkflowEvent.prototype.eventSnapshotEventTime = null;
      WorkflowEvent.prototype.executedWorkflowIds = null;
      WorkflowEvent.prototype.triggerExecutionTime = null;
      WorkflowEvent.prototype.errored = false;
      WorkflowEvent.prototype.executionInProgress = false;
      WorkflowEvent.prototype.formattedError = null;

      return WorkflowEvent;
    })();

    v1.WorkflowIdEvent = (() => {
      class WorkflowIdEvent {
        constructor(properties) {
          this.errorEventsData = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowIdEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.workflowVersion != null &&
            Object.hasOwnProperty.call(message, 'workflowVersion')
          ) {
            writer.uint32(16).int64(message.workflowVersion);
          }
          if (
            message.eventPayload != null &&
            Object.hasOwnProperty.call(message, 'eventPayload')
          ) {
            google$2.protobuf.Struct.encode(
              message.eventPayload,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotId')
          ) {
            writer.uint32(34).string(message.eventSnapshotId);
          }
          if (
            message.eventSnapshotEventTime != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshotEventTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.eventSnapshotEventTime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.executedWorkflowIds != null &&
            Object.hasOwnProperty.call(message, 'executedWorkflowIds')
          ) {
            $root.workflows.v1.InternalWorkflowExecutionIds.encode(
              message.executedWorkflowIds,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.profileLookupResult != null &&
            Object.hasOwnProperty.call(message, 'profileLookupResult')
          ) {
            google$2.protobuf.Struct.encode(
              message.profileLookupResult,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.profileLookupEndTime != null &&
            Object.hasOwnProperty.call(message, 'profileLookupEndTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.profileLookupEndTime,
              writer.uint32(66).fork()
            ).ldelim();
          }
          if (
            message.matchedProfileIndex != null &&
            Object.hasOwnProperty.call(message, 'matchedProfileIndex')
          ) {
            profiles$1.v1.ProfileIndex.encode(
              message.matchedProfileIndex,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.triggerExecutionTime != null &&
            Object.hasOwnProperty.call(message, 'triggerExecutionTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.triggerExecutionTime,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.errored != null &&
            Object.hasOwnProperty.call(message, 'errored')
          ) {
            writer.uint32(88).bool(message.errored);
          }
          if (
            message.executionInProgress != null &&
            Object.hasOwnProperty.call(message, 'executionInProgress')
          ) {
            writer.uint32(96).bool(message.executionInProgress);
          }
          if (
            message.formattedError != null &&
            Object.hasOwnProperty.call(message, 'formattedError')
          ) {
            workflow_evaluation_results$1.v1.FormattedError.encode(
              message.formattedError,
              writer.uint32(106).fork()
            ).ldelim();
          }
          if (
            message.errorEventsData != null &&
            Object.hasOwnProperty.call(message, 'errorEventsData')
          ) {
            for (const element of message.errorEventsData) {
              workflow_evaluation_results$1.v1.WorkflowEvent.encode(
                element,
                writer.uint32(114).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowIdEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.workflowVersion = reader.int64();
                break;
              }
              case 3: {
                message.eventPayload = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 4: {
                message.eventSnapshotId = reader.string();
                break;
              }
              case 5: {
                message.eventSnapshotEventTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.executedWorkflowIds =
                  $root.workflows.v1.InternalWorkflowExecutionIds.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.profileLookupResult = google$2.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.profileLookupEndTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 9: {
                message.matchedProfileIndex = profiles$1.v1.ProfileIndex.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 10: {
                message.triggerExecutionTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 11: {
                message.errored = reader.bool();
                break;
              }
              case 12: {
                message.executionInProgress = reader.bool();
                break;
              }
              case 13: {
                message.formattedError =
                  workflow_evaluation_results$1.v1.FormattedError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 14: {
                if (
                  !message.errorEventsData ||
                  !message.errorEventsData.length
                ) {
                  message.errorEventsData = [];
                }
                message.errorEventsData.push(
                  workflow_evaluation_results$1.v1.WorkflowEvent.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.WorkflowIdEvent) {
            return object;
          }
          const message = new $root.workflows.v1.WorkflowIdEvent();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.workflowVersion != null) {
            if ($util.Long) {
              (message.workflowVersion = $util.Long.fromValue(
                object.workflowVersion
              )).unsigned = false;
            } else if (typeof object.workflowVersion === 'string') {
              message.workflowVersion = parseInt(object.workflowVersion, 10);
            } else if (typeof object.workflowVersion === 'number') {
              message.workflowVersion = object.workflowVersion;
            } else if (typeof object.workflowVersion === 'object') {
              message.workflowVersion = new $util.LongBits(
                object.workflowVersion.low >>> 0,
                object.workflowVersion.high >>> 0
              ).toNumber();
            }
          }
          if (object.eventPayload != null) {
            if (typeof object.eventPayload !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.eventPayload: object expected, but got ' +
                  typeof object.eventPayload
              );
            }
            message.eventPayload = google$2.protobuf.Struct.fromObject(
              object.eventPayload
            );
          }
          if (object.eventSnapshotId != null) {
            message.eventSnapshotId = String(object.eventSnapshotId);
          }
          if (object.eventSnapshotEventTime != null) {
            if (typeof object.eventSnapshotEventTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.eventSnapshotEventTime: object expected, but got ' +
                  typeof object.eventSnapshotEventTime
              );
            }
            message.eventSnapshotEventTime = timing$1.v1.Timestamp.fromObject(
              object.eventSnapshotEventTime
            );
          }
          if (object.executedWorkflowIds != null) {
            if (typeof object.executedWorkflowIds !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.executedWorkflowIds: object expected, but got ' +
                  typeof object.executedWorkflowIds
              );
            }
            message.executedWorkflowIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.fromObject(
                object.executedWorkflowIds
              );
          }
          if (object.profileLookupResult != null) {
            if (typeof object.profileLookupResult !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.profileLookupResult: object expected, but got ' +
                  typeof object.profileLookupResult
              );
            }
            message.profileLookupResult = google$2.protobuf.Struct.fromObject(
              object.profileLookupResult
            );
          }
          if (object.profileLookupEndTime != null) {
            if (typeof object.profileLookupEndTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.profileLookupEndTime: object expected, but got ' +
                  typeof object.profileLookupEndTime
              );
            }
            message.profileLookupEndTime = timing$1.v1.Timestamp.fromObject(
              object.profileLookupEndTime
            );
          }
          if (object.matchedProfileIndex != null) {
            if (typeof object.matchedProfileIndex !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.matchedProfileIndex: object expected, but got ' +
                  typeof object.matchedProfileIndex
              );
            }
            message.matchedProfileIndex = profiles$1.v1.ProfileIndex.fromObject(
              object.matchedProfileIndex
            );
          }
          if (object.triggerExecutionTime != null) {
            if (typeof object.triggerExecutionTime !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.triggerExecutionTime: object expected, but got ' +
                  typeof object.triggerExecutionTime
              );
            }
            message.triggerExecutionTime = timing$1.v1.Timestamp.fromObject(
              object.triggerExecutionTime
            );
          }
          if (object.errored != null) {
            message.errored = Boolean(object.errored);
          }
          if (object.executionInProgress != null) {
            message.executionInProgress = Boolean(object.executionInProgress);
          }
          if (object.formattedError != null) {
            if (typeof object.formattedError !== 'object') {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.formattedError: object expected, but got ' +
                  typeof object.formattedError
              );
            }
            message.formattedError =
              workflow_evaluation_results$1.v1.FormattedError.fromObject(
                object.formattedError
              );
          }
          if (object.errorEventsData) {
            if (!Array.isArray(object.errorEventsData)) {
              throw new TypeError(
                '.workflows.v1.WorkflowIdEvent.errorEventsData: array type expected, but got ' +
                  typeof object.errorEventsData
              );
            }
            message.errorEventsData = new Array(object.errorEventsData.length);
            for (let i = 0; i < object.errorEventsData.length; ++i) {
              if (typeof object.errorEventsData[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.WorkflowIdEvent.errorEventsData: object expected, but got ' +
                    typeof object.errorEventsData[i]
                );
              }
              message.errorEventsData[i] =
                workflow_evaluation_results$1.v1.WorkflowEvent.fromObject(
                  object.errorEventsData[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errorEventsData = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.workflowVersion =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.workflowVersion = options.longs === String ? '0' : 0;
            }
            object.eventPayload = null;
            object.eventSnapshotId = '';
            object.eventSnapshotEventTime = null;
            object.triggerExecutionTime = null;
            object.errored = false;
            object.executionInProgress = false;
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (
            message.workflowVersion != null &&
            message.hasOwnProperty('workflowVersion')
          ) {
            object.workflowVersion =
              typeof message.workflowVersion === 'number'
                ? options.longs === String
                  ? String(message.workflowVersion)
                  : message.workflowVersion
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.workflowVersion)
                : options.longs === Number
                ? new $util.LongBits(
                    message.workflowVersion.low >>> 0,
                    message.workflowVersion.high >>> 0
                  ).toNumber()
                : message.workflowVersion;
          }
          if (
            message.eventPayload != null &&
            message.hasOwnProperty('eventPayload')
          ) {
            object.eventPayload = google$2.protobuf.Struct.toObject(
              message.eventPayload,
              options
            );
          }
          if (
            message.eventSnapshotId != null &&
            message.hasOwnProperty('eventSnapshotId')
          ) {
            object.eventSnapshotId = message.eventSnapshotId;
          }
          if (
            message.eventSnapshotEventTime != null &&
            message.hasOwnProperty('eventSnapshotEventTime')
          ) {
            object.eventSnapshotEventTime = timing$1.v1.Timestamp.toObject(
              message.eventSnapshotEventTime,
              options
            );
          }
          if (
            message.executedWorkflowIds != null &&
            message.hasOwnProperty('executedWorkflowIds')
          ) {
            object.executedWorkflowIds =
              $root.workflows.v1.InternalWorkflowExecutionIds.toObject(
                message.executedWorkflowIds,
                options
              );
          }
          if (
            message.profileLookupResult != null &&
            message.hasOwnProperty('profileLookupResult')
          ) {
            object.profileLookupResult = google$2.protobuf.Struct.toObject(
              message.profileLookupResult,
              options
            );
          }
          if (
            message.profileLookupEndTime != null &&
            message.hasOwnProperty('profileLookupEndTime')
          ) {
            object.profileLookupEndTime = timing$1.v1.Timestamp.toObject(
              message.profileLookupEndTime,
              options
            );
          }
          if (
            message.matchedProfileIndex != null &&
            message.hasOwnProperty('matchedProfileIndex')
          ) {
            object.matchedProfileIndex = profiles$1.v1.ProfileIndex.toObject(
              message.matchedProfileIndex,
              options
            );
          }
          if (
            message.triggerExecutionTime != null &&
            message.hasOwnProperty('triggerExecutionTime')
          ) {
            object.triggerExecutionTime = timing$1.v1.Timestamp.toObject(
              message.triggerExecutionTime,
              options
            );
          }
          if (message.errored != null && message.hasOwnProperty('errored')) {
            object.errored = message.errored;
          }
          if (
            message.executionInProgress != null &&
            message.hasOwnProperty('executionInProgress')
          ) {
            object.executionInProgress = message.executionInProgress;
          }
          if (
            message.formattedError != null &&
            message.hasOwnProperty('formattedError')
          ) {
            object.formattedError =
              workflow_evaluation_results$1.v1.FormattedError.toObject(
                message.formattedError,
                options
              );
          }
          if (message.errorEventsData && message.errorEventsData.length) {
            object.errorEventsData = new Array(message.errorEventsData.length);
            for (let i = 0; i < message.errorEventsData.length; ++i) {
              object.errorEventsData[i] =
                workflow_evaluation_results$1.v1.WorkflowEvent.toObject(
                  message.errorEventsData[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.WorkflowIdEvent';
        }
      }

      WorkflowIdEvent.prototype.workflowExecutionId = '';
      WorkflowIdEvent.prototype.workflowVersion = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      WorkflowIdEvent.prototype.eventPayload = null;
      WorkflowIdEvent.prototype.eventSnapshotId = '';
      WorkflowIdEvent.prototype.eventSnapshotEventTime = null;
      WorkflowIdEvent.prototype.executedWorkflowIds = null;
      WorkflowIdEvent.prototype.profileLookupResult = null;
      WorkflowIdEvent.prototype.profileLookupEndTime = null;
      WorkflowIdEvent.prototype.matchedProfileIndex = null;
      WorkflowIdEvent.prototype.triggerExecutionTime = null;
      WorkflowIdEvent.prototype.errored = false;
      WorkflowIdEvent.prototype.executionInProgress = false;
      WorkflowIdEvent.prototype.formattedError = null;
      WorkflowIdEvent.prototype.errorEventsData = $util.emptyArray;

      return WorkflowIdEvent;
    })();

    v1.GlobalLog = (() => {
      class GlobalLog {
        get type() {
          for (const key of [
            'skippedWebhook',
            'eventSnapshot',
            'workflowTriggerEvaluation',
            'workflowExecution',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'skippedWebhook',
            'eventSnapshot',
            'workflowTriggerEvaluation',
            'workflowExecution',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GlobalLog(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.logEventTimestamp != null &&
            Object.hasOwnProperty.call(message, 'logEventTimestamp')
          ) {
            timing$1.v1.Timestamp.encode(
              message.logEventTimestamp,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.skippedWebhook != null &&
            Object.hasOwnProperty.call(message, 'skippedWebhook')
          ) {
            $root.workflows.v1.GlobalLog.WebhookRequest.encode(
              message.skippedWebhook,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.eventSnapshot != null &&
            Object.hasOwnProperty.call(message, 'eventSnapshot')
          ) {
            $root.workflows.v1.GlobalLog.EventSnapshot.encode(
              message.eventSnapshot,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.workflowTriggerEvaluation != null &&
            Object.hasOwnProperty.call(message, 'workflowTriggerEvaluation')
          ) {
            $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.encode(
              message.workflowTriggerEvaluation,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.workflowExecution != null &&
            Object.hasOwnProperty.call(message, 'workflowExecution')
          ) {
            $root.workflows.v1.GlobalLog.WorkflowExecution.encode(
              message.workflowExecution,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GlobalLog();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.logEventTimestamp = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.skippedWebhook =
                  $root.workflows.v1.GlobalLog.WebhookRequest.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.eventSnapshot =
                  $root.workflows.v1.GlobalLog.EventSnapshot.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.workflowTriggerEvaluation =
                  $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.workflowExecution =
                  $root.workflows.v1.GlobalLog.WorkflowExecution.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GlobalLog) {
            return object;
          }
          const message = new $root.workflows.v1.GlobalLog();
          if (object.logEventTimestamp != null) {
            if (typeof object.logEventTimestamp !== 'object') {
              throw new TypeError(
                '.workflows.v1.GlobalLog.logEventTimestamp: object expected, but got ' +
                  typeof object.logEventTimestamp
              );
            }
            message.logEventTimestamp = timing$1.v1.Timestamp.fromObject(
              object.logEventTimestamp
            );
          }
          if (object.skippedWebhook != null) {
            if (typeof object.skippedWebhook !== 'object') {
              throw new TypeError(
                '.workflows.v1.GlobalLog.skippedWebhook: object expected, but got ' +
                  typeof object.skippedWebhook
              );
            }
            message.skippedWebhook =
              $root.workflows.v1.GlobalLog.WebhookRequest.fromObject(
                object.skippedWebhook
              );
          }
          if (object.eventSnapshot != null) {
            if (typeof object.eventSnapshot !== 'object') {
              throw new TypeError(
                '.workflows.v1.GlobalLog.eventSnapshot: object expected, but got ' +
                  typeof object.eventSnapshot
              );
            }
            message.eventSnapshot =
              $root.workflows.v1.GlobalLog.EventSnapshot.fromObject(
                object.eventSnapshot
              );
          }
          if (object.workflowTriggerEvaluation != null) {
            if (typeof object.workflowTriggerEvaluation !== 'object') {
              throw new TypeError(
                '.workflows.v1.GlobalLog.workflowTriggerEvaluation: object expected, but got ' +
                  typeof object.workflowTriggerEvaluation
              );
            }
            message.workflowTriggerEvaluation =
              $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.fromObject(
                object.workflowTriggerEvaluation
              );
          }
          if (object.workflowExecution != null) {
            if (typeof object.workflowExecution !== 'object') {
              throw new TypeError(
                '.workflows.v1.GlobalLog.workflowExecution: object expected, but got ' +
                  typeof object.workflowExecution
              );
            }
            message.workflowExecution =
              $root.workflows.v1.GlobalLog.WorkflowExecution.fromObject(
                object.workflowExecution
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.logEventTimestamp = null;
          }
          let keys;
          if (
            message.logEventTimestamp != null &&
            message.hasOwnProperty('logEventTimestamp')
          ) {
            object.logEventTimestamp = timing$1.v1.Timestamp.toObject(
              message.logEventTimestamp,
              options
            );
          }
          if (
            message.skippedWebhook != null &&
            message.hasOwnProperty('skippedWebhook')
          ) {
            object.skippedWebhook =
              $root.workflows.v1.GlobalLog.WebhookRequest.toObject(
                message.skippedWebhook,
                options
              );
            if (options.oneofs) {
              object.type = 'skippedWebhook';
            }
          }
          if (
            message.eventSnapshot != null &&
            message.hasOwnProperty('eventSnapshot')
          ) {
            object.eventSnapshot =
              $root.workflows.v1.GlobalLog.EventSnapshot.toObject(
                message.eventSnapshot,
                options
              );
            if (options.oneofs) {
              object.type = 'eventSnapshot';
            }
          }
          if (
            message.workflowTriggerEvaluation != null &&
            message.hasOwnProperty('workflowTriggerEvaluation')
          ) {
            object.workflowTriggerEvaluation =
              $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.toObject(
                message.workflowTriggerEvaluation,
                options
              );
            if (options.oneofs) {
              object.type = 'workflowTriggerEvaluation';
            }
          }
          if (
            message.workflowExecution != null &&
            message.hasOwnProperty('workflowExecution')
          ) {
            object.workflowExecution =
              $root.workflows.v1.GlobalLog.WorkflowExecution.toObject(
                message.workflowExecution,
                options
              );
            if (options.oneofs) {
              object.type = 'workflowExecution';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GlobalLog';
        }
      }

      GlobalLog.prototype.logEventTimestamp = null;
      GlobalLog.prototype.skippedWebhook = null;
      GlobalLog.prototype.eventSnapshot = null;
      GlobalLog.prototype.workflowTriggerEvaluation = null;
      GlobalLog.prototype.workflowExecution = null;
      GlobalLog.WebhookRequest = (() => {
        class WebhookRequest {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WebhookRequest(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.id != null &&
              Object.hasOwnProperty.call(message, 'id')
            ) {
              writer.uint32(10).string(message.id);
            }
            if (
              message.sourceId != null &&
              Object.hasOwnProperty.call(message, 'sourceId')
            ) {
              writer.uint32(18).string(message.sourceId);
            }
            if (
              message.sourceApplication != null &&
              Object.hasOwnProperty.call(message, 'sourceApplication')
            ) {
              writer.uint32(24).int32(message.sourceApplication);
            }
            if (
              message.receivedTime != null &&
              Object.hasOwnProperty.call(message, 'receivedTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.receivedTime,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.storedTime != null &&
              Object.hasOwnProperty.call(message, 'storedTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.storedTime,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.data != null &&
              Object.hasOwnProperty.call(message, 'data')
            ) {
              google$2.protobuf.Struct.encode(
                message.data,
                writer.uint32(50).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WebhookRequest();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.id = reader.string();
                  break;
                }
                case 2: {
                  message.sourceId = reader.string();
                  break;
                }
                case 3: {
                  message.sourceApplication = reader.int32();
                  break;
                }
                case 4: {
                  message.receivedTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 5: {
                  message.storedTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 6: {
                  message.data = google$2.protobuf.Struct.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.GlobalLog.WebhookRequest) {
              return object;
            }
            const message = new $root.workflows.v1.GlobalLog.WebhookRequest();
            if (object.id != null) {
              message.id = String(object.id);
            }
            if (object.sourceId != null) {
              message.sourceId = String(object.sourceId);
            }
            if (object.sourceApplication != null) {
              switch (object.sourceApplication) {
                case 'UNSPECIFIED_SOURCE_APPLICATION':
                case 0: {
                  message.sourceApplication = 0;
                  break;
                }
                case 'FORMSORT':
                case 1: {
                  message.sourceApplication = 1;
                  break;
                }
                case 'HEALTHIE':
                case 2: {
                  message.sourceApplication = 2;
                  break;
                }
                case 'AXLE_HEALTH':
                case 3: {
                  message.sourceApplication = 3;
                  break;
                }
                case 'BUTTERFLY_LABS':
                case 4: {
                  message.sourceApplication = 4;
                  break;
                }
                case 'MORF':
                case 5: {
                  message.sourceApplication = 5;
                  break;
                }
                case 'PUSH_JSON':
                case 6: {
                  message.sourceApplication = 6;
                  break;
                }
                case 'ACUITY':
                case 7: {
                  message.sourceApplication = 7;
                  break;
                }
                case 'VITAL':
                case 8: {
                  message.sourceApplication = 8;
                  break;
                }
                case 'SEGMENT':
                case 9: {
                  message.sourceApplication = 9;
                  break;
                }
                case 'ACTIVE_CAMPAIGN':
                case 10: {
                  message.sourceApplication = 10;
                  break;
                }
                case 'INTAKEQ':
                case 11: {
                  message.sourceApplication = 11;
                  break;
                }
                case 'CUSTOMER_IO':
                case 12: {
                  message.sourceApplication = 12;
                  break;
                }
                case 'HUBSPOT':
                case 13: {
                  message.sourceApplication = 13;
                  break;
                }
                default: {
                  if (typeof object.sourceApplication == 'number') {
                    message.sourceApplication = object.sourceApplication;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.receivedTime != null) {
              if (typeof object.receivedTime !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WebhookRequest.receivedTime: object expected, but got ' +
                    typeof object.receivedTime
                );
              }
              message.receivedTime = timing$1.v1.Timestamp.fromObject(
                object.receivedTime
              );
            }
            if (object.storedTime != null) {
              if (typeof object.storedTime !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WebhookRequest.storedTime: object expected, but got ' +
                    typeof object.storedTime
                );
              }
              message.storedTime = timing$1.v1.Timestamp.fromObject(
                object.storedTime
              );
            }
            if (object.data != null) {
              if (typeof object.data !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WebhookRequest.data: object expected, but got ' +
                    typeof object.data
                );
              }
              message.data = google$2.protobuf.Struct.fromObject(object.data);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.id = '';
              object.sourceId = '';
              object.sourceApplication =
                options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
              object.receivedTime = null;
              object.storedTime = null;
              object.data = null;
            }
            let keys;
            if (message.id != null && message.hasOwnProperty('id')) {
              object.id = message.id;
            }
            if (
              message.sourceId != null &&
              message.hasOwnProperty('sourceId')
            ) {
              object.sourceId = message.sourceId;
            }
            if (
              message.sourceApplication != null &&
              message.hasOwnProperty('sourceApplication')
            ) {
              object.sourceApplication =
                options.enums === String
                  ? source_application$1.v2.SourceApplication.Application[
                      message.sourceApplication
                    ] === undefined
                    ? message.sourceApplication
                    : source_application$1.v2.SourceApplication.Application[
                        message.sourceApplication
                      ]
                  : message.sourceApplication;
            }
            if (
              message.receivedTime != null &&
              message.hasOwnProperty('receivedTime')
            ) {
              object.receivedTime = timing$1.v1.Timestamp.toObject(
                message.receivedTime,
                options
              );
            }
            if (
              message.storedTime != null &&
              message.hasOwnProperty('storedTime')
            ) {
              object.storedTime = timing$1.v1.Timestamp.toObject(
                message.storedTime,
                options
              );
            }
            if (message.data != null && message.hasOwnProperty('data')) {
              object.data = google$2.protobuf.Struct.toObject(
                message.data,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.GlobalLog.WebhookRequest';
          }
        }

        WebhookRequest.prototype.id = '';
        WebhookRequest.prototype.sourceId = '';
        WebhookRequest.prototype.sourceApplication = 0;
        WebhookRequest.prototype.receivedTime = null;
        WebhookRequest.prototype.storedTime = null;
        WebhookRequest.prototype.data = null;

        return WebhookRequest;
      })();

      GlobalLog.EventSnapshot = (() => {
        class EventSnapshot {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new EventSnapshot(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.webhookRequest != null &&
              Object.hasOwnProperty.call(message, 'webhookRequest')
            ) {
              $root.workflows.v1.GlobalLog.WebhookRequest.encode(
                message.webhookRequest,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.eventSnapshotId != null &&
              Object.hasOwnProperty.call(message, 'eventSnapshotId')
            ) {
              writer.uint32(18).string(message.eventSnapshotId);
            }
            if (
              message.eventType != null &&
              Object.hasOwnProperty.call(message, 'eventType')
            ) {
              writer.uint32(26).string(message.eventType);
            }
            if (
              message.eventPayload != null &&
              Object.hasOwnProperty.call(message, 'eventPayload')
            ) {
              google$2.protobuf.Struct.encode(
                message.eventPayload,
                writer.uint32(34).fork()
              ).ldelim();
            }
            if (
              message.processedTime != null &&
              Object.hasOwnProperty.call(message, 'processedTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.processedTime,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.eventTime != null &&
              Object.hasOwnProperty.call(message, 'eventTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.eventTime,
                writer.uint32(50).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new EventSnapshot();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.webhookRequest =
                    $root.workflows.v1.GlobalLog.WebhookRequest.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.eventSnapshotId = reader.string();
                  break;
                }
                case 3: {
                  message.eventType = reader.string();
                  break;
                }
                case 4: {
                  message.eventPayload = google$2.protobuf.Struct.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 5: {
                  message.processedTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 6: {
                  message.eventTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.workflows.v1.GlobalLog.EventSnapshot) {
              return object;
            }
            const message = new $root.workflows.v1.GlobalLog.EventSnapshot();
            if (object.webhookRequest != null) {
              if (typeof object.webhookRequest !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.EventSnapshot.webhookRequest: object expected, but got ' +
                    typeof object.webhookRequest
                );
              }
              message.webhookRequest =
                $root.workflows.v1.GlobalLog.WebhookRequest.fromObject(
                  object.webhookRequest
                );
            }
            if (object.eventSnapshotId != null) {
              message.eventSnapshotId = String(object.eventSnapshotId);
            }
            if (object.eventType != null) {
              message.eventType = String(object.eventType);
            }
            if (object.eventPayload != null) {
              if (typeof object.eventPayload !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.EventSnapshot.eventPayload: object expected, but got ' +
                    typeof object.eventPayload
                );
              }
              message.eventPayload = google$2.protobuf.Struct.fromObject(
                object.eventPayload
              );
            }
            if (object.processedTime != null) {
              if (typeof object.processedTime !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.EventSnapshot.processedTime: object expected, but got ' +
                    typeof object.processedTime
                );
              }
              message.processedTime = timing$1.v1.Timestamp.fromObject(
                object.processedTime
              );
            }
            if (object.eventTime != null) {
              if (typeof object.eventTime !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.EventSnapshot.eventTime: object expected, but got ' +
                    typeof object.eventTime
                );
              }
              message.eventTime = timing$1.v1.Timestamp.fromObject(
                object.eventTime
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.webhookRequest = null;
              object.eventSnapshotId = '';
              object.eventType = '';
              object.eventPayload = null;
              object.processedTime = null;
              object.eventTime = null;
            }
            let keys;
            if (
              message.webhookRequest != null &&
              message.hasOwnProperty('webhookRequest')
            ) {
              object.webhookRequest =
                $root.workflows.v1.GlobalLog.WebhookRequest.toObject(
                  message.webhookRequest,
                  options
                );
            }
            if (
              message.eventSnapshotId != null &&
              message.hasOwnProperty('eventSnapshotId')
            ) {
              object.eventSnapshotId = message.eventSnapshotId;
            }
            if (
              message.eventType != null &&
              message.hasOwnProperty('eventType')
            ) {
              object.eventType = message.eventType;
            }
            if (
              message.eventPayload != null &&
              message.hasOwnProperty('eventPayload')
            ) {
              object.eventPayload = google$2.protobuf.Struct.toObject(
                message.eventPayload,
                options
              );
            }
            if (
              message.processedTime != null &&
              message.hasOwnProperty('processedTime')
            ) {
              object.processedTime = timing$1.v1.Timestamp.toObject(
                message.processedTime,
                options
              );
            }
            if (
              message.eventTime != null &&
              message.hasOwnProperty('eventTime')
            ) {
              object.eventTime = timing$1.v1.Timestamp.toObject(
                message.eventTime,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.GlobalLog.EventSnapshot';
          }
        }

        EventSnapshot.prototype.webhookRequest = null;
        EventSnapshot.prototype.eventSnapshotId = '';
        EventSnapshot.prototype.eventType = '';
        EventSnapshot.prototype.eventPayload = null;
        EventSnapshot.prototype.processedTime = null;
        EventSnapshot.prototype.eventTime = null;

        return EventSnapshot;
      })();

      GlobalLog.WorkflowTriggerEvaluation = (() => {
        class WorkflowTriggerEvaluation {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WorkflowTriggerEvaluation(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.eventSnapshot != null &&
              Object.hasOwnProperty.call(message, 'eventSnapshot')
            ) {
              $root.workflows.v1.GlobalLog.EventSnapshot.encode(
                message.eventSnapshot,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.workflowExecutionId != null &&
              Object.hasOwnProperty.call(message, 'workflowExecutionId')
            ) {
              writer.uint32(18).string(message.workflowExecutionId);
            }
            if (
              message.workflowIdSummary != null &&
              Object.hasOwnProperty.call(message, 'workflowIdSummary')
            ) {
              $root.workflows.v1.WorkflowIdSummary.encode(
                message.workflowIdSummary,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.triggerExecutionTime != null &&
              Object.hasOwnProperty.call(message, 'triggerExecutionTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.triggerExecutionTime,
                writer.uint32(34).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WorkflowTriggerEvaluation();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.eventSnapshot =
                    $root.workflows.v1.GlobalLog.EventSnapshot.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.workflowExecutionId = reader.string();
                  break;
                }
                case 3: {
                  message.workflowIdSummary =
                    $root.workflows.v1.WorkflowIdSummary.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 4: {
                  message.triggerExecutionTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation();
            if (object.eventSnapshot != null) {
              if (typeof object.eventSnapshot !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.eventSnapshot: object expected, but got ' +
                    typeof object.eventSnapshot
                );
              }
              message.eventSnapshot =
                $root.workflows.v1.GlobalLog.EventSnapshot.fromObject(
                  object.eventSnapshot
                );
            }
            if (object.workflowExecutionId != null) {
              message.workflowExecutionId = String(object.workflowExecutionId);
            }
            if (object.workflowIdSummary != null) {
              if (typeof object.workflowIdSummary !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.workflowIdSummary: object expected, but got ' +
                    typeof object.workflowIdSummary
                );
              }
              message.workflowIdSummary =
                $root.workflows.v1.WorkflowIdSummary.fromObject(
                  object.workflowIdSummary
                );
            }
            if (object.triggerExecutionTime != null) {
              if (typeof object.triggerExecutionTime !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.triggerExecutionTime: object expected, but got ' +
                    typeof object.triggerExecutionTime
                );
              }
              message.triggerExecutionTime = timing$1.v1.Timestamp.fromObject(
                object.triggerExecutionTime
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.eventSnapshot = null;
              object.workflowExecutionId = '';
              object.workflowIdSummary = null;
              object.triggerExecutionTime = null;
            }
            let keys;
            if (
              message.eventSnapshot != null &&
              message.hasOwnProperty('eventSnapshot')
            ) {
              object.eventSnapshot =
                $root.workflows.v1.GlobalLog.EventSnapshot.toObject(
                  message.eventSnapshot,
                  options
                );
            }
            if (
              message.workflowExecutionId != null &&
              message.hasOwnProperty('workflowExecutionId')
            ) {
              object.workflowExecutionId = message.workflowExecutionId;
            }
            if (
              message.workflowIdSummary != null &&
              message.hasOwnProperty('workflowIdSummary')
            ) {
              object.workflowIdSummary =
                $root.workflows.v1.WorkflowIdSummary.toObject(
                  message.workflowIdSummary,
                  options
                );
            }
            if (
              message.triggerExecutionTime != null &&
              message.hasOwnProperty('triggerExecutionTime')
            ) {
              object.triggerExecutionTime = timing$1.v1.Timestamp.toObject(
                message.triggerExecutionTime,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix + 'workflows.v1.GlobalLog.WorkflowTriggerEvaluation'
            );
          }
        }

        WorkflowTriggerEvaluation.prototype.eventSnapshot = null;
        WorkflowTriggerEvaluation.prototype.workflowExecutionId = '';
        WorkflowTriggerEvaluation.prototype.workflowIdSummary = null;
        WorkflowTriggerEvaluation.prototype.triggerExecutionTime = null;

        return WorkflowTriggerEvaluation;
      })();

      GlobalLog.WorkflowExecution = (() => {
        class WorkflowExecution {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new WorkflowExecution(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.workflowTriggerEvaluation != null &&
              Object.hasOwnProperty.call(message, 'workflowTriggerEvaluation')
            ) {
              $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.encode(
                message.workflowTriggerEvaluation,
                writer.uint32(10).fork()
              ).ldelim();
            }
            if (
              message.executedWorkflowIds != null &&
              Object.hasOwnProperty.call(message, 'executedWorkflowIds')
            ) {
              $root.workflows.v1.InternalWorkflowExecutionIds.encode(
                message.executedWorkflowIds,
                writer.uint32(18).fork()
              ).ldelim();
            }
            if (
              message.profileLookupResult != null &&
              Object.hasOwnProperty.call(message, 'profileLookupResult')
            ) {
              google$2.protobuf.Struct.encode(
                message.profileLookupResult,
                writer.uint32(42).fork()
              ).ldelim();
            }
            if (
              message.profileLookupEndTime != null &&
              Object.hasOwnProperty.call(message, 'profileLookupEndTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.profileLookupEndTime,
                writer.uint32(50).fork()
              ).ldelim();
            }
            if (
              message.matchedProfile != null &&
              Object.hasOwnProperty.call(message, 'matchedProfile')
            ) {
              profiles$1.v1.Profile.encode(
                message.matchedProfile,
                writer.uint32(58).fork()
              ).ldelim();
            }
            if (
              message.matchedProfileIndex != null &&
              Object.hasOwnProperty.call(message, 'matchedProfileIndex')
            ) {
              profiles$1.v1.ProfileIndex.encode(
                message.matchedProfileIndex,
                writer.uint32(66).fork()
              ).ldelim();
            }
            if (
              message.formattedError != null &&
              Object.hasOwnProperty.call(message, 'formattedError')
            ) {
              workflow_evaluation_results$1.v1.FormattedError.encode(
                message.formattedError,
                writer.uint32(74).fork()
              ).ldelim();
            }
            if (
              message.executionInProgress != null &&
              Object.hasOwnProperty.call(message, 'executionInProgress')
            ) {
              writer.uint32(80).bool(message.executionInProgress);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new WorkflowExecution();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.workflowTriggerEvaluation =
                    $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 2: {
                  message.executedWorkflowIds =
                    $root.workflows.v1.InternalWorkflowExecutionIds.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 5: {
                  message.profileLookupResult = google$2.protobuf.Struct.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 6: {
                  message.profileLookupEndTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 7: {
                  message.matchedProfile = profiles$1.v1.Profile.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 8: {
                  message.matchedProfileIndex =
                    profiles$1.v1.ProfileIndex.decode(reader, reader.uint32());
                  break;
                }
                case 9: {
                  message.formattedError =
                    workflow_evaluation_results$1.v1.FormattedError.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 10: {
                  message.executionInProgress = reader.bool();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof $root.workflows.v1.GlobalLog.WorkflowExecution
            ) {
              return object;
            }
            const message =
              new $root.workflows.v1.GlobalLog.WorkflowExecution();
            if (object.workflowTriggerEvaluation != null) {
              if (typeof object.workflowTriggerEvaluation !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.workflowTriggerEvaluation: object expected, but got ' +
                    typeof object.workflowTriggerEvaluation
                );
              }
              message.workflowTriggerEvaluation =
                $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.fromObject(
                  object.workflowTriggerEvaluation
                );
            }
            if (object.executedWorkflowIds != null) {
              if (typeof object.executedWorkflowIds !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.executedWorkflowIds: object expected, but got ' +
                    typeof object.executedWorkflowIds
                );
              }
              message.executedWorkflowIds =
                $root.workflows.v1.InternalWorkflowExecutionIds.fromObject(
                  object.executedWorkflowIds
                );
            }
            if (object.profileLookupResult != null) {
              if (typeof object.profileLookupResult !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.profileLookupResult: object expected, but got ' +
                    typeof object.profileLookupResult
                );
              }
              message.profileLookupResult = google$2.protobuf.Struct.fromObject(
                object.profileLookupResult
              );
            }
            if (object.profileLookupEndTime != null) {
              if (typeof object.profileLookupEndTime !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.profileLookupEndTime: object expected, but got ' +
                    typeof object.profileLookupEndTime
                );
              }
              message.profileLookupEndTime = timing$1.v1.Timestamp.fromObject(
                object.profileLookupEndTime
              );
            }
            if (object.matchedProfile != null) {
              if (typeof object.matchedProfile !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.matchedProfile: object expected, but got ' +
                    typeof object.matchedProfile
                );
              }
              message.matchedProfile = profiles$1.v1.Profile.fromObject(
                object.matchedProfile
              );
            }
            if (object.matchedProfileIndex != null) {
              if (typeof object.matchedProfileIndex !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.matchedProfileIndex: object expected, but got ' +
                    typeof object.matchedProfileIndex
                );
              }
              message.matchedProfileIndex =
                profiles$1.v1.ProfileIndex.fromObject(
                  object.matchedProfileIndex
                );
            }
            if (object.formattedError != null) {
              if (typeof object.formattedError !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GlobalLog.WorkflowExecution.formattedError: object expected, but got ' +
                    typeof object.formattedError
                );
              }
              message.formattedError =
                workflow_evaluation_results$1.v1.FormattedError.fromObject(
                  object.formattedError
                );
            }
            if (object.executionInProgress != null) {
              message.executionInProgress = Boolean(object.executionInProgress);
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.workflowTriggerEvaluation = null;
              object.executedWorkflowIds = null;
              object.executionInProgress = false;
            }
            let keys;
            if (
              message.workflowTriggerEvaluation != null &&
              message.hasOwnProperty('workflowTriggerEvaluation')
            ) {
              object.workflowTriggerEvaluation =
                $root.workflows.v1.GlobalLog.WorkflowTriggerEvaluation.toObject(
                  message.workflowTriggerEvaluation,
                  options
                );
            }
            if (
              message.executedWorkflowIds != null &&
              message.hasOwnProperty('executedWorkflowIds')
            ) {
              object.executedWorkflowIds =
                $root.workflows.v1.InternalWorkflowExecutionIds.toObject(
                  message.executedWorkflowIds,
                  options
                );
            }
            if (
              message.profileLookupResult != null &&
              message.hasOwnProperty('profileLookupResult')
            ) {
              object.profileLookupResult = google$2.protobuf.Struct.toObject(
                message.profileLookupResult,
                options
              );
            }
            if (
              message.profileLookupEndTime != null &&
              message.hasOwnProperty('profileLookupEndTime')
            ) {
              object.profileLookupEndTime = timing$1.v1.Timestamp.toObject(
                message.profileLookupEndTime,
                options
              );
            }
            if (
              message.matchedProfile != null &&
              message.hasOwnProperty('matchedProfile')
            ) {
              object.matchedProfile = profiles$1.v1.Profile.toObject(
                message.matchedProfile,
                options
              );
            }
            if (
              message.matchedProfileIndex != null &&
              message.hasOwnProperty('matchedProfileIndex')
            ) {
              object.matchedProfileIndex = profiles$1.v1.ProfileIndex.toObject(
                message.matchedProfileIndex,
                options
              );
            }
            if (
              message.formattedError != null &&
              message.hasOwnProperty('formattedError')
            ) {
              object.formattedError =
                workflow_evaluation_results$1.v1.FormattedError.toObject(
                  message.formattedError,
                  options
                );
            }
            if (
              message.executionInProgress != null &&
              message.hasOwnProperty('executionInProgress')
            ) {
              object.executionInProgress = message.executionInProgress;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'workflows.v1.GlobalLog.WorkflowExecution';
          }
        }

        WorkflowExecution.prototype.workflowTriggerEvaluation = null;
        WorkflowExecution.prototype.executedWorkflowIds = null;
        WorkflowExecution.prototype.profileLookupResult = null;
        WorkflowExecution.prototype.profileLookupEndTime = null;
        WorkflowExecution.prototype.matchedProfile = null;
        WorkflowExecution.prototype.matchedProfileIndex = null;
        WorkflowExecution.prototype.formattedError = null;
        WorkflowExecution.prototype.executionInProgress = false;

        return WorkflowExecution;
      })();

      return GlobalLog;
    })();

    v1.WorkflowUniquenessConflictResolution = (() => {
      class WorkflowUniquenessConflictResolution {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowUniquenessConflictResolution(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.conflictResolution != null &&
            Object.hasOwnProperty.call(message, 'conflictResolution')
          ) {
            writer.uint32(8).int32(message.conflictResolution);
          }
          if (
            message.scope != null &&
            Object.hasOwnProperty.call(message, 'scope')
          ) {
            writer.uint32(16).int32(message.scope);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowUniquenessConflictResolution();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.conflictResolution = reader.int32();
                break;
              }
              case 2: {
                message.scope = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.WorkflowUniquenessConflictResolution
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.WorkflowUniquenessConflictResolution();
          if (object.conflictResolution != null) {
            switch (object.conflictResolution) {
              case 'UNSPECIFIED_CONFLICT_RESOLUTION':
              case 0: {
                message.conflictResolution = 0;
                break;
              }
              case 'SUPERSEDE_EXISTING':
              case 1: {
                message.conflictResolution = 1;
                break;
              }
              case 'SKIP_NEW':
              case 2: {
                message.conflictResolution = 2;
                break;
              }
              default: {
                if (typeof object.conflictResolution == 'number') {
                  message.conflictResolution = object.conflictResolution;
                  break;
                }
                break;
              }
            }
          }
          if (object.scope != null) {
            switch (object.scope) {
              case 'UNSPECIFIED_CONFLICT_SCOPE':
              case 0: {
                message.scope = 0;
                break;
              }
              case 'WORKFLOW':
              case 1: {
                message.scope = 1;
                break;
              }
              default: {
                if (typeof object.scope == 'number') {
                  message.scope = object.scope;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.conflictResolution =
              options.enums === String ? 'UNSPECIFIED_CONFLICT_RESOLUTION' : 0;
            object.scope =
              options.enums === String ? 'UNSPECIFIED_CONFLICT_SCOPE' : 0;
          }
          let keys;
          if (
            message.conflictResolution != null &&
            message.hasOwnProperty('conflictResolution')
          ) {
            object.conflictResolution =
              options.enums === String
                ? $root.workflows.v1.WorkflowUniquenessConflictResolution
                    .ConflictResolution[message.conflictResolution] ===
                  undefined
                  ? message.conflictResolution
                  : $root.workflows.v1.WorkflowUniquenessConflictResolution
                      .ConflictResolution[message.conflictResolution]
                : message.conflictResolution;
          }
          if (message.scope != null && message.hasOwnProperty('scope')) {
            object.scope =
              options.enums === String
                ? $root.workflows.v1.WorkflowUniquenessConflictResolution
                    .ConflictScope[message.scope] === undefined
                  ? message.scope
                  : $root.workflows.v1.WorkflowUniquenessConflictResolution
                      .ConflictScope[message.scope]
                : message.scope;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.WorkflowUniquenessConflictResolution'
          );
        }
      }

      WorkflowUniquenessConflictResolution.prototype.conflictResolution = 0;
      WorkflowUniquenessConflictResolution.prototype.scope = 0;
      WorkflowUniquenessConflictResolution.ConflictResolution = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNSPECIFIED_CONFLICT_RESOLUTION')] = 0;
        values[(valuesById[1] = 'SUPERSEDE_EXISTING')] = 1;
        values[(valuesById[2] = 'SKIP_NEW')] = 2;
        return values;
      })();

      WorkflowUniquenessConflictResolution.ConflictScope = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNSPECIFIED_CONFLICT_SCOPE')] = 0;
        values[(valuesById[1] = 'WORKFLOW')] = 1;
        return values;
      })();

      return WorkflowUniquenessConflictResolution;
    })();

    v1.RefreshWorkflowResult = (() => {
      class RefreshWorkflowResult {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RefreshWorkflowResult(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.result != null &&
            Object.hasOwnProperty.call(message, 'result')
          ) {
            workflow_evaluation_results$1.v1.DestinationActionResult.encode(
              message.result,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.refreshedWorkflow != null &&
            Object.hasOwnProperty.call(message, 'refreshedWorkflow')
          ) {
            $root.workflows.v1.WorkflowExecutionGraph.encode(
              message.refreshedWorkflow,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RefreshWorkflowResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.result =
                  workflow_evaluation_results$1.v1.DestinationActionResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.refreshedWorkflow =
                  $root.workflows.v1.WorkflowExecutionGraph.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.RefreshWorkflowResult) {
            return object;
          }
          const message = new $root.workflows.v1.RefreshWorkflowResult();
          if (object.result != null) {
            if (typeof object.result !== 'object') {
              throw new TypeError(
                '.workflows.v1.RefreshWorkflowResult.result: object expected, but got ' +
                  typeof object.result
              );
            }
            message.result =
              workflow_evaluation_results$1.v1.DestinationActionResult.fromObject(
                object.result
              );
          }
          if (object.refreshedWorkflow != null) {
            if (typeof object.refreshedWorkflow !== 'object') {
              throw new TypeError(
                '.workflows.v1.RefreshWorkflowResult.refreshedWorkflow: object expected, but got ' +
                  typeof object.refreshedWorkflow
              );
            }
            message.refreshedWorkflow =
              $root.workflows.v1.WorkflowExecutionGraph.fromObject(
                object.refreshedWorkflow
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.result = null;
            object.refreshedWorkflow = null;
          }
          let keys;
          if (message.result != null && message.hasOwnProperty('result')) {
            object.result =
              workflow_evaluation_results$1.v1.DestinationActionResult.toObject(
                message.result,
                options
              );
          }
          if (
            message.refreshedWorkflow != null &&
            message.hasOwnProperty('refreshedWorkflow')
          ) {
            object.refreshedWorkflow =
              $root.workflows.v1.WorkflowExecutionGraph.toObject(
                message.refreshedWorkflow,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.RefreshWorkflowResult';
        }
      }

      RefreshWorkflowResult.prototype.result = null;
      RefreshWorkflowResult.prototype.refreshedWorkflow = null;

      return RefreshWorkflowResult;
    })();

    v1.ListObjectTypeIDsRequest = (() => {
      class ListObjectTypeIDsRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectTypeIDsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(8).int32(message.application);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(16).int32(message.objectCategory);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectTypeIDsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.objectCategory = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListObjectTypeIDsRequest) {
            return object;
          }
          const message = new $root.workflows.v1.ListObjectTypeIDsRequest();
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.application = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.application = 13;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
          }
          let keys;
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.application
                  ] === undefined
                  ? message.application
                  : source_application$1.v2.SourceApplication.Application[
                      message.application
                    ]
                : message.application;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.objectCategory
                  ] === undefined
                  ? message.objectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ]
                : message.objectCategory;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListObjectTypeIDsRequest';
        }
      }

      ListObjectTypeIDsRequest.prototype.application = 0;
      ListObjectTypeIDsRequest.prototype.objectCategory = 0;

      return ListObjectTypeIDsRequest;
    })();

    v1.ListObjectTypeIDsResponse = (() => {
      class ListObjectTypeIDsResponse {
        constructor(properties) {
          this.ids = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ListObjectTypeIDsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.ids != null &&
            Object.hasOwnProperty.call(message, 'ids')
          ) {
            for (const element of message.ids) {
              workflow_parameters$1.v1.ObjectTypeID.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ListObjectTypeIDsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.ids || !message.ids.length) {
                  message.ids = [];
                }
                message.ids.push(
                  workflow_parameters$1.v1.ObjectTypeID.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.ListObjectTypeIDsResponse) {
            return object;
          }
          const message = new $root.workflows.v1.ListObjectTypeIDsResponse();
          if (object.ids) {
            if (!Array.isArray(object.ids)) {
              throw new TypeError(
                '.workflows.v1.ListObjectTypeIDsResponse.ids: array type expected, but got ' +
                  typeof object.ids
              );
            }
            message.ids = new Array(object.ids.length);
            for (let i = 0; i < object.ids.length; ++i) {
              if (typeof object.ids[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.ListObjectTypeIDsResponse.ids: object expected, but got ' +
                    typeof object.ids[i]
                );
              }
              message.ids[i] = workflow_parameters$1.v1.ObjectTypeID.fromObject(
                object.ids[i]
              );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.ids = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.ids && message.ids.length) {
            object.ids = new Array(message.ids.length);
            for (let i = 0; i < message.ids.length; ++i) {
              object.ids[i] = workflow_parameters$1.v1.ObjectTypeID.toObject(
                message.ids[i],
                options
              );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.ListObjectTypeIDsResponse';
        }
      }

      ListObjectTypeIDsResponse.prototype.ids = $util.emptyArray;

      return ListObjectTypeIDsResponse;
    })();

    v1.GetActionFieldObjectTypeIDsRequest = (() => {
      class GetActionFieldObjectTypeIDsRequest {
        get action() {
          for (const key of ['destinationAction', 'fetchAction']) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set action(name) {
          for (const key of ['destinationAction', 'fetchAction']) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetActionFieldObjectTypeIDsRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.destinationAction != null &&
            Object.hasOwnProperty.call(message, 'destinationAction')
          ) {
            $root.workflows.v1.WorkflowDestinationAction.encode(
              message.destinationAction,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.fetchAction != null &&
            Object.hasOwnProperty.call(message, 'fetchAction')
          ) {
            $root.workflows.v1.WorkflowFetchAction.encode(
              message.fetchAction,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.fieldName != null &&
            Object.hasOwnProperty.call(message, 'fieldName')
          ) {
            writer.uint32(26).string(message.fieldName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetActionFieldObjectTypeIDsRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.destinationAction =
                  $root.workflows.v1.WorkflowDestinationAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.fetchAction =
                  $root.workflows.v1.WorkflowFetchAction.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.fieldName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetActionFieldObjectTypeIDsRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetActionFieldObjectTypeIDsRequest();
          if (object.destinationAction != null) {
            if (typeof object.destinationAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetActionFieldObjectTypeIDsRequest.destinationAction: object expected, but got ' +
                  typeof object.destinationAction
              );
            }
            message.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.fromObject(
                object.destinationAction
              );
          }
          if (object.fetchAction != null) {
            if (typeof object.fetchAction !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetActionFieldObjectTypeIDsRequest.fetchAction: object expected, but got ' +
                  typeof object.fetchAction
              );
            }
            message.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.fromObject(
                object.fetchAction
              );
          }
          if (object.fieldName != null) {
            message.fieldName = String(object.fieldName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.fieldName = '';
          }
          let keys;
          if (
            message.destinationAction != null &&
            message.hasOwnProperty('destinationAction')
          ) {
            object.destinationAction =
              $root.workflows.v1.WorkflowDestinationAction.toObject(
                message.destinationAction,
                options
              );
            if (options.oneofs) {
              object.action = 'destinationAction';
            }
          }
          if (
            message.fetchAction != null &&
            message.hasOwnProperty('fetchAction')
          ) {
            object.fetchAction =
              $root.workflows.v1.WorkflowFetchAction.toObject(
                message.fetchAction,
                options
              );
            if (options.oneofs) {
              object.action = 'fetchAction';
            }
          }
          if (
            message.fieldName != null &&
            message.hasOwnProperty('fieldName')
          ) {
            object.fieldName = message.fieldName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetActionFieldObjectTypeIDsRequest'
          );
        }
      }

      GetActionFieldObjectTypeIDsRequest.prototype.destinationAction = null;
      GetActionFieldObjectTypeIDsRequest.prototype.fetchAction = null;
      GetActionFieldObjectTypeIDsRequest.prototype.fieldName = '';

      return GetActionFieldObjectTypeIDsRequest;
    })();

    v1.GetActionFieldObjectTypeIDsResponse = (() => {
      class GetActionFieldObjectTypeIDsResponse {
        constructor(properties) {
          this.objectTypeIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetActionFieldObjectTypeIDsResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.objectTypeIds != null &&
            Object.hasOwnProperty.call(message, 'objectTypeIds')
          ) {
            for (const element of message.objectTypeIds) {
              workflow_parameters$1.v1.ObjectTypeID.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetActionFieldObjectTypeIDsResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.objectTypeIds || !message.objectTypeIds.length) {
                  message.objectTypeIds = [];
                }
                message.objectTypeIds.push(
                  workflow_parameters$1.v1.ObjectTypeID.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetActionFieldObjectTypeIDsResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetActionFieldObjectTypeIDsResponse();
          if (object.objectTypeIds) {
            if (!Array.isArray(object.objectTypeIds)) {
              throw new TypeError(
                '.workflows.v1.GetActionFieldObjectTypeIDsResponse.objectTypeIds: array type expected, but got ' +
                  typeof object.objectTypeIds
              );
            }
            message.objectTypeIds = new Array(object.objectTypeIds.length);
            for (let i = 0; i < object.objectTypeIds.length; ++i) {
              if (typeof object.objectTypeIds[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GetActionFieldObjectTypeIDsResponse.objectTypeIds: object expected, but got ' +
                    typeof object.objectTypeIds[i]
                );
              }
              message.objectTypeIds[i] =
                workflow_parameters$1.v1.ObjectTypeID.fromObject(
                  object.objectTypeIds[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypeIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.objectTypeIds && message.objectTypeIds.length) {
            object.objectTypeIds = new Array(message.objectTypeIds.length);
            for (let i = 0; i < message.objectTypeIds.length; ++i) {
              object.objectTypeIds[i] =
                workflow_parameters$1.v1.ObjectTypeID.toObject(
                  message.objectTypeIds[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetActionFieldObjectTypeIDsResponse'
          );
        }
      }

      GetActionFieldObjectTypeIDsResponse.prototype.objectTypeIds =
        $util.emptyArray;

      return GetActionFieldObjectTypeIDsResponse;
    })();

    v1.GetObjectTypeFieldSchemasRequest = (() => {
      class GetObjectTypeFieldSchemasRequest {
        constructor(properties) {
          this.objectTypeIds = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetObjectTypeFieldSchemasRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(8).int32(message.application);
          }
          if (
            message.objectCategory != null &&
            Object.hasOwnProperty.call(message, 'objectCategory')
          ) {
            writer.uint32(16).int32(message.objectCategory);
          }
          if (
            message.objectTypeIds != null &&
            Object.hasOwnProperty.call(message, 'objectTypeIds')
          ) {
            for (const element of message.objectTypeIds) {
              writer.uint32(26).string(element);
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetObjectTypeFieldSchemasRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              case 2: {
                message.objectCategory = reader.int32();
                break;
              }
              case 3: {
                if (!message.objectTypeIds || !message.objectTypeIds.length) {
                  message.objectTypeIds = [];
                }
                message.objectTypeIds.push(reader.string());
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetObjectTypeFieldSchemasRequest
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetObjectTypeFieldSchemasRequest();
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.application = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.application = 13;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectCategory != null) {
            switch (object.objectCategory) {
              case 'UNSPECIFIED_OBJECT_CATEGORY':
              case 0: {
                message.objectCategory = 0;
                break;
              }
              case 'FORM':
              case 1: {
                message.objectCategory = 1;
                break;
              }
              case 'APPOINTMENT':
              case 2: {
                message.objectCategory = 2;
                break;
              }
              case 'PATIENT':
              case 3: {
                message.objectCategory = 3;
                break;
              }
              case 'LAB_ORDER':
              case 4: {
                message.objectCategory = 4;
                break;
              }
              default: {
                if (typeof object.objectCategory == 'number') {
                  message.objectCategory = object.objectCategory;
                  break;
                }
                break;
              }
            }
          }
          if (object.objectTypeIds) {
            if (!Array.isArray(object.objectTypeIds)) {
              throw new TypeError(
                '.workflows.v1.GetObjectTypeFieldSchemasRequest.objectTypeIds: array type expected, but got ' +
                  typeof object.objectTypeIds
              );
            }
            message.objectTypeIds = new Array(object.objectTypeIds.length);
            for (let i = 0; i < object.objectTypeIds.length; ++i) {
              message.objectTypeIds[i] = String(object.objectTypeIds[i]);
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.objectTypeIds = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
            object.objectCategory =
              options.enums === String ? 'UNSPECIFIED_OBJECT_CATEGORY' : 0;
          }
          let keys;
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? source_application$1.v2.SourceApplication.Application[
                    message.application
                  ] === undefined
                  ? message.application
                  : source_application$1.v2.SourceApplication.Application[
                      message.application
                    ]
                : message.application;
          }
          if (
            message.objectCategory != null &&
            message.hasOwnProperty('objectCategory')
          ) {
            object.objectCategory =
              options.enums === String
                ? object_category$1.v1.ObjectCategory.Category[
                    message.objectCategory
                  ] === undefined
                  ? message.objectCategory
                  : object_category$1.v1.ObjectCategory.Category[
                      message.objectCategory
                    ]
                : message.objectCategory;
          }
          if (message.objectTypeIds && message.objectTypeIds.length) {
            object.objectTypeIds = new Array(message.objectTypeIds.length);
            for (let i = 0; i < message.objectTypeIds.length; ++i) {
              object.objectTypeIds[i] = message.objectTypeIds[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetObjectTypeFieldSchemasRequest'
          );
        }
      }

      GetObjectTypeFieldSchemasRequest.prototype.application = 0;
      GetObjectTypeFieldSchemasRequest.prototype.objectCategory = 0;
      GetObjectTypeFieldSchemasRequest.prototype.objectTypeIds =
        $util.emptyArray;

      return GetObjectTypeFieldSchemasRequest;
    })();

    v1.GetObjectTypeFieldSchemasResponse = (() => {
      class GetObjectTypeFieldSchemasResponse {
        constructor(properties) {
          this.schemas = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetObjectTypeFieldSchemasResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.schemas != null &&
            Object.hasOwnProperty.call(message, 'schemas')
          ) {
            for (const element of message.schemas) {
              workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetObjectTypeFieldSchemasResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.schemas || !message.schemas.length) {
                  message.schemas = [];
                }
                message.schemas.push(
                  workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflows.v1.GetObjectTypeFieldSchemasResponse
          ) {
            return object;
          }
          const message =
            new $root.workflows.v1.GetObjectTypeFieldSchemasResponse();
          if (object.schemas) {
            if (!Array.isArray(object.schemas)) {
              throw new TypeError(
                '.workflows.v1.GetObjectTypeFieldSchemasResponse.schemas: array type expected, but got ' +
                  typeof object.schemas
              );
            }
            message.schemas = new Array(object.schemas.length);
            for (let i = 0; i < object.schemas.length; ++i) {
              if (typeof object.schemas[i] !== 'object') {
                throw new TypeError(
                  '.workflows.v1.GetObjectTypeFieldSchemasResponse.schemas: object expected, but got ' +
                    typeof object.schemas[i]
                );
              }
              message.schemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.fromObject(
                  object.schemas[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.schemas = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.schemas && message.schemas.length) {
            object.schemas = new Array(message.schemas.length);
            for (let i = 0; i < message.schemas.length; ++i) {
              object.schemas[i] =
                workflow_parameters$1.v1.WorkflowEventPayloadFieldSchema.toObject(
                  message.schemas[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflows.v1.GetObjectTypeFieldSchemasResponse'
          );
        }
      }

      GetObjectTypeFieldSchemasResponse.prototype.schemas = $util.emptyArray;

      return GetObjectTypeFieldSchemasResponse;
    })();

    v1.GetExtraFetchedDataRequest = (() => {
      class GetExtraFetchedDataRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetExtraFetchedDataRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowExecutionId != null &&
            Object.hasOwnProperty.call(message, 'workflowExecutionId')
          ) {
            writer.uint32(10).string(message.workflowExecutionId);
          }
          if (
            message.nodeId != null &&
            Object.hasOwnProperty.call(message, 'nodeId')
          ) {
            writer.uint32(18).string(message.nodeId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetExtraFetchedDataRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowExecutionId = reader.string();
                break;
              }
              case 2: {
                message.nodeId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.GetExtraFetchedDataRequest) {
            return object;
          }
          const message = new $root.workflows.v1.GetExtraFetchedDataRequest();
          if (object.workflowExecutionId != null) {
            message.workflowExecutionId = String(object.workflowExecutionId);
          }
          if (object.nodeId != null) {
            message.nodeId = String(object.nodeId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowExecutionId = '';
            object.nodeId = '';
          }
          let keys;
          if (
            message.workflowExecutionId != null &&
            message.hasOwnProperty('workflowExecutionId')
          ) {
            object.workflowExecutionId = message.workflowExecutionId;
          }
          if (message.nodeId != null && message.hasOwnProperty('nodeId')) {
            object.nodeId = message.nodeId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetExtraFetchedDataRequest';
        }
      }

      GetExtraFetchedDataRequest.prototype.workflowExecutionId = '';
      GetExtraFetchedDataRequest.prototype.nodeId = '';

      return GetExtraFetchedDataRequest;
    })();

    v1.GetExtraFetchedDataResponse = (() => {
      class GetExtraFetchedDataResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new GetExtraFetchedDataResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.extraFetchedData != null &&
            Object.hasOwnProperty.call(message, 'extraFetchedData')
          ) {
            workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.encode(
              message.extraFetchedData,
              writer.uint32(10).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new GetExtraFetchedDataResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.extraFetchedData =
                  workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflows.v1.GetExtraFetchedDataResponse
          ) {
            return object;
          }
          const message = new $root.workflows.v1.GetExtraFetchedDataResponse();
          if (object.extraFetchedData != null) {
            if (typeof object.extraFetchedData !== 'object') {
              throw new TypeError(
                '.workflows.v1.GetExtraFetchedDataResponse.extraFetchedData: object expected, but got ' +
                  typeof object.extraFetchedData
              );
            }
            message.extraFetchedData =
              workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.fromObject(
                object.extraFetchedData
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.extraFetchedData = null;
          }
          let keys;
          if (
            message.extraFetchedData != null &&
            message.hasOwnProperty('extraFetchedData')
          ) {
            object.extraFetchedData =
              workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.toObject(
                message.extraFetchedData,
                options
              );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.GetExtraFetchedDataResponse';
        }
      }

      GetExtraFetchedDataResponse.prototype.extraFetchedData = null;

      return GetExtraFetchedDataResponse;
    })();

    v1.CancelWorkflowRequest = (() => {
      class CancelWorkflowRequest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CancelWorkflowRequest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.temporalWorkflowId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowId')
          ) {
            writer.uint32(10).string(message.temporalWorkflowId);
          }
          if (
            message.temporalWorkflowRunId != null &&
            Object.hasOwnProperty.call(message, 'temporalWorkflowRunId')
          ) {
            writer.uint32(18).string(message.temporalWorkflowRunId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CancelWorkflowRequest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.temporalWorkflowId = reader.string();
                break;
              }
              case 2: {
                message.temporalWorkflowRunId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CancelWorkflowRequest) {
            return object;
          }
          const message = new $root.workflows.v1.CancelWorkflowRequest();
          if (object.temporalWorkflowId != null) {
            message.temporalWorkflowId = String(object.temporalWorkflowId);
          }
          if (object.temporalWorkflowRunId != null) {
            message.temporalWorkflowRunId = String(
              object.temporalWorkflowRunId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.temporalWorkflowId = '';
            object.temporalWorkflowRunId = '';
          }
          let keys;
          if (
            message.temporalWorkflowId != null &&
            message.hasOwnProperty('temporalWorkflowId')
          ) {
            object.temporalWorkflowId = message.temporalWorkflowId;
          }
          if (
            message.temporalWorkflowRunId != null &&
            message.hasOwnProperty('temporalWorkflowRunId')
          ) {
            object.temporalWorkflowRunId = message.temporalWorkflowRunId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CancelWorkflowRequest';
        }
      }

      CancelWorkflowRequest.prototype.temporalWorkflowId = '';
      CancelWorkflowRequest.prototype.temporalWorkflowRunId = '';

      return CancelWorkflowRequest;
    })();

    v1.CancelWorkflowResponse = (() => {
      class CancelWorkflowResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new CancelWorkflowResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new CancelWorkflowResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.workflows.v1.CancelWorkflowResponse) {
            return object;
          }
          const message = new $root.workflows.v1.CancelWorkflowResponse();
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflows.v1.CancelWorkflowResponse';
        }
      }

      return CancelWorkflowResponse;
    })();

    v1.Status = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_WORKFLOW_STATUS')] = 0;
      values[(valuesById[1] = 'DRAFT')] = 1;
      values[(valuesById[2] = 'ACTIVE')] = 2;
      values[(valuesById[3] = 'INACTIVE')] = 3;
      return values;
    })();

    v1.WorkflowsService = (() => {
      class WorkflowsService extends $protobuf.rpc.Service {
        constructor(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          super(rpcImpl, requestDelimited, responseDelimited);
        }

        static create(
          rpcImpl,
          requestDelimited = false,
          responseDelimited = false
        ) {
          return new WorkflowsService(
            rpcImpl,
            requestDelimited,
            responseDelimited
          );
        }
      }

      Object.defineProperty(
        (WorkflowsService.prototype.create = function create(
          request,
          callback
        ) {
          return this.rpcCall(
            create,
            $root.workflows.v1.CreateRequest,
            $root.workflows.v1.CreateResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'Create' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.get = function get(request, callback) {
          return this.rpcCall(
            get,
            $root.workflows.v1.GetRequest,
            $root.workflows.v1.GetResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'Get' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.update = function update(
          request,
          callback
        ) {
          return this.rpcCall(
            update,
            $root.workflows.v1.UpdateRequest,
            $root.workflows.v1.UpdateResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'Update' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.createWorkflowClone =
          function createWorkflowClone(request, callback) {
            return this.rpcCall(
              createWorkflowClone,
              $root.workflows.v1.CreateWorkflowCloneRequest,
              $root.workflows.v1.CreateWorkflowCloneResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'CreateWorkflowClone' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getWorkflowVersion =
          function getWorkflowVersion(request, callback) {
            return this.rpcCall(
              getWorkflowVersion,
              $root.workflows.v1.GetWorkflowVersionRequest,
              $root.workflows.v1.GetWorkflowVersionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetWorkflowVersion' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.updateWorkflowVersion =
          function updateWorkflowVersion(request, callback) {
            return this.rpcCall(
              updateWorkflowVersion,
              $root.workflows.v1.UpdateWorkflowVersionRequest,
              $root.workflows.v1.UpdateWorkflowVersionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateWorkflowVersion' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.updateWorkflowVersionNode =
          function updateWorkflowVersionNode(request, callback) {
            return this.rpcCall(
              updateWorkflowVersionNode,
              $root.workflows.v1.UpdateWorkflowVersionNodeRequest,
              $root.workflows.v1.UpdateWorkflowVersionNodeResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'UpdateWorkflowVersionNode' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.list = function list(request, callback) {
          return this.rpcCall(
            list,
            $root.workflows.v1.ListRequest,
            $root.workflows.v1.ListResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'List' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listWorkflowVersions =
          function listWorkflowVersions(request, callback) {
            return this.rpcCall(
              listWorkflowVersions,
              $root.workflows.v1.ListWorkflowVersionsRequest,
              $root.workflows.v1.ListWorkflowVersionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWorkflowVersions' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getValidTriggerEvents =
          function getValidTriggerEvents(request, callback) {
            return this.rpcCall(
              getValidTriggerEvents,
              $root.workflows.v1.GetValidTriggerEventsRequest,
              $root.workflows.v1.GetValidTriggerEventsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetValidTriggerEvents' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.retryEventSnapshot =
          function retryEventSnapshot(request, callback) {
            return this.rpcCall(
              retryEventSnapshot,
              $root.workflows.v1.RetryEventSnapshotRequest,
              $root.workflows.v1.RetryEventSnapshotResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'RetryEventSnapshot' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.retryWorkflowExecution =
          function retryWorkflowExecution(request, callback) {
            return this.rpcCall(
              retryWorkflowExecution,
              $root.workflows.v1.RetryWorkflowExecutionRequest,
              $root.workflows.v1.RetryWorkflowExecutionResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'RetryWorkflowExecution' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listGroupedErrorData =
          function listGroupedErrorData(request, callback) {
            return this.rpcCall(
              listGroupedErrorData,
              $root.workflows.v1.ListGroupedErrorDataRequest,
              $root.workflows.v1.ListGroupedErrorDataResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListGroupedErrorData' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listWorkflowGroupedWorkflowErrors =
          function listWorkflowGroupedWorkflowErrors(request, callback) {
            return this.rpcCall(
              listWorkflowGroupedWorkflowErrors,
              $root.workflows.v1.ListWorkflowGroupedWorkflowErrorsRequest,
              $root.workflows.v1.ListWorkflowGroupedWorkflowErrorsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWorkflowGroupedWorkflowErrors' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listWorkflowGroupedDestinationActionErrors =
          function listWorkflowGroupedDestinationActionErrors(
            request,
            callback
          ) {
            return this.rpcCall(
              listWorkflowGroupedDestinationActionErrors,
              $root.workflows.v1
                .ListWorkflowGroupedDestinationActionErrorsRequest,
              $root.workflows.v1
                .ListWorkflowGroupedDestinationActionErrorsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWorkflowGroupedDestinationActionErrors' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listProfileDestinationActionOutcomes =
          function listProfileDestinationActionOutcomes(request, callback) {
            return this.rpcCall(
              listProfileDestinationActionOutcomes,
              $root.workflows.v1.ListProfileOutcomesRequest,
              $root.workflows.v1.ListProfileDestinationActionOutcomesResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListProfileDestinationActionOutcomes' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listProfileWorkflowEvents =
          function listProfileWorkflowEvents(request, callback) {
            return this.rpcCall(
              listProfileWorkflowEvents,
              $root.workflows.v1.ListProfileOutcomesRequest,
              $root.workflows.v1.ListProfileWorkflowEventsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListProfileWorkflowEvents' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listWorkflowExecutionDestinationActionOutcomes =
          function listWorkflowExecutionDestinationActionOutcomes(
            request,
            callback
          ) {
            return this.rpcCall(
              listWorkflowExecutionDestinationActionOutcomes,
              $root.workflows.v1
                .ListWorkflowExecutionDestinationActionOutcomesRequest,
              $root.workflows.v1
                .ListWorkflowExecutionDestinationActionOutcomesResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWorkflowExecutionDestinationActionOutcomes' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listWorkflowIdEvents =
          function listWorkflowIdEvents(request, callback) {
            return this.rpcCall(
              listWorkflowIdEvents,
              $root.workflows.v1.ListWorkflowIdEventsRequest,
              $root.workflows.v1.ListWorkflowIdEventsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListWorkflowIdEvents' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listGlobalLogs = function listGlobalLogs(
          request,
          callback
        ) {
          return this.rpcCall(
            listGlobalLogs,
            $root.workflows.v1.ListGlobalLogsRequest,
            $root.workflows.v1.ListGlobalLogsResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'ListGlobalLogs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listCachedGlobalLogs =
          function listCachedGlobalLogs(request, callback) {
            return this.rpcCall(
              listCachedGlobalLogs,
              $root.workflows.v1.ListGlobalLogsRequest,
              $root.workflows.v1.ListGlobalLogsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListCachedGlobalLogs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listCachedErrorGlobalLogs =
          function listCachedErrorGlobalLogs(request, callback) {
            return this.rpcCall(
              listCachedErrorGlobalLogs,
              $root.workflows.v1.ListGlobalLogsRequest,
              $root.workflows.v1.ListGlobalLogsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListCachedErrorGlobalLogs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listDestinationActions =
          function listDestinationActions(request, callback) {
            return this.rpcCall(
              listDestinationActions,
              $root.workflows.v1.ListDestinationActionsRequest,
              $root.workflows.v1.ListDestinationActionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListDestinationActions' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listFetchActions =
          function listFetchActions(request, callback) {
            return this.rpcCall(
              listFetchActions,
              $root.workflows.v1.ListFetchActionsRequest,
              $root.workflows.v1.ListFetchActionsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListFetchActions' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.listObjectTypeIDs =
          function listObjectTypeIDs(request, callback) {
            return this.rpcCall(
              listObjectTypeIDs,
              $root.workflows.v1.ListObjectTypeIDsRequest,
              $root.workflows.v1.ListObjectTypeIDsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'ListObjectTypeIDs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getActionFieldObjectTypeIDs =
          function getActionFieldObjectTypeIDs(request, callback) {
            return this.rpcCall(
              getActionFieldObjectTypeIDs,
              $root.workflows.v1.GetActionFieldObjectTypeIDsRequest,
              $root.workflows.v1.GetActionFieldObjectTypeIDsResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetActionFieldObjectTypeIDs' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getObjectTypeFieldSchemas =
          function getObjectTypeFieldSchemas(request, callback) {
            return this.rpcCall(
              getObjectTypeFieldSchemas,
              $root.workflows.v1.GetObjectTypeFieldSchemasRequest,
              $root.workflows.v1.GetObjectTypeFieldSchemasResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetObjectTypeFieldSchemas' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getEventPayloadFieldSchemas =
          function getEventPayloadFieldSchemas(request, callback) {
            return this.rpcCall(
              getEventPayloadFieldSchemas,
              $root.workflows.v1.GetEventPayloadFieldSchemasRequest,
              $root.workflows.v1.GetEventPayloadFieldSchemasResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetEventPayloadFieldSchemas' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.getExtraFetchedData =
          function getExtraFetchedData(request, callback) {
            return this.rpcCall(
              getExtraFetchedData,
              $root.workflows.v1.GetExtraFetchedDataRequest,
              $root.workflows.v1.GetExtraFetchedDataResponse,
              request,
              callback
            );
          }),
        'name',
        { value: 'GetExtraFetchedData' }
      );

      Object.defineProperty(
        (WorkflowsService.prototype.cancelWorkflow = function cancelWorkflow(
          request,
          callback
        ) {
          return this.rpcCall(
            cancelWorkflow,
            $root.workflows.v1.CancelWorkflowRequest,
            $root.workflows.v1.CancelWorkflowResponse,
            request,
            callback
          );
        }),
        'name',
        { value: 'CancelWorkflow' }
      );

      return WorkflowsService;
    })();

    return v1;
  })();

  return workflows;
})();

export const workflows = $root.workflows;
