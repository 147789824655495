import Link from 'next/link';
import { FC, Fragment, memo } from 'react';
import { Flexbox } from '@morf/ui';
import { Icon } from '@morf/ui/Icon';
import { NavigationItemsProps } from './types';
import { Tooltip } from '@morf/ui/Tooltip';
import { useRouter } from 'next/router';
import { useTheme } from '@morf/theming';

const NavigationItems: FC<NavigationItemsProps> = ({ organizationId }) => {
  const router = useRouter();
  const theme = useTheme();

  const { pathname } = router;

  const isActive = (p: string) => {
    return pathname.includes(p);
  };

  const items = [
    {
      icon: (
        <Icon
          name='activity'
          fill={theme.colors.main.dark.darkest}
          viewBox='0 0 22 22'
        />
      ),
      iconActive: (
        <Icon
          name='activity-solid'
          fill={theme.colors.main.primary.darker}
          stroke='none'
          viewBox='0 0 22 22'
        />
      ),
      to: 'monitoring',
      tooltipDescription: 'Monitoring',
    },
    {
      icon: (
        <Icon
          name='bolt'
          stroke={theme.colors.main.dark.darkest}
          viewBox='0 0 22 22'
        />
      ),
      iconActive: (
        <Icon
          name='bolt'
          stroke={theme.colors.main.primary.darker}
          fill={theme.colors.main.primary.darker}
          viewBox='0 0 22 22'
        />
      ),
      to: 'workflows',
      tooltipDescription: 'Workflows',
    },
    {
      icon: (
        <Icon
          name='patients'
          stroke={theme.colors.main.dark.darkest}
          viewBox='0 0 22 22'
        />
      ),
      iconActive: (
        <Icon
          name='patients'
          stroke={theme.colors.main.primary.darker}
          fill={theme.colors.main.primary.darker}
          viewBox='0 0 22 22'
        />
      ),
      to: 'profiles',
      tooltipDescription: 'Profiles',
    },
    {
      icon: (
        <Icon
          name='identification'
          stroke={theme.colors.main.dark.darkest}
          fill='none'
        />
      ),
      iconActive: (
        <Icon
          name='identification-solid'
          fill={theme.colors.main.primary.darker}
          stroke={theme.colors.ui.card}
          strokeWidth={0.5}
          viewBox='0 0 22 22'
        />
      ),
      to: 'providers',
      tooltipDescription: 'Providers',
    },
    {
      icon: (
        <Icon
          name='properties'
          stroke={theme.colors.main.dark.darkest}
          viewBox='0 0 20 20'
        />
      ),
      iconActive: (
        <Icon
          name='properties'
          fill={theme.colors.main.primary.darker}
          stroke={theme.colors.main.primary.darker}
          viewBox='0 0 20 20'
        />
      ),
      to: 'globalProperties',
      tooltipDescription: 'Properties',
    },
    {
      icon: (
        <Icon
          name='apps'
          stroke={theme.colors.main.dark.darkest}
          viewBox='0 0 20 20'
        />
      ),
      iconActive: (
        <Icon
          name='apps'
          fill={theme.colors.main.primary.darker}
          stroke={theme.colors.main.primary.darker}
          viewBox='0 0 20 20'
        />
      ),
      to: 'integrations',
      tooltipDescription: 'Integrations',
    },
  ];

  return (
    <Fragment>
      {items.map((item, index) => (
        <Link key={index} href={`/organizations/${organizationId}/${item.to}`}>
          <Tooltip
            tooltipText={item.tooltipDescription}
            tooltipDirection='right'
          >
            <Flexbox
              hoverBackgroundColor={theme.colors.ui.body}
              borderRadius={0.5}
              cursor='pointer'
              justifyContent='center'
              alignItems='center'
              p={0.5}
            >
              {isActive(item.to) ? item.iconActive : item.icon}
            </Flexbox>
          </Tooltip>
        </Link>
      ))}
    </Fragment>
  );
};

export const MemoizedNavigationItems = memo(NavigationItems);
