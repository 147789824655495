import { TimeOption } from './TimeDropdown/TimeOption/types';
import { parseTimeRange } from './parseTimeRange';
import { timeOptions } from './TimeDropdown/TimeOption/timeOptions';

export const getInitialTimeOption = (
  label: string | undefined,
  defaultTimeOption: TimeOption
): TimeOption => {
  if (!label) return defaultTimeOption;

  const initialTimeOption = label.includes('-')
    ? parseTimeRange(label)
    : timeOptions.find((o) => o.label === label);

  return initialTimeOption || defaultTimeOption;
};
