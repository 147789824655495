import { TimeOption } from './types';
import { calculateTimeRange } from './calculateTimeRange';

const now = new Date();

const timeRanges = [
  { label: 'Last hour', hours: 1 },
  { label: 'Last 24 hours', hours: 24 },
  { label: 'Last 48 hours', hours: 48 },
  { label: 'Last 72 hours', hours: 72 },
  { label: 'Last 7 days', hours: 168 },
  { label: 'Last 14 days', hours: 336 },
  { label: 'Last 30 days', hours: 720 },
];

export const timeOptions: TimeOption[] = timeRanges.map(({ label, hours }) => ({
  label,
  timeRange: calculateTimeRange(now, hours),
}));

export const [
  lastHour,
  last24Hours,
  last48Hours,
  last72Hours,
  last7Days,
  last14Days,
  last30Days,
] = timeOptions;
